import { useMutation, useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'
import { OrgUpdateRequest } from 'src/types/flare-org-dev.d'
import { OrganisationDTO } from 'src/types/flare-organisations-dev'

export const useCurrentOrg = () => {
  return useQuery<OrganisationDTO>({
    queryKey: ['/organisation'],
    queryFn: async () => {
      const res = await getApi()?.organisations.get('/organisation/current')
      return res?.data
    },
  })
}

export const useUpdateOrgMutator = (id: string | null | undefined) => {
  return useMutation({
    mutationFn: async (form: OrgUpdateRequest) => {
      const res = await getApi()?.organisations?.put(`/organisation/${id}`, form)
      return res?.data
    },
  })
}
