import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { Modal, closeModal, useModals } from 'src/features/App/api/stores'
import { AspNetUserDTO } from 'src/types/flare-user-dev'

import { useUpdateUserMutator } from '../../api'
import { UserStatus } from '../../api/types.d'

// TODO - write a runtime validator for modal state
// In order to reuse the same 'state' variable, we need to verify what gets passed in by openModal

// TODO - encapsulate "isLoading" in the Shadcn button component

const ConfirmDisableUserDialog = () => {
  const queryClient = useQueryClient()
  const user: AspNetUserDTO = useModals(state => state.state)?.user as AspNetUserDTO
  const isOpen = useModals(state => state.modal) == Modal.DISABLE_USER
  const id = user?.id || ''

  const updateUser = useUpdateUserMutator(id)

  return (
    <Dialog open={isOpen}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Disable User</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2'>
          Are you sure you want to disable this user?
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild onClick={closeModal}>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>

          <Button
            type='button'
            variant='destructive'
            disabled={updateUser.isLoading}
            onClick={() => {
              updateUser.mutate(
                [{ op: 'replace', path: '/status', value: UserStatus.Disabled }],
                {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries(['/users'])
                    toast.success('User disabled')
                    closeModal()
                  },
                }
              )
            }}
          >
            {updateUser.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDisableUserDialog
