import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const shipmentFormSchema = z.object({
  name: z.string(),
  id: z.string().optional(),
  legId: z.string().optional(),
  containerId: z.string().optional(),
  shipTo: z.string(),
  shipToAddress: z.any(),
  shipFrom: z.string(),
  shipFromAddress: z.any(),
  shipMode: z.string(),
  shipDate: z.date(),
  shipTerms: z.object({
    label: z.string().min(1),
    value: z.string().min(1),
  }),
  sensorProfile: z.object(
    {
      label: z.string().min(1),
      value: z.string().min(1, { message: 'Sensor Profile is required' }),
    },
    { invalid_type_error: 'Sensor Profile is required' }
  ),

  isFinanced: z.boolean(),
  isInsured: z.boolean(),
  purchaseOrder: z.any(),

  devices: z.array(z.any()),
  devicesToAdd: z.array(z.any()),
  devicesToRemove: z.array(z.string()),

  recipients: z.array(z.any()),
  recipientsToAdd: z.array(z.any()),
  recipientsToRemove: z.array(z.string()),

  documents: z.array(z.any()),
  documentsToAdd: z.array(z.any()),
  documentsToRemove: z.array(z.string()),
})

export const shipmentResolver = zodResolver(shipmentFormSchema)

export type ShipmentForm = z.infer<typeof shipmentFormSchema>

export const updateLegSchema = z.object({
  bolRef: z.string().min(1),
})

export const updateLegResolver = zodResolver(updateLegSchema)

export type UpdateLegForm = z.infer<typeof updateLegSchema>
