import React, { useCallback, useEffect, useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FloppyDisk, Prohibit } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Button } from 'src/components/Button'
import { Skeleton } from 'src/components/Skeleton'
import Subheader from 'src/components/Subheader'
import { queryClient } from 'src/helpers/queryClient'

import {
  AddressDetails,
  mapAddressDetailsToForm,
  mapFormToCreateAddressDTO,
  useAddressStore,
} from '../../api'
import { useCreateAddressMutator } from '../../api/queries'
import { addressSchema } from '../../api/resolvers'

import { AddressDetailsBlock, AddressMapBlock } from './components'

type CreateAddressSchema = z.infer<typeof addressSchema.create>

export const defaultAddressForm: CreateAddressSchema = {
  name: '',
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  country: null,
  province: '',
  postalCode: '',
  position: null,
}
const SingleAddressPageDesktop = () => {
  const navigate = useNavigate()
  const resetAutoRef = useRef<(() => void) | null>(null)
  const isdirty = useAddressStore(s => s.isdirty)

  const form = useForm({
    defaultValues: defaultAddressForm,
    resolver: zodResolver(addressSchema.create),
  })

  const resetAutoRefInChild = useCallback((resetFunction: () => void) => {
    resetAutoRef.current = resetFunction
  }, [])
  useEffect(() => {
    return () => {
      useAddressStore.getState().reset()
      if (resetAutoRef.current) {
        resetAutoRef.current()
      }
    }
  }, [])

  const createAddress = useCreateAddressMutator()
  const createAddressForm = form.handleSubmit(() => {
    const values = mapFormToCreateAddressDTO(form.getValues())
    createAddress.mutate(values, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['/address'])
        toast.success('Address created')
        navigate('/addresses')
      },
      onError: e => {
        if (e instanceof AxiosError) {
          console.log(e)
        }
      },
    })
  })
  console.log(createAddress.isLoading)
  return (
    <FormProvider {...form}>
      <div className='flex-1' data-testid='address-page-desktop'>
        <Subheader
          returnTo='/addresses'
          title={'Create new Address' || <Skeleton className='w-[200px] h-[36px]' />}
          actions={
            <div className='flex items-center gap-2'>
              <Button
                variant={'outline'}
                disabled={!isdirty && !form.formState.isDirty}
                data-testid='cancel-btn'
                onClick={() => {
                  form.reset()
                  useAddressStore.getState().reset()
                  if (resetAutoRef.current) {
                    resetAutoRef.current()
                  }
                }}
              >
                <Prohibit className='mr-2' />
                Cancel
              </Button>

              <Button
                variant={'outline'}
                disabled={
                  (!isdirty && !form.formState.isDirty) || createAddress.isLoading
                }
                data-testid='save-btn'
                onClick={async () => createAddressForm()}
              >
                <FloppyDisk className='mr-2' />
                {createAddress.isLoading && (
                  <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                )}
                Save Changes
              </Button>
            </div>
          }
        />

        <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
          <div className='container py-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
            <AddressDetailsBlock resetAutoRefInChild={resetAutoRefInChild} />
            <AddressMapBlock
              onAddressUpdate={addressDetails => {
                const appendedAddressDetails: AddressDetails = {
                  ...addressDetails,
                  name: form.getValues().name || '',
                }
                form.reset(mapAddressDetailsToForm(appendedAddressDetails))
                useAddressStore.setState({ isdirty: true })
              }}
            />
          </div>
        </div>
      </div>
    </FormProvider>
  )
}

export default SingleAddressPageDesktop
