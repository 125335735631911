import React, { useEffect, useRef, useState } from 'react'

import {
  Bell,
  Check,
  CreditCard,
  ExclamationMark,
  Files,
  Flag,
  MapPin,
  Package,
  Pencil,
  Plus,
  Pulse,
  Question,
  ShieldCheck,
  UploadSimple,
  X,
} from '@phosphor-icons/react'
import { useQuery } from '@tanstack/react-query'
import {
  SortingState,
  createColumnHelper,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { motion } from 'framer-motion'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import { useLocation } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import { z } from 'zod'

import PurchaseOrderImg from 'src/assets/purchase_order.png'
import Block from 'src/components/Block'
import { Button } from 'src/components/Button'
import { Combobox } from 'src/components/Combobox'
import { DatePicker } from 'src/components/DatePicker'
import FileUploader from 'src/components/FileUploader'
import { Input } from 'src/components/Input'
import { Label } from 'src/components/Label'
import RangePill from 'src/components/RangePill'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/Select'
import Table, { PaginationControls } from 'src/components/Table'
import Tag from 'src/components/Tag'
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip'
import { useUnusedFlares } from 'src/features/Flares/api/queries'
import { useSensorProfiles } from 'src/features/SensorProfiles/api/queries'
import { useCurrentOrg } from 'src/features/Settings/api/queries'
import { useUsers } from 'src/features/Users'
import { convertLocationToLatLng, defaultMapOptions } from 'src/helpers/gmap'
import { useMemoisedOptions } from 'src/helpers/hooks'
import { useSteps } from 'src/hooks/useStep'
import { getApi } from 'src/services'
import { LocationDTO } from 'src/types'

import { DUMMY_CARGO, DUMMY_DETAILS, termsOptions } from '../../api/constants'
import { UPLOAD_DUMMY_PO } from '../../api/helpers'
import { usePOShipmentMap, usePOShipmentStore } from '../../api/stores'
import { POShipmentForm } from '../../api/types.d'

const nextStep = useSteps.getState().nextStep

export const PromptUploadStep = () => {
  const form = useFormContext<POShipmentForm>()
  const documents = form.watch('documents')

  return (
    <motion.div
      key={'upload'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='flex flex-col gap-4'
    >
      <h1 className='font-medium'>Upload Purchase Order/Invoice</h1>
      <FileUploader
        files={documents}
        isMultipleUpload={false}
        onUpload={documents => {
          form.setValue('documents', documents)
          form.setValue('poDocumentName', documents[0].name)
          nextStep()
        }}
      />
    </motion.div>
  )
}

export const ProcessUploadStep = () => {
  const form = useFormContext<POShipmentForm>()

  useEffect(() => {
    UPLOAD_DUMMY_PO(form)

    // NOTE - artificial delay to simulate processing
    setTimeout(() => nextStep(), 2000)
  }, [])

  return (
    <motion.div
      key={'process'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className='flex flex-col gap-4 items-center justify-center h-60'
    >
      <MoonLoader size={24} color='#2563eb' />
      <div className='font-semibold'>Processing your Purchase Order...</div>
    </motion.div>
  )
}

export const VerifyDetailsStep = () => {
  const form = useFormContext<POShipmentForm>()
  const podetails = form.watch('podetails')
  const pocargo = form.watch('pocargo')

  return (
    <motion.div
      key={'verify'}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.3 }}
      className='grid gap-4'
      style={{ gridTemplateColumns: '250px auto' }}
    >
      <div>
        <img
          src={PurchaseOrderImg}
          className='w-full rounded border border-slate-300 flex items-center justify-center'
        />
      </div>
      <div className='flex flex-col gap-4'>
        <div className='border border-slate-300 rounded bg-white flex flex-col divide-y divide-slate-300 text-[14px]'>
          <div className='bg-slate-100 grid grid-cols-2 divide-x divide-slate-300 rounded-t font-semibold'>
            <div className='px-4 py-2'>Field</div>
            <div className='px-4 py-2'>Value</div>
          </div>

          {Object.entries(podetails).map(([field, value]) => (
            <div className='grid-cols-2 grid' key={field}>
              <div className='px-4 py-2 font-semibold'>{field}</div>
              <div className='px-4 py-2'>{value as string}</div>
            </div>
          ))}
        </div>

        <div className='border border-slate-300 rounded bg-white flex flex-col divide-y divide-slate-300 text-[14px]'>
          <div className='bg-slate-100 grid grid-cols-4 divide-x divide-slate-300 rounded-t font-semibold'>
            <div className='px-4 py-2'>Description</div>
            <div className='px-4 py-2'>Product Code</div>
            <div className='px-4 py-2'>Quantity</div>
            <div className='px-4 py-2'>Value</div>
          </div>

          {pocargo.map((row, i) => (
            <div className='grid-cols-4 grid' key={i}>
              {Object.values(row).map(cell => (
                <div className='px-4 py-2' key={`${i}_${cell}`}>
                  {cell as string}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  )
}

export const ShipmentSetupStep = () => {
  return (
    <motion.div
      className='flex flex-col gap-6'
      initial={{ y: -5, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
    >
      <div className='flex text-center justify-center'>
        <Pencil color='#2563eb' className='mr-2' />
        {/* <i className='far fa-pencil text-blue-600 mr-2' /> */}
        We’ve got the details from your Purchase Order, fill in the remaining fields to
        create your Shipment
      </div>

      <ShipmenteDetailsBlock />
      <ShipmentTrackingBlock />
      <ShipmentCargoBox />
      <ShipmentFinanceBox />
      <ShipmentCollaboratorBox />
      <ShipmentDocumentsBox />
    </motion.div>
  )
}

export const ShipmenteDetailsBlock = () => {
  const form = useFormContext<POShipmentForm>()
  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={MapPin}>Shipment Detail</Block.Title>
      <div className='flex flex-col gap-1'>
        <Label>Shipment Name</Label>
        <Input className='w-full' {...form.register('name')} />
        {form.formState.errors.name && (
          <p className='text-red-600 text-sm'>
            {form.formState.errors.name.message as string}
          </p>
        )}
      </div>

      <ShipmentMap />

      <div className='grid grid-cols-2 md:grid-cols-4 gap-4'>
        <ShipmentAddressFields />

        <div className='flex flex-col gap-1'>
          <Label>Ship Mode</Label>
          <Controller
            control={form.control}
            name='mode'
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value='0'>
                    {/* <Boat className='mr-2 inline' /> */}
                    {/* <i className='fas fa-ship mr-2 text-slate-400' /> */}
                    Sea
                  </SelectItem>
                  <SelectItem value='1'>
                    {/* <AirplaneInFlight className='mr-2' /> */}
                    {/* <i className='fas fa-plane mr-2 text-slate-400' /> */}
                    Air
                  </SelectItem>
                  <SelectItem value='2'>
                    {/* <Train className='mr-2' /> */}
                    {/* <i className='fas fa-train mr-2 text-slate-400' /> */}
                    Rail
                  </SelectItem>
                  <SelectItem value='3'>
                    {/* <Truck className='mr-2' /> */}
                    {/* <i className='fas fa-truck mr-2 text-slate-400' /> */}
                    Road
                  </SelectItem>
                </SelectContent>
              </Select>
            )}
          />
        </div>
        <div className='flex flex-col gap-1'>
          <Label>
            Ship Date
            <Tooltip>
              <TooltipTrigger>
                <Question className='ml-1' />
                {/* <i className='fas fa-question-circle text-sm text-slate-400 ml-1' /> */}
              </TooltipTrigger>
              <TooltipContent>
                <p>The date the shipment is scheduled to begin transit.</p>
              </TooltipContent>
            </Tooltip>
          </Label>
          <Controller
            control={form.control}
            name='date'
            render={({ field }) => (
              <DatePicker mode='single' {...field} date={field.value as any} />
            )}
          />

          {form.formState.errors.date && (
            <p className='text-red-600 text-sm'>
              {form.formState.errors.date.message as string}
            </p>
          )}
        </div>
      </div>

      {/* <Banner variant='magic' icon='fas fa-stars'>
        Estimate for sea freight from Houston to Southampton on March 10th is 6-7 business
        days.
      </Banner>

      <Banner variant='eco' icon='fas fa-leaf'>
        Carbon Emission Estimates: <strong>Mode:</strong> Sea - <strong>Distance:</strong>{' '}
        9269km - <strong>Emissions:</strong> 111,856kg CO2e
      </Banner> */}
    </Block>
  )
}

export const ShipmentAddressFields = () => {
  const form = useFormContext<POShipmentForm>()
  const isEdit = useLocation().pathname.includes('edit')

  const fromAddressValue = form.watch('fromAddress')?.name
  const toAddressValue = form.watch('toAddress')?.name

  return (
    <>
      <div className='flex flex-col gap-1'>
        <Label>
          Ship From
          <Check color='#16a34a' size={16} />
          {/* <i className='fas fa-check ml-2 text-green-600' /> */}
        </Label>
        {isEdit ? (
          <Input disabled className='w-full' value={fromAddressValue || ''} />
        ) : (
          <Input isValid disabled className='w-full' {...form.register('fromString')} />
        )}
      </div>
      <div className='flex flex-col gap-1'>
        <Label>
          Ship To
          <Check color='#16a34a' size={16} />
          {/* Ship To <i className='fas fa-check ml-2 text-green-600' /> */}
        </Label>
        {isEdit ? (
          <Input disabled className='w-full' value={toAddressValue || ''} />
        ) : (
          <Input isValid disabled className='w-full' {...form.register('toString')} />
        )}
      </div>
    </>
  )
}

// TODO - move to location feature
export const useLocationFromStr = (address: string) => {
  return useQuery<LocationDTO>({
    queryKey: ['/location-from-address-str', address],
    enabled: address.length > 0,
    queryFn: async () => {
      const res = await getApi()?.location?.get(`/location/from-string`, {
        params: { address },
      })
      return res?.data as LocationDTO
    },
  })
}

export const ShipmentMap = () => {
  const form = useFormContext<POShipmentForm>()
  const mapRef = useRef(null)

  const [map, setMap] = useState<google.maps.Map | null>()
  const { initialiseMap, addMarker, addLine, centerMap } = usePOShipmentMap.getState()

  const fromAddress = form.watch('fromAddress')
  const toAddress = form.watch('toAddress')

  const fromLocation = useLocationFromStr(form.watch('fromString'))
  const toLocation = useLocationFromStr(form.watch('toString'))
  const originIcon = (
    <UploadSimple size={16} color='#94A3B8' className='ml-2' weight='bold' />
  )
  const destinationIcon = (
    <Flag size={16} color='#CBD5E1' className='ml-2' weight='bold' />
  )
  useEffect(() => {
    if (mapRef.current) {
      setMap(new google.maps.Map(mapRef.current, defaultMapOptions))
    }
  }, [mapRef])

  useEffect(() => {
    const addMapFeatures = async () => {
      try {
        if (map) {
          initialiseMap(mapRef)
          if (fromAddress?.location && toAddress?.location) {
            addMarker(convertLocationToLatLng(fromAddress.location), 'Origin', originIcon)
            addMarker(
              convertLocationToLatLng(toAddress.location),
              'Destination',
              destinationIcon
            )
            addLine()
            centerMap()
          } else if (fromLocation.data && toLocation.data) {
            addMarker(convertLocationToLatLng(fromLocation.data), 'Origin', originIcon)
            addMarker(
              convertLocationToLatLng(toLocation.data),
              'Destination',
              destinationIcon
            )
            addLine()
            centerMap()
          }
        }
      } catch (error) {
        console.error('Error occurred while adding map features:', error)
      }
    }

    addMapFeatures()
  }, [map, fromAddress, fromLocation.data, toAddress, toLocation.data])

  return (
    <div className='bg-white rounded-lg overflow-hidden drop-shadow border border-slate-300 h-[320px]'>
      <div ref={mapRef} className='w-full h-full'></div>
    </div>
  )
}

export const ShipmentTrackingBlock = () => {
  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Pulse}>Devices</Block.Title>
      <ShipmentManageSensorProfile />
      <ShipmentAddDevicesList />
    </Block>
  )
}

export const ShipmentAddDevicesList = () => {
  const { control } = useFormContext<any>()

  const unusedDevices = useUnusedFlares()
  const deviceOptions = useMemoisedOptions(unusedDevices.data)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'devices',
  })

  useEffect(() => {
    usePOShipmentStore.setState({ devices: fields })
  }, [fields.length])

  return (
    <div className='flex flex-col gap-1'>
      <Label>Assigned Devices</Label>

      {fields.length == 0 ? (
        <div className='bg-slate-100 rounded px-4 py-2 w-full text-slate-600'>
          No Assigned Devices yet
        </div>
      ) : (
        fields.map((item, index) => (
          <div className='gap-2 flex items-center' key={item.id}>
            <Controller
              name={`devices.${index}` as const}
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    value={field.value.value}
                    onValueChange={e =>
                      field.onChange(deviceOptions.find(opt => opt.value == e))
                    }
                  >
                    <SelectTrigger>
                      <SelectValue />
                    </SelectTrigger>
                    <SelectContent>
                      {deviceOptions.map(opt => (
                        <SelectItem
                          key={opt.value}
                          value={opt.value as string}
                          // @ts-ignore
                          disabled={fields.find(f => f.value == opt.value)}
                        >
                          {opt.label}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </>
              )}
            />
            <Button onClick={() => remove(index)} size={'icon'} variant={'outline'}>
              <X />
              {/* <i className='fas fa-times' /> */}
            </Button>
          </div>
        ))
      )}

      <Button
        variant={'secondary'}
        className='w-fit mt-2'
        disabled={fields.length >= deviceOptions.length}
        onClick={() => append({ label: '', value: null })}
      >
        <Plus className='mr-2' />
        {/* <i className='fas fa-plus mr-2 text-slate-400' /> */}
        Add Device
      </Button>
    </div>
  )
}

export const ShipmentManageSensorProfile = () => {
  const form = useFormContext<any>()

  const profiles = useSensorProfiles({ pageSize: 1000 })
  const profileOptions = useMemoisedOptions(profiles.data?.items)
  const sensorProfileError = () => {
    const sensorError = form.formState.errors.sensorProfile as any
    return sensorError?.value?.message as string
  }
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-col gap-1'>
        <Label>Device Sensor Profile</Label>
        <Controller
          name='sensorProfile'
          control={form.control}
          render={({ field }) => (
            <Combobox
              className='w-full'
              options={profileOptions}
              value={field.value}
              render={e => {
                const profile = profiles.data?.items?.find(p => p.id == e.value)
                return (
                  <div className='flex items-center justify-between w-full'>
                    <span>{profile?.name}</span>
                    <div className='flex items-center gap-2'>
                      <RangePill
                        isInactive={!profile?.temperatureCheck}
                        sensor={'temperature'}
                        value={[profile?.temperatureMinC, profile?.temperatureMaxC]}
                      />
                      <RangePill
                        isInactive={!profile?.humidityCheck}
                        sensor={'humidity'}
                        value={[profile?.humidityMinRh, profile?.humidityMaxRh]}
                      />
                    </div>
                  </div>
                )
              }}
              onSelect={e => field.onChange(e)}
            />
          )}
        />
        {form.formState.errors.sensorProfile && (
          <p className='text-red-600 text-sm'>{sensorProfileError()}</p>
        )}
      </div>
    </div>
  )
}

const columnHelper = createColumnHelper<any>()

const columns = [
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('product_code', {
    header: 'Product Code',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('value', {
    header: `Value ${DUMMY_DETAILS['Currency']}`,
    cell: info => {
      return <div className='flex items-center justify-end'>{info.getValue()}</div>
    },
  }),
]

export const ShipmentCargoBox = () => {
  const form = useFormContext()
  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable({
    data: DUMMY_CARGO,
    columns: columns,
    state: { rowSelection, sorting },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
  })

  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Package}>Cargo</Block.Title>

      <div className='flex flex-col gap-1 md:max-w-[50%]'>
        <Label>Shipping Terms</Label>
        <Controller
          control={form.control}
          name='terms'
          render={({ field }) => (
            <Combobox
              options={termsOptions}
              value={field.value}
              onSelect={field.onChange}
            />
          )}
        />
      </div>

      <div className='flex flex-col border-t border-slate-300 pt-4'>
        <div className='flex items-center justify-between pb-4'>
          <Label>Cargo Items</Label>
          <PaginationControls table={table} />
        </div>
        <Table table={table} />
        <div className='flex justify-end border border-slate-300 rounded border-t-0 bg-white w-full p-2'>
          <span className='font-semibold pr-4'>Total </span>
          {DUMMY_DETAILS && DUMMY_DETAILS['Total Amount']}
        </div>
      </div>
    </Block>
  )
}

export const ShipmentFinanceBox = () => {
  const form = useFormContext<POShipmentForm>()

  const currentOrg = useCurrentOrg()

  const isFinanceApproved = form.getValues('isFinanced')
  const isInsuranceApproved = form.getValues('isInsured')

  const isFinanceAllowed = currentOrg.data?.isFinanceApproved

  const isInsuranceAllowed = currentOrg.data?.isInsuranceApproved

  const cols = isFinanceAllowed && isInsuranceAllowed ? 2 : 1

  return (
    <div
      className={clsx(
        cols == 1 ? 'md:grid-cols-1' : 'md:grid-cols-2',
        'grid grid-cols-1 gap-6'
      )}
    >
      {isFinanceAllowed && (
        <Block className='grid gap-4' style={{ gridTemplateColumns: 'auto 100px' }}>
          <div className='flex flex-col gap-1'>
            <Block.Title icon={CreditCard}>Finance</Block.Title>
            {isFinanceApproved ? (
              <div className='flex text-center font-normal text-slate-900'>
                Finance approved for this Shipment
                <Check color='#16A34A' className='ml-2' />
              </div>
            ) : (
              <p className='font-semibold text-amber-400'>Awaiting Approval</p>
            )}
          </div>
          {/* <div>
            <small className='whitespace-nowrap'>Total Order Value</small>
            <p className='font-bold text-lg'>$3,000.00</p>
          </div> */}
        </Block>
      )}
      {isInsuranceAllowed && (
        <Block className='grid gap-4' style={{ gridTemplateColumns: 'auto 100px' }}>
          <div className='flex flex-col gap-1'>
            <Block.Title icon={ShieldCheck}>Insurance</Block.Title>
            {isInsuranceApproved ? (
              <div className='flex text-center font-normal text-slate-900'>
                Insurance approved for this Shipment
                <Check color='#16A34A' className='ml-2' />
              </div>
            ) : (
              <p className='font-semibold text-amber-400'>Awaiting Approval</p>
            )}
          </div>
          {/* <div>
            <small className='whitespace-nowrap'>Total Order Value</small>
            <p className='font-bold text-lg'>$3,000.00</p>
          </div> */}
        </Block>
      )}
    </div>
  )
}

export const ShipmentCollaboratorBox = () => {
  const { control } = useFormContext<POShipmentForm>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  })

  const [input, setInput] = useState<string>('')
  const [emailError, setEmailError] = useState<string>('')

  const users = useUsers({ pageSize: 1000 })
  const usersOptions = useMemoisedOptions(users.data?.items, 'fullName', 'id')

  const filteredOptions = usersOptions.filter(
    opt => !fields.find(u => u?.id === opt.value)
  )

  const addUser = userOpt => {
    const user = users.data?.items?.find(u => u.id == userOpt.value)
    append({ email: user?.email as string, name: user?.fullName as string })
  }

  const addEmail = () => {
    try {
      z.string().email().parse(input)
      append({ email: input, name: input })
      setInput('')
      setEmailError('')
    } catch (error) {
      setInput('')
      setEmailError('Invalid email')
    }
  }

  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Bell}>Collaborators</Block.Title>
      <Label>Notification Recipients</Label>

      <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
        <div>
          <Label>Users</Label>
          <Combobox
            isLoading={users.isLoading}
            placeholder='Select a user...'
            className='w-full'
            options={filteredOptions}
            onSelect={addUser}
            value={null}
          />
        </div>
        <div>
          <Label>Emails</Label>
          <div className='flex items-start gap-2'>
            <div className='w-full'>
              <Input
                onKeyDown={e => e.key === 'Enter' && addEmail()}
                value={input}
                isError={!!emailError}
                onChange={e => setInput(e.currentTarget.value)}
                className='w-full'
                placeholder='Enter an email...'
              />
              {emailError && (
                <small className='text-red-500'>
                  <ExclamationMark className='mr-1 text-red-600' />
                  {emailError}
                </small>
              )}
            </div>
            <Button variant={'secondary'} onClick={addEmail}>
              Add
            </Button>
          </div>
        </div>
      </div>

      <div className='flex items-center gap-1 flex-wrap'>
        {fields.length == 0 ? (
          <div className='bg-slate-100 rounded px-4 py-2 w-full text-slate-600'>
            No recipients yet
          </div>
        ) : (
          fields.map((user, i) => (
            <Tag key={user.id} text={user.name} onDelete={() => remove(i)} />
          ))
        )}
      </div>
    </Block>
  )
}

export const ShipmentDocumentsBox = () => {
  const form = useFormContext<POShipmentForm>()
  const documents = form.watch('documents')

  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Files}>Documents</Block.Title>
      <FileUploader
        files={documents}
        onRemove={i =>
          form.setValue(
            'documents',
            documents.filter((_, idx) => idx !== i),
            { shouldDirty: true }
          )
        }
        onUpload={files => {
          form.setValue('documents', [...documents, ...files], { shouldDirty: true })
        }}
      />
    </Block>
  )
}
