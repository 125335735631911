import { create } from 'zustand'

export interface UseSteps {
  totalSteps: number
  currentStep: number
  nextStep: () => void
  prevStep: () => void
  setStep: (step: number) => void
}

export const useSteps = create<UseSteps>()((set, get) => ({
  totalSteps: 4,
  currentStep: 0,
  nextStep: () => {
    const { currentStep, totalSteps } = get()
    if (currentStep < totalSteps) set({ currentStep: currentStep + 1 })
  },
  prevStep: () => {
    const { currentStep } = get()
    if (currentStep > 1) set({ currentStep: currentStep - 1 })
  },
  setStep: step => {
    const { totalSteps } = get()
    if (step >= 0 && step <= totalSteps) {
      set({ currentStep: step })
    }
  },
}))

export const { nextStep, prevStep, setStep } = useSteps.getState()
