import { ReactHTML } from 'react'

import { toast } from 'sonner'
import { create } from 'zustand'

import { defaultMapOptions } from 'src/helpers/gmap'
import { createHTMLMapMarkerFactoy } from 'src/helpers/markerFactory'
import { AddressDTO, DeviceDTO, LocationDTO } from 'src/types'

export const useShipmentMap = create<any>()((set, get) => ({
  map: undefined,
  markerFactory: undefined,
  markers: [],
  lines: [],
  initialiseMap: (mapRef: React.RefObject<HTMLDivElement>) => {
    if (!mapRef?.current || !window.google) return
    const map = new google.maps.Map(mapRef.current, defaultMapOptions)
    const markerFactory = createHTMLMapMarkerFactoy(map)
    set({ map, markerFactory })
  },
  addMarker: (position: google.maps.LatLng, text: string, icon: ReactHTML) => {
    const markerFactory = get().markerFactory
    const marker = new markerFactory({ position, color: 'label', text, icon: icon })
    set({ markers: [...get().markers, marker] })
  },
  addLine: () => {
    const markers = get().markers
    if (markers.length < 2) return
    const icon = { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 3 }
    const line = new google.maps.Polyline({
      path: markers.map(marker => marker.getPosition()),
      geodesic: true,
      strokeColor: '#2563EB',
      strokeOpacity: 0,
      icons: [{ icon, offset: '0', repeat: '15px' }],
    })
    line.setMap(get().map)
    set({ lines: [...get().lines, line] })
  },
  centerMap: () => {
    const markers = get().markers
    if (markers.length === 0) return
    const bounds = new google.maps.LatLngBounds()
    markers.forEach(marker => {
      bounds.extend(marker.getPosition())
    })
    get().map?.fitBounds(bounds)
  },
  clearMap: () => {
    console.log('CLEAR')
    get().markers.forEach(marker => marker.setMap(null))
    get().lines.forEach(line => line.setMap(null))
    set({ markers: [], lines: [] })
  },
}))

export interface UseShipmentStore {
  step: number
  nextStep: () => void
  prevStep: () => void
  resetSteps: () => void
  setStep: (step: number) => void

  poFile: File | null
  po: any
  poLineItems: any
  scanSuccessful: boolean

  shipName: string

  shipFromAddress: AddressDTO | null
  shipFromLocation: LocationDTO | null
  shipFromString: string

  shipToAddress: AddressDTO | null
  shipToLocation: LocationDTO | null
  shipToString: string

  shipMode: string
  shipDate: Date
  shipTerms: any
  shipSensorProfile: any

  shipIsInsured: boolean
  shipIsFinanced: boolean

  shipDevices: (DeviceDTO | null)[]
  addDeviceSlot: () => void
  setDevice: (index: number, device: DeviceDTO) => void
  removeDevice: (index: number) => void

  shipUserRecipients: any[]
  addUserRecipient: (user: any) => void
  removeUserRecipient: (index: number) => void

  shipEmailRecipients: any[]
  addEmailRecipient: (email: string) => void
  removeEmailRecipient: (index: number) => void

  shipDocuments: any[]
  addDocuments: (document: any) => void
  removeDocument: (index: number) => void
}

export const useShipmentStore = create<UseShipmentStore>((set, get) => ({
  step: 0,
  nextStep: () => set(state => ({ step: state.step + 1 })),
  prevStep: () => set(state => ({ step: state.step - 1 })),
  resetSteps: () => set({ step: 0 }),
  setStep: step => set({ step }),

  poFile: null,
  po: null,
  poLineItems: null,
  scanSuccessful: false,

  shipName: '',

  shipFromAddress: null,
  shipFromLocation: null,
  shipFromString: '',

  shipToAddress: null,
  shipToLocation: null,
  shipToString: '',

  shipMode: '0',
  shipDate: new Date(),
  shipTerms: '0',
  shipSensorProfile: '',

  shipIsInsured: false,
  shipIsFinanced: false,

  shipDevices: [],
  addDeviceSlot: () => set(state => ({ shipDevices: [...state.shipDevices, null] })),
  setDevice: (index, device) =>
    set(state => {
      const devices = [...state.shipDevices]
      devices[index] = device
      return { shipDevices: devices }
    }),
  removeDevice: index =>
    set(state => ({
      shipDevices: state.shipDevices.filter((_, i) => i !== index),
    })),

  shipUserRecipients: [],
  addUserRecipient: user =>
    set(state => ({ shipUserRecipients: [...state.shipUserRecipients, user] })),
  removeUserRecipient: index =>
    set(state => ({
      shipUserRecipients: state.shipUserRecipients.filter((_, i) => i !== index),
    })),

  shipEmailRecipients: [],
  addEmailRecipient: email =>
    set(state => ({ shipEmailRecipients: [...state.shipEmailRecipients, email] })),
  removeEmailRecipient: index =>
    set(state => ({
      shipEmailRecipients: state.shipEmailRecipients.filter((_, i) => i !== index),
    })),

  shipDocuments: [],
  addDocuments: documents => {
    const maxSize = 100 * 1024 * 1024 // 100MB in bytes
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png']

    const totalSize = documents.reduce((acc, doc) => acc + doc.size, 0)
    if (totalSize > maxSize) {
      toast.error('Documents exceed 100MB limit')
      return
    }

    const invalidTypes = documents.filter(doc => !allowedTypes.includes(doc.type))
    if (invalidTypes.length > 0) {
      const invalidNames = invalidTypes.map(doc => doc.name).join(', ')
      toast.error(
        `Invalid file types: ${invalidNames}. Only PDF, JPG, and PNG files are allowed.`
      )
      return
    }

    if (get().shipDocuments.length + documents.length > 9) {
      toast.error('Cannot add more than 9 documents')
      return
    }

    set(state => ({ shipDocuments: [...state.shipDocuments, ...documents] }))
  },
  removeDocument: index =>
    set(state => ({
      shipDocuments: state.shipDocuments.filter((_, i) => i !== index),
    })),
}))

export const {
  nextStep,
  setStep,
  addDeviceSlot,
  setDevice,
  removeDevice,
  addEmailRecipient,
  removeEmailRecipient,
  addUserRecipient,
  removeUserRecipient,
  addDocuments,
  removeDocument,
} = useShipmentStore.getState()
