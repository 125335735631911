import React from 'react'

import { ArrowRight, Icon, Link, Prohibit } from '@phosphor-icons/react'
import clsx from 'clsx'
import { title } from 'radash'
import { ErrorBoundary } from 'react-error-boundary'
import { PuffLoader } from 'react-spinners'

import { Button } from 'src/components/Button'
import { getFlagEmoji } from 'src/helpers/utils'
import { ShipmentDTO } from 'src/types'

import { statusMapping } from '../api'

export const LoadingIndicator = () => (
  <div className='w-full grow flex items-center justify-center'>
    <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
  </div>
)

export const ShipmentPill = ({ children }) => {
  return (
    <div className='flex items-center justify-center rounded-lg px-2.5 py-1.5 gap-2 bg-slate-200 text-[14px]'>
      {children}
    </div>
  )
}

export const StatusPill = ({ status }: { status: number | undefined }) => {
  if (status == null) return null
  const { text, bgColor, icon, iconColor } = statusMapping[status]
  const _icon = icon as Icon
  return (
    <div
      className={clsx(
        bgColor,
        `flex items-center justify-center rounded-full px-3 py-2 text-[14px]`
      )}
    >
      <_icon color={iconColor} />
      <i className={clsx(iconColor, `fas fa-${icon} pr-1`)} />
      <span>{text}</span>
    </div>
  )
}

export const ShipmentJourneyDetails = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  if (!shipment || shipment?.legs?.length == 0) return null
  const leg = shipment?.legs?.[0]

  if (!leg?.origin || !leg?.destination) return null

  return (
    <div className='flex items-center gap-4'>
      <div className='truncate'>
        {getFlagEmoji(leg.origin?.country?.code2)} {leg.origin?.name}
      </div>
      <ArrowRight />
      <div className='truncate'>
        {getFlagEmoji(leg.destination?.country?.code2)} {leg.destination?.name}
      </div>
    </div>
  )
}

export const PageLayout = ({ children }) => {
  return (
    <div className='overflow-auto bg-slate-50 h-[calc(100vh-140.8px)]'>
      <div className='max-w-[1336px] mx-auto p-4 flex flex-col gap-4'>
        <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
      </div>
    </div>
  )
}

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Go Back</Button>
    </div>
  )
}

export const CancelBtn = () => (
  <Link to='/shipments'>
    <Button variant='outline'>
      <Prohibit />
      <span className='hidden sm:inline ml-2'>Cancel</span>
    </Button>
  </Link>
)

export const ContentsTable = ({ headers, rows }) => {
  return (
    <div className='overflow-hidden border border-slate-300 rounded text-[14px]'>
      <div
        className='grid'
        style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
      >
        {headers.map((header, index) => (
          <div
            key={index}
            className='bg-slate-100 px-4 py-2 text-left font-medium border-b border-slate-300'
          >
            {title(header ?? '').replace('_', ' ')}
          </div>
        ))}

        {rows.map((row, rowIndex) =>
          Object.values(row).map((cell, cellIndex) => (
            <div
              key={cellIndex}
              className={clsx(
                rowIndex == rows.length - 1 &&
                  rows.length !== 1 &&
                  'border-b border-slate-300',
                'px-4 py-2 whitespace-nowrap'
              )}
            >
              {cell === null || cell === undefined ? 'N/A' : String(cell)}
            </div>
          ))
        )}
      </div>
    </div>
  )
}
