import { useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'
import { DeviceDTO } from 'src/types/flare-devices-dev'

export const useDevice = (id: string | undefined | null) => {
  return useQuery<DeviceDTO>({
    queryKey: ['/devices', id],
    enabled: !!id,
    queryFn: async () => {
      const res = await getApi()?.devices.get(`/device/by-id/${id}`)
      return res?.data
    },
  })
}

export const useDevices = () => {
  return useQuery<DeviceDTO[]>({
    queryKey: ['/devices'],
    queryFn: async () => {
      const res = await getApi()?.devices.get(`/device`)
      return res?.data
    },
  })
}

export const useUnusedFlares = () => {
  return useQuery<DeviceDTO[]>({
    queryKey: ['/unusedFlares'],
    queryFn: async () => {
      const res = await getApi()?.devices.get(`/device/unused`)
      return res?.data
    },
  })
}
