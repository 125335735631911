import React from 'react'

import { ArrowLeft } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import { Button } from './Button'

interface SubheaderProps {
  title: string | React.ReactNode
  actions?: React.ReactNode
  returnTo?: string | number
}

const Subheader = (props: SubheaderProps) => {
  return (
    <div
      className='bg-white border-b border-slate-300 p-4 flex items-center drop-shadow z-10 relative'
      data-testid='page-subheader'
    >
      <div className='max-w-[1336px] px-4 w-full mx-auto flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          {props.returnTo && (
            <Link to={props.returnTo as string} data-testid='subheader-return-btn'>
              <Button size={'icon'} variant={'outline'}>
                <ArrowLeft color='000' />
              </Button>
            </Link>
          )}

          <h1 className='text-xl font-semibold' data-testid='page-title'>
            {props.title ?? ''}
          </h1>
        </div>
        {props.actions}
      </div>
    </div>
  )
}

export default Subheader
