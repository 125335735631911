import React, { useRef, useState } from 'react'

import {
  DotsThreeVertical,
  Download,
  FileArrowUp,
  FileDoc,
  File as FileIcon,
  FilePdf,
  Trash,
} from '@phosphor-icons/react'
import { useFormContext } from 'react-hook-form'
import { toast } from 'sonner'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { POShipmentForm } from 'src/features/POShipment'
import ConfirmDeleteDocumentDialog from 'src/features/POShipment/views/ConfirmDeleteDocumentDialog/ConfirmDeleteDocumentDialog'
import { parseDate } from 'src/helpers/utils'
import { DocumentDTO } from 'src/types'

import { Button } from './Button'

interface FileUploaderProps {
  files: File[]
  isMultipleUpload?: boolean
  onUpload: (files: File[]) => void
  onRemove?: (index: number) => void
}

const FileUploader: React.FC<FileUploaderProps> = ({
  files = [],
  isMultipleUpload = true,
  onUpload,
  onRemove,
}) => {
  const [hovering, setHovering] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files

    if (fileList && fileList.length > 0) {
      if (fileList.length > 10 || files.length + fileList.length > 10) {
        toast.error('Max upload limit is 10 files')
        return
      }

      if (!checkFileSize(fileList)) {
        toast.error('Max upload size is 100MB')
        return
      }

      onUpload?.(Array.from(fileList))
    }
  }

  return (
    <div className='relative w-full'>
      {/* <input type='file' multiple ref={inputRef} hidden onChange={handleFileChange} /> */}

      <input
        style={{ display: 'none' }}
        onDragEnter={() => setHovering(true)}
        onDragLeave={() => setHovering(false)}
        onMouseEnter={() => setHovering(false)}
        type='file'
        multiple={isMultipleUpload}
        ref={inputRef}
        id='fileInput'
        className='h-full w-full absolute opacity-0 top-0'
        onChange={handleFileChange}
      />

      {files.length > 0 && (
        <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mb-4 border-b border-slate-300 pb-4'>
          {files.map((file, i) => {
            if (file instanceof File)
              return (
                <InMemoryFileDisplay
                  file={file}
                  key={i}
                  onRemove={() => onRemove?.(i)}
                  index={i}
                />
              )
            else return <DocumentFileDisplay document={file} key={i} />
          })}
        </div>
      )}

      <div className='h-42 p-6 flex items-center justify-center relative border-2 border-slate-300 border-dashed bg-slate-100 rounded'>
        {hovering && (
          <div className='flex flex-col items-center gap-2'>
            <FileArrowUp size={32} />
            <div>Release to Upload...</div>
          </div>
        )}

        {!hovering && (
          <div className='flex flex-col items-center gap-4 pointer-events-none'>
            <FileArrowUp size={32} />
            <p className='font-medium'>Drag and drop file here</p>

            <Button
              onClick={() => inputRef.current?.click()}
              className='pointer-events-auto z-10'
            >
              Browse Files
            </Button>
            <small className='text-slate-600'>Maximum size 100mb</small>
          </div>
        )}
      </div>
    </div>
  )
}

export default FileUploader

const checkFileSize = (fileList: FileList) => {
  // Calculate total size of selected files
  let totalSize = 0
  for (let i = 0; i < fileList.length; i++) {
    totalSize += fileList[i].size
  }

  // Check if total size is within the limit (100MB)
  const maxSizeBytes = 100 * 1024 * 1024 // 100MB in bytes
  return totalSize <= maxSizeBytes
}

const renderFileIcon = (file: File | DocumentDTO) => {
  const type = (file as File)?.type ?? (file as DocumentDTO)?.fileName

  if (type.includes('pdf')) return <FilePdf size={32} />
  if (type.includes('doc') || type.includes('docx')) return <FileDoc size={32} />
  return <FileIcon size={32} />
}

export const DocumentFileDisplay = ({ document }: { document: DocumentDTO }) => {
  return (
    <div className='border border-slate-300 rounded flex'>
      <div className='h-full aspect-square bg-slate-200 flex items-center justify-center text-slate-600'>
        {renderFileIcon(document)}
      </div>
      <div className='p-4 flex flex-col truncate grow'>
        <p className='truncate'>{document.fileName}</p>
        <small className='text-slate-400'>
          Uploaded: {parseDate(document.createdOn)}
        </small>
      </div>

      <DocumentActions document={document} />
    </div>
  )
}

const InMemoryFileDisplay = ({
  file,
  onRemove,
  index,
}: {
  file: File
  onRemove: any
  index: number
}) => {
  const form = useFormContext<POShipmentForm>()

  return (
    <div className='border border-dashed border-slate-300 rounded flex'>
      <div className='h-full aspect-square bg-slate-100 flex items-center justify-center text-slate-600'>
        {renderFileIcon(file)}
      </div>

      <div className='p-4 flex flex-col truncate grow'>
        <p className='truncate'>{file.name}</p>
        {file?.size && (
          <small className='text-slate-400'>
            Size: {(file.size / 1024 / 1024).toPrecision(2)} MB
          </small>
        )}
      </div>

      {index !== 0 && (
        <div className='flex items-center justify-center aspect-square h-full'>
          <Button size='icon' variant={'outline'} onClick={onRemove}>
            <Trash />
          </Button>
        </div>
      )}
    </div>
  )
}

export const DocumentActions = ({ document }: { document: DocumentDTO }) => {
  const [showModal, setModal] = React.useState(false)
  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className='flex items-center justify-center aspect-square h-full'>
            <Button variant={'outline'} size={'icon'}>
              <DotsThreeVertical color='000' size={20} />
            </Button>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            className='font-medium text-[14px] rounded'
            onClick={() => document?.url && window.open(document?.url)}
          >
            <Download className='mr-2' />
            Download File
          </DropdownMenuItem>

          {!document.isPurchaseOrder && (
            <DropdownMenuItem
              className='font-medium text-[14px] rounded'
              onClick={() => setModal(true)}
            >
              <Trash className='mr-2' color='#dc2626' />
              <span>Delete</span>
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      {showModal && (
        <ConfirmDeleteDocumentDialog
          document={document}
          open={showModal}
          setShowModal={() => setModal(!showModal)}
        />
      )}
    </>
  )
}
