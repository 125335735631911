import { useMutation } from '@tanstack/react-query'
import Cookies from 'js-cookie'

import { getApi } from 'src/services'

export const loginFn = async (data: { emailAddress: string; password: string }) => {
  const result = await getApi()?.identity.post(`/identity/login`, data)

  const duration = new Date(result?.data.expiration).valueOf() - new Date().valueOf()
  const expires = Number((duration / (1000 * 60 * 60 * 24)).toPrecision(3))

  Cookies.set('flare-session', result?.data.token, { expires })

  return result
}

export const useLoginMutator = () => {
  return useMutation({
    mutationFn: loginFn,
  })
}

export const sendResetEmailFn = async (data: { email: string }) => {
  const result = await getApi()?.identity.get(`/identity/password-reset-token`, {
    params: { userEmail: data.email },
  })

  return result?.data
}

export const useSendResetEmailMutator = () => {
  return useMutation({
    mutationFn: sendResetEmailFn,
  })
}

export const saveNewPasswordFn = async (data: {
  emailAddress: string
  newPassword: string
}) => {
  const result = await getApi()?.identity.post(`/identity/update-password`, data)

  return result
}

export const useUpdatePasswordMutator = () => {
  return useMutation({
    mutationFn: saveNewPasswordFn,
  })
}

export const confirmEmailFn = async (data: {
  confirmationToken: string
  emailAddress: string
}) => {
  const result = await getApi()?.identity.post(`/identity/confirm-email`, data)

  return result?.data
}

export const sendConfirmEmailFn = async (data: { email: string }) => {
  const result = await getApi()?.identity?.get(`/identity/email-confirmation-token`, {
    params: { userEmail: data.email },
  })

  return result?.data
}

export const useSendConfirmEmailMutator = () => {
  return useMutation({
    mutationFn: sendConfirmEmailFn,
  })
}

export const useConfirmEmailMutator = () => {
  return useMutation({
    mutationFn: confirmEmailFn,
  })
}
