import React from 'react'

import { Files, Pen } from '@phosphor-icons/react'
import { TooltipTrigger } from '@radix-ui/react-tooltip'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/Dialog'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import { Tooltip, TooltipContent } from 'src/components/Tooltip'
import { closeModal } from 'src/features/App/api/stores'
import { queryClient } from 'src/helpers/queryClient'
import { ShipmentDTO } from 'src/types'

import { UpdateLegForm, updateLegResolver, useUpdateLegMutator } from '../../api'

const UpdateBOLNumberModal = ({ shipment }: { shipment: ShipmentDTO | undefined }) => {
  const [open, setOpen] = React.useState(false)

  const leg = shipment?.legs?.[0]

  const form = useForm<UpdateLegForm>({
    defaultValues: { bolRef: leg?.bolRef ?? '' },
    resolver: updateLegResolver,
  })

  const updateLeg = useUpdateLegMutator(leg?.id)

  const handleSubmit = form.handleSubmit(async data => {
    return updateLeg.mutateAsync(
      { bolRef: data.bolRef ?? '' },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['/shipments', shipment?.id])
          toast.success('Shipment updated.')
          setOpen(false)
        },
      }
    )
  })

  return (
    <Form {...form}>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogTrigger asChild>
          {leg?.bolRef ? (
            <div className='flex gap-4 items-center'>
              <span className='font-medium'>BOL: {leg.bolRef}</span>
              <Tooltip>
                <TooltipTrigger>
                  <Button size='icon' variant={'outline'} asChild>
                    <div>
                      <Pen />
                    </div>
                  </Button>
                </TooltipTrigger>
                <TooltipContent>Edit BOL Number</TooltipContent>
              </Tooltip>
            </div>
          ) : (
            <Button variant='outline'>
              <Files className='mr-2' />

              <span>ADD BOL Number</span>
            </Button>
          )}
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Update BOL Number</DialogTitle>
          </DialogHeader>

          <div className='p-6'>
            <FormField
              control={form.control}
              name='bolRef'
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>BOL Number</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className='w-full'
                        data-testid='name-input'
                        placeholder='Bol Refence Number'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )
              }}
            />
          </div>

          <DialogFooter className='sm:justify-between'>
            <DialogClose asChild>
              <Button
                variant='outline'
                onClick={closeModal}
                data-testid='closing-Bol-modal'
              >
                Cancel
              </Button>
            </DialogClose>

            <Button data-testid='Saving-Bol-btn' variant='default' onClick={handleSubmit}>
              {updateLeg.isLoading || queryClient.isFetching() ? (
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
              ) : null}
              Save
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </Form>
  )
}
export default UpdateBOLNumberModal
