import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { AxiosError } from 'axios'
import { motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'

import FlareLogo from '../../../assets/suply_black.png'
import { defaultLoginFormData } from '../api/constants'
import { useLoginMutator } from '../api/queries'
import { loginSchema } from '../api/resolvers'

// TODO - Get data-testid for the inputs from Deyan

const LoginPage = () => {
  const navigate = useNavigate()
  const login = useLoginMutator()

  const form = useForm({
    defaultValues: defaultLoginFormData,
    resolver: zodResolver(loginSchema),
  })

  const handleLogin = form.handleSubmit(async data => {
    await login.mutateAsync(data, {
      onSuccess: () => {
        navigate('/')
      },
      onError: e => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 401) {
            form.setError('password', { message: 'Password not recognized' })
          } else if (e.response?.status === 422) {
            navigate('/reset-password', {
              state: { required: true, emailAddress: data.emailAddress },
            })
          } else if (e.response?.status === 403) {
            navigate('/send-confirmation', { state: { emailAddress: data.emailAddress } })
          } else {
            toast.error('An unknown error occurred')
          }
        }
      },
    })
  })

  return (
    <Form {...form}>
      <motion.div
        data-testid='login-page'
        className='flex flex-col gap-4 bg-slate-100 w-screen h-screen items-center justify-center'
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
      >
        <img src={FlareLogo} alt='Suply Logo' width={200} />
        <form
          onSubmit={handleLogin}
          className='flex flex-col gap-4 m-6 p-6 border border-slate-300 rounded-lg bg-white'
          style={{ width: 364 }}
        >
          <div>
            <h1 className='font-semibold text-xl weight-600 mb-1'>Welcome to Suply</h1>
            <p className='text-slate-500 text-[14px]'>Login to get started.</p>
          </div>
          <div style={{ height: 1 }} className='bg-slate-300'></div>
          <div className='flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='emailAddress'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Address</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete='email'
                      className='w-full'
                      data-testid='email'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete='current-password'
                      type={'password'}
                      className='w-full'
                      data-testid='current-password'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              type='submit'
              disabled={login.isLoading}
              data-testid='submit-login-btn'
            >
              {login.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
              Login
            </Button>
          </div>
        </form>
        <Link
          data-testid='forgot-password-link'
          to='/reset-password'
          className='text-slate-600 hover:text-slate-900 transition'
        >
          Forgotten password?
        </Link>
      </motion.div>
    </Form>
  )
}

export default LoginPage
