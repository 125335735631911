import { useMutation, useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'

export const useReports = (query = {}) => {
  return useQuery<any>({
    queryKey: ['/reports', query],
    queryFn: async () => {
      const res = await getApi()?.reports?.get('/reports', { params: query })
      return res?.data
    },
  })
}

export const useDeleteReportMutator = (id: string) => {
  return useMutation({
    mutationFn: async () => {
      if (!id) throw new Error('id is required')
      const res = await getApi()?.reports?.delete(`/reports/${id}`)
      return res?.data
    },
  })
}
