import { useMutation, useQuery } from '@tanstack/react-query'
import * as jose from 'jose'
import Cookies from 'js-cookie'

import { getApi } from 'src/services'
import { PatchOp } from 'src/types'
import { RegisterRequest } from 'src/types/flare-identity-dev'
import { AspNetUserDTO, AspNetUserDTOPagedResponse } from 'src/types/flare-user-dev'

export const useUsers = (query = {}) => {
  return useQuery<AspNetUserDTOPagedResponse>({
    queryKey: ['/users', query],
    queryFn: async () => {
      const res = await getApi()?.user?.get('/user', { params: query })
      return res?.data
    },
  })
}

export const useUser = (id: string) => {
  return useQuery<AspNetUserDTO>({
    queryKey: ['/user', id],
    queryFn: async () => {
      const res = await getApi()?.user?.get(`/user/${id}`)
      return res?.data
    },
  })
}

export const getCurrentUserFn = async () => {
  try {
    const payload = jose.decodeJwt(Cookies.get('flare-session') as string)
    const res = await getApi()?.user?.get(`/user/${payload.UserId}`)
    return res?.data
  } catch (e) {
    // toast.error('Error getting current user')
    return {}
  }
}

export const useCurrentUser = () =>
  useQuery<AspNetUserDTO>({
    queryKey: ['/user', null],
    queryFn: getCurrentUserFn,
  })

export const useAddUserMutator = () => {
  return useMutation({
    mutationFn: async (form: RegisterRequest) => {
      const res = await getApi()?.identity?.post('/identity/register', form)
      return res?.data
    },
  })
}

export const useUpdateUserMutator = (id: string) => {
  return useMutation({
    mutationFn: async (operations: PatchOp<AspNetUserDTO>[]) => {
      if (!id) throw new Error('id is required')
      const res = await getApi()?.user?.patch(`/user/${id}`, operations)
      return res?.data
    },
  })
}

export const useUpdateTempPreferencesMutator = (userId: string | undefined | null) => {
  return useMutation({
    mutationFn: async (value: string) => {
      const op: PatchOp<AspNetUserDTO> = { op: 'replace', value, path: '/tempMetric' }
      const res = await getApi()?.user?.patch(`/user/${userId}`, [op])
      return res?.data
    },
  })
}
