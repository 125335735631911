import { z } from 'zod'

export const loginSchema = z.object({
  emailAddress: z.string().min(1, { message: 'Username is required' }).email(),
  password: z.string().min(1, { message: 'Password is required' }),
})

export const emailSchema = z.object({
  email: z.string().email({ message: 'Must be a valid email address' }),
})

export const updatePasswordSchema = z
  .object({
    password: z.string().min(1, { message: 'Password is required' }),
    confirmPassword: z.string().min(1, { message: 'Confirm Password is required' }),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: "Passwords don't match",
    path: ['confirmPassword'],
  })
