import React from 'react'

import { Loader2 } from 'lucide-react'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { Modal, useModals } from 'src/features/App/api/stores'
import { queryClient } from 'src/helpers/queryClient'

import { useDeleteSensorProfileMutator } from '../../api/queries'

const ConfirmDeleteSensorProfile = () => {
  const { modal, state, closeModal, callback } = useModals()
  const isOpen = modal == Modal.DELETE_SENSOR_PROFILE

  const ids = state?.ids as string[]

  const deleteSensorProfile = useDeleteSensorProfileMutator(ids)
  const handleSubmit = async () => {
    await deleteSensorProfile.mutateAsync()
    queryClient.invalidateQueries(['/sensor-profiles'])
    callback && callback()
    closeModal()
  }

  return (
    <Dialog open={isOpen} data-testid='confirm-delete-sensorprofile-dialog'>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Delete Sensor Profile</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2'>
          {`Are you sure you want to delete ${
            ids?.length == 1 ? 'this' : 'these'
          } Sensor ${ids?.length == 1 ? 'Profile' : 'Profiles'}?`}
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild>
            <Button variant='secondary' onClick={closeModal}>
              Close
            </Button>
          </DialogClose>

          <Button
            data-testid='confirm-delete-sensorprofile-btn'
            variant='destructive'
            onClick={handleSubmit}
          >
            {deleteSensorProfile.isLoading && (
              <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            )}
            Delete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
export default ConfirmDeleteSensorProfile
