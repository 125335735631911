import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FloppyDisk, Prohibit } from '@phosphor-icons/react'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import Subheader from 'src/components/Subheader'
import { useCurrentUser } from 'src/features/Users'
import { celsiusToFahrenheit } from 'src/helpers/utils'

import { defaultSensorProfile } from '../../api/constants'
import { convertSensorProfileFormToDTO } from '../../api/helpers'
import { useCreateSensorProfileMutator } from '../../api/queries'
import { createSensorProfileSchema } from '../../api/resolvers'
import { SensorThresholdBlock } from '../components'

const CreateSensorProfilePageDesktop: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const tempMetric = useCurrentUser()?.data?.tempMetric

  const form = useForm({
    defaultValues: defaultSensorProfile,
    resolver: zodResolver(createSensorProfileSchema),
  })

  const createSensorProfile = useCreateSensorProfileMutator()

  const onSubmit = form.handleSubmit(
    async () => {
      const values = convertSensorProfileFormToDTO(form.getValues())
      return createSensorProfile.mutateAsync(values, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['/sensor-profiles'])
          toast.success('Sensor Profile Created')
          navigate('/sensor-profiles')
        },
        onError: e => {
          if (e instanceof AxiosError) {
            console.log(e)
          }
        },
      })
    },
    error => {
      console.log(error)
    }
  )
  return (
    <Form {...form}>
      <div className='flex-1'>
        <Subheader
          title={'Creating a New Sensor Profile'}
          actions={
            <div className='flex items-center gap-2'>
              <Link to='/sensor-profiles'>
                <Button variant='outline'>
                  <Prohibit className='mr-2' />
                  {/* <i className='fas fa-ban mr-2' /> */}
                  Cancel
                </Button>
              </Link>
              <Button
                variant='outline'
                disabled={
                  (!form.getValues().humidityCheck &&
                    !form.getValues().temperatureCheck) ||
                  createSensorProfile.isLoading
                }
                onClick={() => onSubmit()}
              >
                {/* <i className='far fa-floppy-disk mr-2' /> */}
                <FloppyDisk className='mr-2' />
                {createSensorProfile.isLoading && (
                  <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                )}
                Create
              </Button>
            </div>
          }
        />

        <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
          <div className='container py-4 flex flex-col gap-4'>
            <FormField
              control={form.control}
              name='name'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Profile Name</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      className='w-full'
                      data-testid='name-input'
                      placeholder='E.g. Acceptable Temp Range'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <SensorThresholdBlock
              title={'temperature'}
              icon={'fa-thermometer-half'}
              min={-30}
              max={100}
              defaultValue={[5, 25]}
              format={value =>
                tempMetric === 'F' ? `${celsiusToFahrenheit(value)} °F` : `${value}°C`
              }
            />
            <SensorThresholdBlock
              title={'humidity'}
              icon={'fa-droplet'}
              min={0}
              max={100}
              defaultValue={[25, 75]}
              format={value => `${value}%`}
            />
          </div>
        </div>
      </div>
    </Form>
  )
}

export default CreateSensorProfilePageDesktop
