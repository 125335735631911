import React from 'react'

import { ArrowRight, Icon } from '@phosphor-icons/react'
import clsx from 'clsx'

import { getFlagEmoji } from 'src/helpers/utils'

import { POShipmentDTO, statusMapping } from '../api'

export const ShipmentPill = ({ children }) => {
  return (
    <div className='flex items-center justify-center rounded-lg px-2.5 py-1.5 gap-2 bg-slate-200 text-[14px]'>
      {children}
    </div>
  )
}

export const StatusPill = ({ status }: { status: number | undefined }) => {
  if (status == null) return null
  const { text, bgColor, icon, iconColor } = statusMapping[status]
  const _icon = icon as Icon
  return (
    <div
      className={clsx(
        bgColor,
        `flex items-center justify-center rounded-full px-3 py-2 text-[14px]`
      )}
    >
      <_icon color={iconColor} />
      <i className={clsx(iconColor, `fas fa-${icon} pr-1`)} />
      <span>{text}</span>
    </div>
  )
}

export const ShipmentJourneyDetails = ({
  shipment,
}: {
  shipment: POShipmentDTO | undefined
}) => {
  if (!shipment || shipment?.legs.length == 0) return null
  const leg = shipment.legs[0]
  if (!leg.origin || !leg.destination) return null
  return (
    <div className='flex items-center gap-4'>
      <div className='truncate'>
        {getFlagEmoji(leg.origin?.country?.code2)} {leg.origin?.name}
      </div>
      <ArrowRight />
      {/* <i className='fas fa-long-arrow-right text-slate-400' /> */}
      <div className='truncate'>
        {getFlagEmoji(leg.destination?.country?.code2)} {leg.destination?.name}
      </div>
    </div>
  )
}
