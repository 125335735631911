export enum UserStatus {
  Pending = 0,
  Active = 1,
  Disabled = 2,
  NotFound = 3,
  Expired = 4,
}

export interface UserGetParams {
  search?: string
  status?: UserStatus | null
}
