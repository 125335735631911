import React from 'react'

import { GearSix, List, SignOut } from '@phosphor-icons/react'
import clsx from 'clsx'
import { ChevronDown } from 'lucide-react'
import { Link, useLocation } from 'react-router-dom'

import { Button } from 'src/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { Skeleton } from 'src/components/Skeleton'
import { handleLogout } from 'src/features/Auth/api/helpers'
import { useCurrentUser } from 'src/features/Users'

import FlareLogo from '../../../assets/suply_white.png'

const ROUTES = [
  {
    name: 'Shipments',
    path: 'shipments',
  },
  {
    name: 'Sensor Profiles',
    path: 'sensor-profiles',
  },
  // {
  //   name: 'Reports',
  //   path: 'reports',
  // },
  {
    name: 'Addresses',
    path: 'addresses',
  },
  {
    name: 'Users',
    path: 'users',
  },
]

const Navbar = () => {
  const pathname = useLocation()?.pathname.split('/')?.[1] ?? ''
  const currentUser = useCurrentUser()

  return (
    <header
      className='flex items-center justify-between text-white py-4 px-8'
      style={{ background: '#030A1B' }}
    >
      <div className='flex items-center h-full gap-4'>
        <img src={FlareLogo} alt='Suply Logo' />
        <ul className='flex items-center h-full hidden md:flex'>
          {ROUTES.map(item => (
            <Link
              data-testid={item.path}
              to={item.path}
              key={item.name}
              className={clsx(
                'hover:bg-slate-800 text-slate-400 hover:text-white px-3 py-2 rounded cursor-pointer whitespace-nowrap flex items-center',
                pathname.includes(item.path) && 'text-white'
              )}
            >
              {item.name}
            </Link>
          ))}
        </ul>
      </div>

      <div className='flex items-center gap-2'>
        <Link to='/settings' className='h-full' data-testid='settings-link'>
          <button
            className={clsx(
              'hover:bg-slate-800 text-slate-400 hover:text-white aspect-square h-[40px] rounded cursor-pointer whitespace-nowrap flex items-center justify-center',
              pathname.includes('settings') && 'text-white'
            )}
          >
            <GearSix />
          </button>
        </Link>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            {currentUser.isLoading ? (
              <Skeleton className='h-[40px] w-[200px] bg-slate-800' />
            ) : (
              <button
                data-test-id='profile-dropdown'
                className={clsx(
                  'bg-slate-800 text-white px-3 py-2 rounded cursor-pointer whitespace-nowrap flex items-center',
                  (pathname.includes('users') || pathname.includes('contacts')) &&
                    'text-white'
                )}
              >
                <span className='truncate w-full text-left font-medium'>
                  {currentUser?.data?.fullName}
                </span>

                <ChevronDown className='ml-2' />
              </button>
            )}
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <Link to='/profile' data-test-id='profile-link'>
              <DropdownMenuItem>Profile</DropdownMenuItem>
            </Link>
            <DropdownMenuSeparator />
            <DropdownMenuItem
              data-test-id='logout-btn'
              className='flex items-center justify-between'
              onClick={handleLogout}
            >
              Log Out
              <SignOut className='ml-2' />
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div className='h-full aspect-square flex items-center justify-center md:hidden'>
        <Button size={'icon'} variant={'secondary'}>
          <List />
        </Button>
      </div>
    </header>
  )
}

export default Navbar
