import React, { HTMLAttributes, forwardRef } from 'react'

import { Icon } from '@phosphor-icons/react'
import clsx from 'clsx'
import { ErrorBoundary } from 'react-error-boundary'

interface IBlock
  extends React.ForwardRefExoticComponent<
    BlockProps & React.RefAttributes<HTMLDivElement>
  > {
  Title: typeof Title
}

interface BlockProps extends HTMLAttributes<HTMLDivElement> {
  Title?: React.FC<HTMLAttributes<HTMLDivElement> & { icon: string }>
}

const forwardBlockRef = forwardRef<HTMLDivElement, BlockProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <section
        {...props}
        ref={ref}
        className={clsx(
          className,
          'border border-slate-300 rounded-lg bg-white drop-shadow p-6'
        )}
      >
        <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
      </section>
    )
  }
)

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
    </div>
  )
}

forwardBlockRef.displayName = 'Block'

const Title = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement> & { icon: Icon }>(
  (props, ref) => {
    return (
      <div className='flex items-center gap-2 text-lg' ref={ref}>
        <props.icon />
        <h1 className='font-semibold'>{props.children}</h1>
      </div>
    )
  }
)

Title.displayName = 'Title'

export default {
  ...forwardBlockRef,
  Title: Title,
} as IBlock
