import React from 'react'

import { Plus, Trash } from '@phosphor-icons/react'
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Searchbar from 'src/components/Searchbar'
import Subheader from 'src/components/Subheader'
import Table, { PaginationControls, usePagination } from 'src/components/Table'
import { Modal, openModal } from 'src/features/App/api/stores'
import { AddressDTO } from 'src/types'

import { useAddresses } from '../../api/queries'

import { addressesTableColumns } from './components'

const AddressesPageDesktop: React.FC = () => {
  const navigate = useNavigate()
  const { pagination, setPagination } = usePagination()
  const [search, setSearch] = React.useState('')

  const addresses = useAddresses({
    search,
    pageIndex: pagination.pageIndex + 1,
    pageSize: 10,
  })

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})
  const table = useReactTable<any>({
    data: (addresses?.data?.items as AddressDTO[]) || [],
    pageCount: addresses?.data?.paging?.pageCount ?? 0,
    columns: addressesTableColumns(navigate),
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    state: { sorting, rowSelection, pagination },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
  })

  const selectedIds =
    addresses?.data?.items &&
    Object.keys(rowSelection)
      .map(index => parseInt(index))
      .map(index => addresses?.data?.items && addresses?.data?.items[index]?.id)
  const selectedCount = Object.keys(rowSelection).length
  const deleteButtonText = `${selectedCount} ${selectedCount > 1 ? 'Selected' : 'Select'}`

  return (
    <div className='flex-1'>
      <Subheader
        title={'Addresses'}
        actions={
          <div className='flex items-center gap-2'>
            <Link to='/addresses/new'>
              <Button>
                <Plus className='mr-2' color='white' />
                New Address
              </Button>
            </Link>
          </div>
        }
      />

      <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
        <div className='container py-4 flex flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <Searchbar onSearch={setSearch} placeholder={'Search...'} value={search} />
              {selectedCount > 0 && (
                <div className='flex items-center gap-2'>
                  <p className={'font-medium text-base text-red'}>{deleteButtonText}</p>
                  <Button
                    variant={'outline'}
                    onClick={() =>
                      openModal(
                        Modal.DELETE_ADDRESS,
                        {
                          ids: selectedIds,
                        },
                        () => setRowSelection({})
                      )
                    }
                  >
                    <Trash className='mr-2' color='#dc2626' />

                    {`Delete ${selectedIds?.length == 1 ? 'Address' : 'Addresses'}`}
                  </Button>
                </div>
              )}
            </div>
            <PaginationControls table={table} />
          </div>
          <Table table={table} isLoading={addresses.isFetching} minHeight={15 * 60} />
          <div className='flex items-center justify-end'>
            <PaginationControls table={table} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AddressesPageDesktop
