import React from 'react'

const NotFoundPage = () => {
  return (
    <div className='flex-1 flex items-center justify-center text-slate-300 flex-col gap-4'>
      <h1 className='font-semibold' style={{ fontSize: 100 }}>
        404
      </h1>
      <p>The page you're looking for cannot be found</p>
    </div>
  )
}

export default NotFoundPage
