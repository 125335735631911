import React from 'react'

import { Trash } from '@phosphor-icons/react'
import { DotsThreeVertical } from '@phosphor-icons/react/dist/ssr'
import { createColumnHelper } from '@tanstack/react-table'
import { NavigateFunction } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Checkbox from 'src/components/Checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import RangePill from 'src/components/RangePill'
import { Modal, openModal } from 'src/features/App/api/stores'
import { SensorProfileDTO } from 'src/types/flare-alert-dev'

const columnHelper = createColumnHelper<SensorProfileDTO>()

export const sensorProfileTableColumns = (navigate: NavigateFunction) => [
  columnHelper.accessor('select', {
    id: 'select',
    size: 40,
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('name', {
    header: 'Profile Name',
    size: 400,
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('', {
    id: 'sensorThresholds',
    header: 'Sensor Thresholds',
    cell: info => {
      const profile = info.row.original
      return (
        <div className='flex items-center gap-2'>
          <RangePill
            isInactive={!profile.temperatureCheck}
            sensor={'temperature'}
            value={[profile.temperatureMinC, profile.temperatureMaxC]}
          />

          <RangePill
            isInactive={!profile.humidityCheck}
            sensor={'humidity'}
            value={[profile.humidityMinRh, profile.humidityMaxRh]}
          />
        </div>
      )
    },
  }),
  columnHelper.accessor('', {
    id: 'actions',
    size: 40,
    cell: info => {
      const profile = info.row.original
      return (
        <div className='flex items-center  justify-end  gap-2'>
          <Button
            variant={'outline'}
            onClick={() => navigate(`/sensor-profiles/${profile?.id?.toLowerCase()}`)}
          >
            View
            {/* View <i className='fas fa-chevron-right ml-2' /> */}
          </Button>

          <ProfileActionDropdown profile={profile} />
        </div>
      )
    },
  }),
]

export const ProfileActionDropdown = ({ profile }: { profile: any }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={'outline'} size={'icon'}>
          {/* <i className={'fas fa-ellipsis-v'} /> */}
          <DotsThreeVertical color='000' size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className='text-red-600 font-medium text-[14px] rounded'
          onClick={() => openModal(Modal.DELETE_SENSOR_PROFILE, { ids: [profile.id] })}
        >
          {/* <i className='fas fa-trash mr-2' /> */}
          <Trash className='mr-2' color='#dc2626' />
          Delete Sensor Profile
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
