import React, { forwardRef } from 'react'

import { X } from '@phosphor-icons/react'

interface TagProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string | number | React.ReactNode
  onDelete?: () => void
}

const Tag = forwardRef<HTMLDivElement, TagProps>((props, ref) => {
  return (
    <div
      className='bg-white drop-shadow border border-slate-300 rounded text-[12.5px] font-medium flex items-center h-[26px] w-fit'
      ref={ref}
    >
      <span className='truncate max-w-[200px] px-2'>{props.text}</span>

      {props.onDelete && (
        <button
          className='flex items-center justify-center h-full mr-2 text-[12px] hover:text-black'
          data-testid='tag-delete-button'
          onClick={() => props?.onDelete?.()}
        >
          <X size={12} />
        </button>
      )}
    </div>
  )
})

export default Tag
