import Cookies from 'js-cookie'

export const handleLogout = () => {
  Cookies.remove('flare-session')
  window.location.href = '/login'
}

export const validateBase64 = (value: string) => {
  const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
  return base64regex.test(value)
}
