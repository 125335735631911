import { LocationDTO } from 'src/types'

export const pointIconHtml = `<div id="point" class="rounded-full bg-blue-600 border-2 border-slate-100 h-5 w-5 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-10px] ml-[-10px]"><div class="h-2 w-2 bg-blue-800 rounded-full" /></div>`

export const expPointIconHtml = `<div class="rounded-full bg-red-600 border-2 border-slate-100 h-5 w-5 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-10px] ml-[-10px]"><div class="h-2 w-2 bg-zinc-800 rounded-full" /></div>`

export const noiseIconHtml = `
    <div class="rounded-full bg-zinc-600 border-4 border-slate-100 h-9 w-9 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-18px] ml-[-18px] animate-fadeIn">
      <div class="h-3 w-3 bg-zinc-800 rounded-full" />
    </div>
  `

export const activeIconHtml = `
    <div class="rounded-full bg-blue-600 border-4 border-slate-100 h-9 w-9 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-18px] ml-[-18px] animate-fadeIn">
      <div class="h-3 w-3 bg-zinc-800 rounded-full" />
    </div>
  `

export const clusterIconHtml = (count: number) => `
    <div class="rounded-full bg-zinc-600 border-4 border-slate-100 h-9 w-9 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-18px] ml-[-18px] animate-fadeIn">
      <span class="font-semibold text-white text-md">${count}</span>
    </div>
  `

export const expNoiseIconHtml = `
    <div class="rounded-full bg-red-600 border-4 border-slate-100 h-9 w-9 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-18px] ml-[-18px] animate-fadeIn">
      <div class="h-3 w-3 bg-zinc-800 rounded-full" />
    </div>
  `

export const expClusterIconHtml = (count: number) => `
    <div class="rounded-full bg-red-600 border-4 border-slate-100 h-9 w-9 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 mt-[-18px] ml-[-18px] animate-fadeIn">
      <span class="font-semibold text-white text-md">${count}</span>
    </div>
  `

export const unused = `<div class="opacity=0"></div>`

export const defaultMapOptions: google.maps.MapOptions = {
  center: { lat: 0, lng: 0 },
  zoom: 3,
  maxZoom: 16,
  minZoom: 2,
  disableDefaultUI: true,
  scaleControl: true,
  zoomControl: true,
  zoomControlOptions: {
    // position: google.maps.ControlPosition.RIGHT_CENTER,
    position: 8.0,
  },
  rotateControl: true,
  mapTypeId: 'roadmap',

  styles: [
    {
      featureType: 'poi',
      elementType: 'labels',
      stylers: [{ visibility: 'off' }],
    },
    {
      featureType: 'administrative',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#444444',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [
        {
          saturation: -100,
        },
        {
          lightness: 45,
        },
        {
          visibility: 'on',
        },
        {
          weight: '1.00',
        },
      ],
    },
    {
      featureType: 'road.highway',
      elementType: 'all',
      stylers: [
        {
          visibility: 'simplified',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit',
      elementType: 'all',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
  ],
}

export const convertLocationToLatLng = (
  location: LocationDTO | undefined
): google.maps.LatLng | undefined => {
  if (!location || !location.latitude || !location.longitude) return undefined
  return new google.maps.LatLng(location?.latitude, location?.longitude)
}
