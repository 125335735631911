import React from 'react'

import { CheckCircle } from '@phosphor-icons/react'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/Dialog'
import { closeModal } from 'src/features/App/api/stores'
import { queryClient } from 'src/helpers/queryClient'
import { ShipmentDTO } from 'src/types'

import { ShipmentStatus, useUpdateShipmentMutator } from '../../api'

const MarkShipmentCompleteModal = ({ shipment }: { shipment: ShipmentDTO }) => {
  const [open, setOpen] = React.useState(false)
  const updateShipment = useUpdateShipmentMutator(shipment?.id)

  const handleSubmit = async () => {
    await updateShipment.mutateAsync(
      { status: ShipmentStatus.Completed },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['/shipments', shipment?.id])
          toast.success('Shipment updated.')
          setOpen(false)
        },
      }
    )
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant='outline'>
          <CheckCircle className='mr-2' color='#2563eb' />
          <span>Mark as Complete</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Mark Shipment as Complete</DialogTitle>
        </DialogHeader>
        <div className='p-6'>
          Are you sure you want to mark this Shipment as complete?
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild>
            <Button
              variant='outline'
              onClick={closeModal}
              data-testid='closing-Complete-Status-modal'
            >
              Cancel
            </Button>
          </DialogClose>

          <Button data-testid='Change-Status-To-Complete' onClick={handleSubmit}>
            {updateShipment.isLoading && (
              <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            )}
            Mark as Complete
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default MarkShipmentCompleteModal
