import { ArrowsClockwise, Check, Pulse } from '@phosphor-icons/react'

import { POShipmentForm } from './types'

export const DUMMY_DETAILS: Record<string, any> = {
  'PO Number': 'PO00495',
  'PO Date': '04/26/2017',
  'Total Amount': '3,000.00',
  Currency: 'USD',
  'Buyer Name': 'Gojak',
  'Buyer Address': 'Post Office Sq , Suite 8902 Boston MA , 033981 USA',
  'Buyer Phone': '809-136-8903',
  'Buyer Email': 'winnie@gmail.com',
  'Vendor Name': 'Winnie Rachel',
  'Vendor Address': '68 Rower St. South Wales 09905 USA',
  'Ship To Name': 'Boston Office',
  'Ship To Address': 'Post Office Sq , Suite 8902 Boston MA , 033981 USA',
}

export const DUMMY_CARGO: {
  description: string
  product_code: string
  quantity: number
  value: string
}[] = [
  {
    description: 'Small Avocados',
    product_code: 'AVO-115',
    quantity: 500,
    value: (1.0).toFixed(2),
  },
  {
    description: 'Medium Avocados',
    product_code: 'AVO-116',
    quantity: 1000,
    value: (1.5).toFixed(2),
  },
  {
    description: 'Large Avocados',
    product_code: 'AVO-117',
    quantity: 500,
    value: (2.0).toFixed(2),
  },
]

export enum ShipmentStatus {
  All = -1,
  Pending = 0,
  Active = 1,
  Completed = 2,
}

export const statusMapping = {
  [ShipmentStatus.Pending]: {
    text: 'Pending',
    bgColor: 'bg-amber-100',
    icon: ArrowsClockwise,
    iconColor: '#d97706',
  },
  [ShipmentStatus.Active]: {
    text: 'Active',
    bgColor: 'bg-blue-100',
    icon: Pulse,
    iconColor: '#2563eb',
  },
  [ShipmentStatus.Completed]: {
    text: 'Completed',
    bgColor: 'bg-green-100',
    icon: Check,
    iconColor: '#16a34a',
  },
}

export const poShipmentFormDefaultValues: POShipmentForm = {
  name: '',
  date: new Date(),
  mode: '0',
  fromString: '',
  toString: '',
  isFinanced: false,
  isInsured: false,
  fromAddress: null,
  toAddress: null,
  devices: [],
  documents: [],
  recipients: [],
  pocargo: [],
  podetails: {},
  poDocumentName: '',
  terms: { label: 'FOB', value: '0' },
  sensorProfile: { label: '', value: '' },
}

export const termsOptions = [
  //   EXW (Ex Works)
  // FCA (Free Carrier)
  // FAS (Free Alongside Ship)
  // FOB (Free on Board)
  // CFR (Cost and Freight)
  // CPT (Carriage Paid To)
  // DAT (Delivered at Terminal)
  // DAP (Delivered at Place)
  // DDP (Delivered Duty ok Paid)
  { label: 'FOB', value: '0' },
  { label: 'EXW', value: '1' },
  { label: 'FCA', value: '2' },
  { label: 'FAS', value: '3' },
  { label: 'CFR', value: '4' },
  { label: 'CPT', value: '5' },
  { label: 'DAT', value: '6' },
  { label: 'DAP', value: '7' },
  { label: 'DDP', value: '8' },
]

export enum ShipmentTerms {
  FOB,
  EXW,
  FCA,
  FAS,
  CFR,
  CPT,
  DAT,
  DAP,
  DDP,
}
