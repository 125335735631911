import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { Modal, closeModal, useModals } from 'src/features/App/api/stores'

import { useDeleteAddressMutator } from '../../api/queries'

const ConfirmDeleteAddressDialog = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const state = useModals(state => state.state)
  const reset = useModals(state => state.callback)

  const isOpen = useModals(state => state.modal) == Modal.DELETE_ADDRESS
  const ids = state?.ids as string[]

  const deleteAddress = useDeleteAddressMutator(ids)
  const handleSubmit = async () => {
    await deleteAddress.mutate(void 0, {
      onSuccess: async () => {
        closeModal()
        reset?.()
        await queryClient.invalidateQueries(['/addresses'])
        toast.success(ids?.length == 1 ? 'Address Deleted' : 'Addresses Deleted')
        navigate('/addresses')
      },
    })
  }
  return (
    <Dialog open={isOpen}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Delete Address</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2'>
          {`Are you sure you want to delete ${ids?.length == 1 ? 'this' : 'these'} ${
            ids?.length == 1 ? 'Address' : 'Addresses'
          }?`}
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild>
            <Button variant='secondary' onClick={closeModal}>
              Close
            </Button>
          </DialogClose>
          <Button
            variant='destructive'
            disabled={deleteAddress.isLoading}
            onClick={handleSubmit}
          >
            {deleteAddress.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDeleteAddressDialog
