import * as React from 'react'

import { cn } from 'src/utils'

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean
  isError?: boolean
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, isValid, isError, ...props }, ref) => {
    return (
      <input
        type={type}
        className={cn(
          'flex h-[36px] rounded border border-slate-300 text-[14px] bg-white px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300',
          isValid && 'border-green-600',
          isError && 'border-red-600',
          className
        )}
        ref={ref}
        {...props}
      />
    )
  }
)

// @ts-ignore
Input.displayName = 'Input'

export { Input }

export const ErrorDisplay = ({ context, name }: { context: any; name: string }) => {
  return context.formState.errors[name] ? (
    <p className='text-red-600 text-sm'>{context.formState.errors[name].message}</p>
  ) : null
}
