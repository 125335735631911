import React, { useCallback, useEffect, useRef } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FloppyDisk, Prohibit, Trash } from '@phosphor-icons/react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Button } from 'src/components/Button'
import { Skeleton } from 'src/components/Skeleton'
import Subheader from 'src/components/Subheader'
import { Modal, openModal } from 'src/features/App/api/stores'

import {
  AddressDetails,
  mapAddressDTOToDetails,
  mapAddressDetailsToForm,
  mapFormToPatchAddressDTO,
  mapToPatchOperation,
  useAddress,
  useAddressStore,
  useUpdateAddressMutator,
} from '../../api'
import { addressSchema } from '../../api/resolvers'

import { AddressDetailsBlock, AddressMapBlock } from './components'

type UpdateAddressSchema = z.infer<typeof addressSchema.update>

export const defaultAddressForm: UpdateAddressSchema = {
  addressLine1: '',
  addressLine2: '',
  addressLine3: '',
  city: '',
  country: null,
  province: '',
  postalCode: '',
  position: null,
}

const SingleAddressPageDesktop = () => {
  const navigate = useNavigate()
  const { id } = useParams() as { id: string }
  const resetAutoRef = useRef<(() => void) | null>(null)
  const storedAddress = useAddressStore(s => s.address)
  const isdirty = useAddressStore(s => s.isdirty)

  const form = useForm<UpdateAddressSchema>({
    defaultValues: defaultAddressForm,
    resolver: zodResolver(addressSchema.update),
  })
  const apiAddress = useAddress(id)
  const resetAutoRefInChild = useCallback((resetFunction: () => void) => {
    resetAutoRef.current = resetFunction
  }, [])

  const resetForm = useCallback(() => {
    if (storedAddress) {
      form.reset(mapAddressDetailsToForm(storedAddress))
    }
  }, [form, storedAddress])

  useEffect(() => {
    if (storedAddress !== null) {
      resetForm()
    }
  }, [storedAddress])

  useEffect(() => {
    if (apiAddress.data) {
      useAddressStore.getState().updateMap(mapAddressDTOToDetails(apiAddress.data))
    }
  }, [apiAddress.data])

  useEffect(() => {
    return () => {
      useAddressStore.getState().reset()
      if (resetAutoRef.current) {
        resetAutoRef.current()
      }
    }
  }, [])
  const updateAddress = useUpdateAddressMutator(id)
  const updateAddressForm = form.handleSubmit(
    async () => {
      const mapFormToPatchAddressDTOvalues = mapFormToPatchAddressDTO(form.getValues())
      const values = mapToPatchOperation(
        { ...mapFormToPatchAddressDTOvalues, name: apiAddress?.data?.name || '' },
        apiAddress?.data
      )
      return updateAddress.mutate(values, {
        onSuccess: async () => {
          await apiAddress.refetch()
          toast.success('Addess updated successfully')
          navigate('/addresses')
        },
      })
    },
    error => {
      console.log(error)
    }
  )
  return (
    <FormProvider {...form}>
      <div className='flex-1' data-testid='address-page-desktop'>
        <Subheader
          returnTo='/addresses'
          title={apiAddress?.data?.name || <Skeleton className='w-[200px] h-[36px]' />}
          actions={
            <div className='flex items-center gap-2'>
              <Button
                variant={'outline'}
                disabled={!isdirty && !form.formState.isDirty}
                data-testid='cancel-btn'
                onClick={() => {
                  form.reset()
                  useAddressStore.getState().reset()
                  if (resetAutoRef.current) {
                    resetAutoRef.current()
                  }
                }}
              >
                <Prohibit className='mr-2' />
                Cancel
              </Button>
              <Button
                variant={'outline'}
                disabled={!isdirty && !form.formState.isDirty}
                data-testid='save-btn'
                onClick={() => {
                  updateAddressForm()
                }}
              >
                <FloppyDisk className='mr-2' />
                Save Changes
              </Button>

              <Button
                variant='outline'
                onClick={() =>
                  openModal(Modal.DELETE_ADDRESS, {
                    ids: [apiAddress?.data?.id],
                  })
                }
              >
                <Trash className='mr-2' color='#dc2626' />
                Delete Address
              </Button>
            </div>
          }
        />

        <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
          <div className='container py-4 grid grid-cols-1 md:grid-cols-2 gap-4'>
            <AddressDetailsBlock resetAutoRefInChild={resetAutoRefInChild} />
            <AddressMapBlock
              onAddressUpdate={addressDetails => {
                const appendedAddressDetails: AddressDetails = {
                  ...addressDetails,
                  name: apiAddress?.data?.name || '',
                }
                useAddressStore.setState({ isdirty: true })
                form.reset(mapAddressDetailsToForm(appendedAddressDetails))
              }}
            />
          </div>
        </div>
      </div>
    </FormProvider>
  )
}

export default SingleAddressPageDesktop
