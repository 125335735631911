import React from 'react'

import { Outlet } from 'react-router-dom'

import ModalContainer from './ModalContainer'
import Navbar from './Navbar'

const Layout = () => {
  return (
    <div className='min-h-screen flex flex-col'>
      <ModalContainer />
      <Navbar />
      <main className='flex-1 flex'>
        <Outlet />
      </main>
    </div>
  )
}

export default Layout
