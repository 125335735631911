import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

export const poShipmentSchema = z.object({
  name: z.string().min(1),
  date: z.date({ invalid_type_error: 'Date is required' }),
  mode: z.string(),
  fromString: z.any(),
  toString: z.any(),
  fromAddress: z.any(),
  toAddress: z.any(),
  devices: z.array(z.any()),
  documents: z.any(),
  poDocumentName: z.string().min(1),
  terms: z.object({
    label: z.string().min(1),
    value: z.string().min(1),
  }),
  isFinanced: z.boolean(),
  isInsured: z.boolean(),
  podetails: z.any(),
  pocargo: z.any(),
  recipients: z.array(z.record(z.string(), z.any())),
  sensorProfile: z.object(
    {
      label: z.string().min(1),
      value: z.string().min(1, { message: 'Sensor Profile is required' }),
    },
    { invalid_type_error: 'Sensor Profile is required' }
  ),
})

export const poShipmentResolver = zodResolver(poShipmentSchema)

export const createBolRefSchema = z.object({
  bolRef: z.string().min(1, { message: 'BOL Ref is required' }),
})

export type CreateBolRefSchema = z.infer<typeof createBolRefSchema>
