import React from 'react'

import { BatteryCharging, DropHalf, Icon, Thermometer } from '@phosphor-icons/react'

import { useCurrentUser } from 'src/features/Users'
import { celsiusToFahrenheit } from 'src/helpers/utils'

const SENSOR_ICONS = {
  temperature: Thermometer,
  humidity: DropHalf,
  battery: BatteryCharging,
}

const METRICS = {
  humidity: '%rh',
  temperature: '°',
  battery: '%',
}

interface RangePillProps {
  value: [number | null | undefined, number | null | undefined]
  sensor: string
  isInactive?: boolean
}

const RangePill = (props: RangePillProps) => {
  const Icon = SENSOR_ICONS[props.sensor] as Icon
  const tempMetric = useCurrentUser()?.data?.tempMetric
  const metric = `${METRICS[props.sensor]}${
    props.sensor === 'temperature' ? tempMetric : ''
  }`

  return (
    <div className='flex items-center gap-1 rounded border border-slate-300 py-1 px-2 text-xs'>
      {/* <i className={clsx(icon, 'text-blue-500 mr-1')} /> */}
      <Icon color='#2563eb' className='mr-1' size={14} />

      {props.isInactive ? (
        <div className='text-slate-400 font-semibold'>INACTIVE</div>
      ) : (
        <>
          {props.sensor === 'temperature' && tempMetric === '°F'
            ? `${celsiusToFahrenheit(props.value[0] as number)}${tempMetric}`
            : tempMetric === '°C'
            ? `${(props?.value[0] as number).toFixed(1)}${metric}`
            : `${props.value[0]}${metric}`}
          {props.value[1] && <span>to</span>}
          {props.value[1] && (
            <span>
              {props.sensor === 'temperature' && tempMetric === '°F'
                ? `${celsiusToFahrenheit(props.value[1] as number)}${tempMetric}`
                : tempMetric === '°C'
                ? `${props.value[1].toFixed(1)}${metric}`
                : `${props.value[1]}${metric}`}
            </span>
          )}
        </>
      )}
    </div>
  )
}

export default RangePill
