import React, { useEffect, useMemo, useRef, useState } from 'react'

import { Drop, Thermometer, Timer } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { title } from 'radash'
import { useLocation, useParams } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import {
  CartesianGrid,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { Button } from 'src/components/Button'
import Subheader from 'src/components/Subheader'
import { ShipmentJourneyDetails } from 'src/features/POShipment/views/components'
import {
  useLatestTelemetry,
  usePositionTelemetry,
  usePowerTelemetry,
  useSensorTelemetry,
} from 'src/features/Telemetry/queries'
import { useCurrentUser } from 'src/features/Users'
import { defaultMapOptions } from 'src/helpers/gmap'
import { createHTMLMapMarkerFactoy } from 'src/helpers/markerFactory'
import { celsiusToFahrenheit, getModeIcon, getTimeAgo } from 'src/helpers/utils'
import { ContainerDTO } from 'src/types'

import { useDevice } from '../../api/queries'

const SingleFlarePageDesktop: React.FC = () => {
  const location = useLocation()
  const { id } = useParams() as { id: string }
  const device = useDevice(id)
  const sensor = useLatestTelemetry(id, 2)
  const tempMetric = useCurrentUser()?.data?.tempMetric
  const { state } = location
  return (
    <div className='flex-1'>
      <Subheader title={device.data?.name} returnTo={-1} />
      <div className='overflow-auto bg-slate-100 h-[calc(100vh-128px)]'>
        <div className='container py-4 flex flex-col gap-4'>
          <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
            <div className='flex items-center'>
              <div className='flex items-center justify-start flex-1 gap-2'>
                <p className='font-light text-sm'>In Shipment: </p>
                <i
                  className={`ph-light ${getModeIcon(
                    state?.legs?.[0]?.transportMode
                  )} text-blue-600`}
                />
                <h2 className='font-semibold'> {state.name}</h2>
              </div>
              <div className='flex items-center justify-end font-light'>
                <ShipmentJourneyDetails shipment={state} />
              </div>
            </div>
          </div>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <p className='text-sm'>Latest Update</p>
              {sensor.data?.temperature && (
                <div className='flex items-center text-sm bg-white border border-slate-300 rounded px-2 py-1 w-fit'>
                  <Thermometer className='mr-2' color='#2563eb' size={12} />
                  {/* <i className='mr-2 fas fa-temperature-list text-blue-600 text-xs' /> */}
                  {sensor.data.temperature?.toFixed(2)}°{tempMetric}
                </div>
              )}

              {sensor.data?.humidity && (
                <div className='flex items-center text-sm bg-white border border-slate-300 rounded px-2 py-1 w-fit'>
                  <Drop className='mr-2' color='#2563eb' size={12} />
                  {/* <i className='mr-2 fas fa-droplet text-blue-600 text-xs' /> */}
                  {sensor.data.humidity?.toFixed(2)}%
                </div>
              )}

              {sensor.data?.timeStamp && (
                <div className='flex items-center ml-auto text-sm text-slate-600'>
                  <Timer className='mr-1' size={14} />
                  {/* <i className='far fa-stopwatch mr-1' /> */}
                  {getTimeAgo(sensor.data.timeStamp)}
                </div>
              )}
            </div>
          </div>
          <DeviceMap />
          <DeviceCharts />
        </div>
      </div>
    </div>
  )
}

export default SingleFlarePageDesktop

const DeviceMap = () => {
  const { id } = useParams() as { id: string }
  const mapRef = useRef<HTMLDivElement>(null)
  const positions = usePositionTelemetry(id)?.data

  const latestLocation = positions?.locations[positions?.locations?.length - 1]
  const [map, setMap] = useState<google.maps.Map | null>(null)

  useEffect(() => {
    if (mapRef.current) {
      setMap(new google.maps.Map(mapRef.current, defaultMapOptions))
    }
  }, [mapRef])

  useEffect(() => {
    if (map && latestLocation) {
      const HTMLMapMarker = createHTMLMapMarkerFactoy(map)
      const latlng = new google.maps.LatLng(
        latestLocation?.latitude,
        latestLocation?.longitude
      )

      // Create a new map marker at the latest location
      new HTMLMapMarker({ position: latlng, color: 'blue' })
      // Center the map to the latest location
      map.setCenter(latlng)
    }
  }, [latestLocation, map])

  return (
    <div className='bg-white rounded-lg overflow-hidden drop-shadow border border-slate-300 h-[400px]'>
      <div ref={mapRef} className='w-full h-full'></div>
    </div>
  )
}

const sensors = ['temperature', 'humidity']

const DeviceCharts = () => {
  const location = useLocation()
  const { state } = location
  const { id } = useParams() as { id: string }
  const [chart, setChart] = useState<string>('temperature')
  const telemetry = useSensorTelemetry(id)
  const tempMetric = useCurrentUser().data?.tempMetric
  const containerData: ContainerDTO = state?.containers?.[0]

  const battery = usePowerTelemetry(id)

  const readings = useMemo(() => {
    if (!telemetry.data?.telemetryReadings) return []
    return telemetry.data?.telemetryReadings.map(r => ({
      ...r,
      temperature:
        tempMetric === 'F' ? celsiusToFahrenheit(r.temperature) : r.temperature,
    }))
  }, [telemetry.data, tempMetric])
  const minTemp =
    tempMetric === 'F'
      ? celsiusToFahrenheit(containerData?.sensorProfile?.temperatureMinC || 0)
      : containerData?.sensorProfile?.temperatureMinC
  const maxTemp =
    tempMetric === 'F'
      ? celsiusToFahrenheit(containerData?.sensorProfile?.temperatureMaxC || 0)
      : containerData?.sensorProfile?.temperatureMaxC
  const minHumidity = containerData?.sensorProfile?.humidityMinRh
  const maxHumidity = containerData?.sensorProfile?.humidityMaxRh
  const minLine = chart === 'temperature' ? minTemp : minHumidity
  const maxLine = chart === 'temperature' ? maxTemp : maxHumidity

  const renderTooltipContent = (props: any) => {
    const { active, payload, label } = props
    if (active && payload && payload.length) {
      const currentData = payload[0].value
      const date = label
      const unit = chart === 'temperature' ? `°${tempMetric}` : '%'
      return (
        <div className='custom-tooltip text-sm bg-white p-2 rounded'>
          <p>Date: {format(new Date(date), 'H:m dd/MM/yyyy')}</p>
          <p>
            Current {chart === 'temperature' ? 'Temperature' : 'Humidity'}: {currentData}
            {unit}
          </p>
          <p className='text-red-500'>
            Max {chart === 'temperature' ? 'Temperature' : 'Humidity'}: {minLine}
            {unit}
          </p>
          <p className='text-red-500'>
            Min {chart === 'temperature' ? 'Temperature' : 'Humidity'}: {maxLine}
            {unit}
          </p>
        </div>
      )
    }

    return null
  }

  return (
    <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
      <div className='flex gap-2'>
        {sensors.map(sensor => (
          <Button
            key={sensor}
            variant={sensor == chart ? 'default' : 'outline'}
            onClick={() => setChart(sensor)}
          >
            {sensor === 'temperature' ? (
              <Thermometer
                className='mr-2'
                color={`${sensor == chart ? '#BFDBFE' : '#94A3B8'} `}
                size={12}
              />
            ) : (
              <Drop
                className='mr-2'
                color={`${sensor == chart ? '#BFDBFE' : '#94A3B8'} `}
                size={12}
              />
            )}
            {sensor == 'percentcharge' ? 'Battery' : title(sensor)}
          </Button>
        ))}
      </div>
      <div style={{ width: '100%', height: 300 }}>
        {telemetry.isLoading ? (
          <div className='flex items-center justify-center h-full'>
            <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
          </div>
        ) : telemetry.data?.telemetryReadings.length == 0 ? (
          <div>Nothing there!</div>
        ) : (
          <ResponsiveContainer>
            <LineChart
              data={chart == 'percentcharge' ? battery.data?.powerReadings : readings}
            >
              <Line
                isAnimationActive={false}
                type='monotone'
                dataKey={chart}
                stroke='#3b82f6'
                dot={false}
                strokeWidth={2}
              />
              <CartesianGrid stroke='#ccc' strokeDasharray='5 5' />
              <XAxis
                dataKey='timeStamp'
                minTickGap={15}
                tickFormatter={c => format(new Date(c), 'dd/MM/yy')}
              />
              <YAxis
                type='number'
                domain={[0, 100]}
                max={maxLine?.toString() || '0'}
                min={minLine?.toString() || '100'}
                tickFormatter={c =>
                  chart == 'temperature' ? `${c}°${tempMetric}` : `${c}%`
                }
              />
              <Tooltip content={renderTooltipContent} />
              {minLine && (
                <ReferenceLine
                  y={minLine?.toString()}
                  stroke='red'
                  strokeWidth={2}
                  strokeDasharray='1 1'
                  label='Min'
                />
              )}
              {maxLine && (
                <ReferenceLine
                  y={maxLine?.toString()}
                  stroke='red'
                  strokeWidth={2}
                  strokeDasharray='1 1'
                  label='Max'
                />
              )}
            </LineChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  )
}
