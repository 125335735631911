import React from 'react'

import { ConfirmDeleteAddressDialog } from 'src/features/Addresses'
import { ConfirmDeleteReportDialog } from 'src/features/Reports'
import { ConfirmDeleteSensorProfile } from 'src/features/SensorProfiles'
import { ConfirmDisableUserDialog, ConfirmEnableUserDialog } from 'src/features/Users'

const ModalContainer = () => {
  return (
    <>
      <ConfirmDisableUserDialog />
      <ConfirmEnableUserDialog />
      <ConfirmDeleteReportDialog />
      <ConfirmDeleteAddressDialog />
      <ConfirmDeleteSensorProfile />
    </>
  )
}

export default ModalContainer
