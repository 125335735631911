import { ArrowsClockwise, Check, Pulse } from '@phosphor-icons/react'

import { ShipmentForm } from './resolvers'

export enum ShipmentStatus {
  All = -1,
  Pending = 0,
  Active = 1,
  Completed = 2,
}

export const statusMapping = {
  [ShipmentStatus.Pending]: {
    text: 'Pending',
    bgColor: 'bg-amber-100',
    icon: ArrowsClockwise,
    iconColor: '#d97706',
  },
  [ShipmentStatus.Active]: {
    text: 'Active',
    bgColor: 'bg-blue-100',
    icon: Pulse,
    iconColor: '#2563eb',
  },
  [ShipmentStatus.Completed]: {
    text: 'Completed',
    bgColor: 'bg-green-100',
    icon: Check,
    iconColor: '#16a34a',
  },
}

export const shippingTermOptions = [
  { label: 'FOB', value: '0' }, // Ex Works
  { label: 'EXW', value: '1' }, // Free Carrier
  { label: 'FCA', value: '2' }, // Free Alongside Ship
  { label: 'FAS', value: '3' }, // Free on Board
  { label: 'CFR', value: '4' }, // Cost and Freight
  { label: 'CPT', value: '5' }, // Carriage Paid To
  { label: 'DAT', value: '6' }, // Delivered at Terminal
  { label: 'DAP', value: '7' }, // Delivered at Place
  { label: 'DDP', value: '8' }, // Delivered Duty ok Paid
]

export const defaultShipmentForm: ShipmentForm = {
  name: '',
  shipTo: '',
  shipFrom: '',
  shipMode: '0',
  shipDate: new Date(),
  sensorProfile: { label: '', value: '' },
  devices: [],
  devicesToAdd: [],
  devicesToRemove: [],
  shipTerms: shippingTermOptions[0],
  isFinanced: false,
  isInsured: false,
  purchaseOrder: null,
  recipients: [],
  documents: [],
  recipientsToAdd: [],
  recipientsToRemove: [],
  documentsToAdd: [],
  documentsToRemove: [],
  shipToAddress: '',
  shipFromAddress: '',
}

export const FAKE_PURCHASE_ORDER = {
  buyer_name: 'John Doe',
  buyer_email: '',
  buyer_phone: '',
  buyer_address: '',
  buyer_vat_number: '',
  po_number: `PO_${Math.floor(Math.random() * 1000)}`,
  po_date: '2021-10-10',
  delivery_date: '2021-10-10',
  vendor_name: 'Vendor Inc',
  vendor_email: '',
  vendor_id: '',
  vendor_address: '33 Wall Street, New York, NY 10005, USA',
  vendor_vat_number: '',
  currency: 'USD',
  net_amount: 100,
  subtotal: 100,
  total_tax: 10,
  total_amount: 110,
  net_discount: 0,
  total_tax_percentage: 10,
  ship_to_name: 'John Doe',
  ship_to_address: '26 Boardwalk, Atlantic City, NJ 08401, USA',
  line_items: [
    {
      description: 'Product 1',
      product_code: 'PO-1',
      quantity: 100,
      price: 1,
    },
  ],
}
