import React from 'react'

import {
  Cube,
  DeviceMobile,
  DotsThreeVertical,
  SquaresFour,
  Trash,
} from '@phosphor-icons/react'
import { PopoverTrigger } from '@radix-ui/react-popover'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'

import { Button } from 'src/components/Button'
import Checkbox from 'src/components/Checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { Popover, PopoverContent } from 'src/components/Popover'
import { Modal, openModal } from 'src/features/App/api/stores'

const columnHelper = createColumnHelper<any>()

export const reportsTableColumns = [
  columnHelper.accessor('select', {
    id: 'select',
    size: 40,
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('contents', {
    enableSorting: false,
    header: 'Contents',
    cell: info => {
      const { devices, shipments } = info.row.original

      return <ContentsPopover devices={devices} shipments={shipments} />
    },
  }),
  columnHelper.accessor('creationDateInternal', {
    header: 'Created On',
    cell: info => (
      <div>{format(new Date(String(info.getValue() ?? '')), 'dd/MM/yyyy')}</div>
    ),
  }),
  columnHelper.accessor('', {
    id: 'actions',
    size: 50,
    cell: info => {
      const report = info.row.original

      return (
        <div className='flex items-center justify-end gap-2'>
          <Button variant={'outline'}>
            Download
            <i className='far fa-download ml-2' />
          </Button>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'outline'} size={'icon'}>
                {/* <i className={'fas fa-ellipsis-v'} /> */}
                <DotsThreeVertical color='000' size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                className='text-red-600 font-medium text-[14px] rounded'
                onClick={() => openModal(Modal.DELETE_REPORT, report)}
              >
                <Trash className='mr-2' color='#dc2626' />
                {/* <i className='fas fa-trash mr-2' /> */}
                Delete Report
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )
    },
  }),
]

const ContentsPopover = ({
  devices,
  shipments,
}: {
  devices: string[]
  shipments: string[]
}) => {
  const [open, setOpen] = React.useState(false)

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant={'outline'}>
          <SquaresFour className='mr-2' />
          {/* <i className='far fa-folder-grid mr-2' /> */}
          {devices.length > 0 && `${devices.length} Devices`}
          {shipments.length > 0 && `, ${shipments.length} Shipments`}
        </Button>
      </PopoverTrigger>

      <PopoverContent className='max-h-[300px] overflow-y-auto'>
        <div className='flex flex-col gap-2'>
          {devices.map(device => (
            <div className='flex items-center gap-2' key={device}>
              {/* <i className='fas fa-mobile-alt' /> */}
              <DeviceMobile />
              <span>{device}</span>
            </div>
          ))}
          {shipments.map(shipment => (
            <div className='flex items-center gap-2' key={shipment}>
              {/* <i className='fas fa-box' /> */}
              <Cube />
              <span>{shipment}</span>
            </div>
          ))}
        </div>
      </PopoverContent>
    </Popover>
  )
}
