'use client'

import * as React from 'react'

import { CalendarDots } from '@phosphor-icons/react'
import { format } from 'date-fns'
import { DateRange, Matcher } from 'react-day-picker'

import { Button } from 'src/components/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/Popover'
import { cn } from 'src/utils'

import { Calendar } from './Calendar'

type DatePickerProps = {
  className?: string
  date?: DateRange | undefined
  onChange?: (newDate: DateRange | Date | undefined) => void
  mode?: 'single' | 'range'
  placeholder?: string
  disabled?: Matcher | Matcher[] | undefined
}

const DatePicker = React.forwardRef<HTMLButtonElement, DatePickerProps>(
  (
    {
      className,
      date: controlledDate,
      onChange,
      mode = 'range',
      placeholder,
      disabled,
    }: DatePickerProps,
    ref
  ) => {
    const [date, setDate] = React.useState<DateRange | Date | undefined>(controlledDate)
    const range = mode === 'range' ? (date as DateRange) : undefined

    // Update the local state when the controlled prop changes
    React.useEffect(() => {
      setDate(controlledDate)
    }, [controlledDate])

    const handleDateChange = (newDate: Date | undefined) => {
      setDate(newDate)
      onChange?.(newDate) // Notify parent component about the change
    }

    const handleRangeChange = (newRange: DateRange | undefined) => {
      setDate(newRange)
      onChange?.(newRange)
    }

    return (
      <div className={cn('grid gap-2', className)}>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              id='date'
              variant={'outline'}
              className={cn(
                'justify-start text-left font-normal text-[14px]',
                !date && 'text-muted-foreground'
              )}
            >
              <CalendarDots className='mr-2' />

              {mode == 'range' ? (
                <>
                  {range?.from ? (
                    range?.to ? (
                      <>
                        {format(range.from, 'LLL dd, y')} -{' '}
                        {format(range.to, 'LLL dd, y')}
                      </>
                    ) : (
                      format(range.from, 'LLL dd, y')
                    )
                  ) : (
                    <span>Pick a date</span>
                  )}
                </>
              ) : date ? (
                format(date as Date, 'LLL dd, y')
              ) : (
                <span className='text-slate-500'>{placeholder ?? 'Select a date'}</span>
              )}
            </Button>
          </PopoverTrigger>
          <PopoverContent className='w-auto p-0' align='start'>
            {JSON.stringify(disabled)}
            {mode === 'single' ? (
              <Calendar
                initialFocus
                mode='single'
                defaultMonth={date as Date}
                selected={date as Date} // Use 'as Date' to cast DateRange to Date
                onSelect={handleDateChange}
                disabled={{ before: new Date() }}
              />
            ) : (
              <Calendar
                initialFocus
                mode='range'
                defaultMonth={range?.from}
                selected={range}
                onSelect={handleRangeChange}
                disabled={disabled}
              />
            )}
          </PopoverContent>
        </Popover>
      </div>
    )
  }
)

export { DatePicker }
