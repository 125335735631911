import React, { useEffect } from 'react'

import {
  Check,
  CircleNotch,
  CloudArrowUp,
  FloppyDisk,
  Prohibit,
} from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import { AnimatePresence, motion } from 'framer-motion'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import Subheader from 'src/components/Subheader'
import {
  POShipmentForm,
  poShipmentFormDefaultValues,
  poShipmentResolver,
  useCreatePOShipmentMutator,
} from 'src/features/POShipment'
import { useSteps } from 'src/hooks/useStep'

import {
  ProcessUploadStep,
  PromptUploadStep,
  ShipmentSetupStep,
  VerifyDetailsStep,
} from './components'

const { nextStep, setStep } = useSteps.getState()

export const CreatePOShipmentPage = () => {
  const currentStep = useSteps(state => state.currentStep)

  const form = useForm<POShipmentForm>({
    defaultValues: poShipmentFormDefaultValues,
    resolver: poShipmentResolver,
  })

  useEffect(() => {
    return () => setStep(1) // cleanup useStep hook
  }, [])

  console.log('POShipment Form Errors:', form.formState.errors)

  return (
    <FormProvider {...form}>
      <div className='flex-1'>
        <Subheader title='Creating new Shipment' actions={<CreatePOShipmentActions />} />
        <div className='overflow-auto bg-slate-50 h-[calc(100vh-140.8px)]'>
          <div className='max-w-[1336px] mx-auto p-4 flex flex-col gap-4'>
            {currentStep <= 3 && (
              <>
                <p className='font-medium flex items-center justify-center gap-2'>
                  <CloudArrowUp color='#2563eb' />
                  {/* <i className='far fa-cloud-upload text-blue-600 mr-2' /> */}
                  Get started by uploading your Purchase Order, we’ll scan the file and
                  fill in the details for you.
                </p>
                <motion.div
                  layout
                  className='bg-white p-6 border border-slate-300 rounded drop-shadow h-fit'
                >
                  <AnimatePresence>
                    {currentStep == 1 && <PromptUploadStep />}
                    {currentStep == 2 && <ProcessUploadStep />}
                    {currentStep == 3 && <VerifyDetailsStep />}
                  </AnimatePresence>
                </motion.div>
              </>
            )}

            {currentStep == 4 && <ShipmentSetupStep />}
          </div>
        </div>
      </div>
    </FormProvider>
  )
}

export default CreatePOShipmentPage

const CreatePOShipmentActions = () => {
  const form = useFormContext<POShipmentForm>()
  const navigate = useNavigate()
  const currentStep = useSteps(state => state.currentStep)

  const createPOShipment = useCreatePOShipmentMutator()

  const handleSubmit = form.handleSubmit(async formData => {
    createPOShipment.mutate(formData, {
      onError: e => {
        if (e instanceof AxiosError) {
          e.response?.data && toast.error(e.response?.data)
        }
      },
      onSuccess: () => {
        navigate('/shipments')
        setStep(1)
        toast.success('Shipment Created.')
      },
    })
  })

  return (
    <div className='flex gap-2 items-center'>
      <Link
        to='/shipments'
        onClick={event => {
          if (createPOShipment?.isLoading) {
            event.preventDefault() // Prevent navigation when isLoading is true
          }
        }}
      >
        <Button variant='outline' disabled={createPOShipment?.isLoading}>
          <Prohibit />
          <span className='hidden sm:inline ml-2'>Cancel</span>
        </Button>
      </Link>

      {currentStep == 1 && (
        <Button onClick={nextStep} variant={'outline'} disabled>
          <Check className='mr-2' />
          {/* <i className='fas fa-check mr-2' /> */}
          Confirm Details
        </Button>
      )}

      {currentStep == 2 && (
        <Button onClick={nextStep} variant={'outline'} disabled>
          <Check className='mr-2' />
          {/* <i className='fas fa-check mr-2' /> */}
          Confirm Details
        </Button>
      )}

      {currentStep == 3 && (
        <Button onClick={nextStep} variant={'outline'}>
          <Check className='mr-2' />
          {/* <i className='fas fa-check mr-2' /> */}
          Confirm Details
        </Button>
      )}

      {currentStep == 4 && (
        <Button onClick={handleSubmit} disabled={createPOShipment?.isLoading}>
          {createPOShipment.isLoading ? (
            <CircleNotch color='#fff' className='mr-2 animate-spin' />
          ) : (
            <>
              <FloppyDisk className='mr-2' color='#fff' />
              {/* <i className='fas fa-floppy-disk mr-2' /> */}
            </>
          )}
          Create Shipment
        </Button>
      )}
    </div>
  )
}
