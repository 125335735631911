import React from 'react'

import { DotsThreeVertical } from '@phosphor-icons/react'
import { createColumnHelper } from '@tanstack/react-table'
import { format } from 'date-fns'

import { Button } from 'src/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { Modal, openModal } from 'src/features/App/api/stores'
import { AspNetUserDTO } from 'src/types/flare-user-dev'

import { UserStatus } from '../../api/types.d'

const columnHelper = createColumnHelper<AspNetUserDTO>()

export const usersTableColumns = [
  columnHelper.accessor('fullName', {
    header: 'Name',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('email', {
    header: 'Email',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('registrationDate', {
    header: 'Registration Date',
    cell: info => (
      <div>
        {(info.getValue() &&
          format(new Date(String(info.getValue() ?? '')), 'dd/MM/yyyy')) ||
          'NA'}
      </div>
    ),
  }),
  columnHelper.accessor('', {
    id: 'actions',
    size: 50,
    cell: info => {
      const user = info.row.original

      return (
        <div className='flex items-center justify-end'>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'outline'} size={'icon'}>
                <DotsThreeVertical color='000' size={20} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              {(user.status === UserStatus.Active ||
                user.status === UserStatus.Pending) && (
                <DropdownMenuItem
                  className='text-red-600 font-medium text-[14px] rounded'
                  onClick={() => openModal(Modal.DISABLE_USER, { user })}
                >
                  Disable User
                </DropdownMenuItem>
              )}
              {user.status === UserStatus.Disabled && (
                <DropdownMenuItem
                  className='font-medium text-[14px] rounded'
                  onClick={() => openModal(Modal.ENABLE_USER, { user })}
                >
                  Enable User
                </DropdownMenuItem>
              )}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )
    },
  }),
]
