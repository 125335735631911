import React, { forwardRef, useEffect } from 'react'

import { CheckFat } from '@phosphor-icons/react'
import clsx from 'clsx'

export interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const [checked, setChecked] = React.useState(props.checked ?? false)

  const handleCheck = (
    e: React.KeyboardEvent<HTMLDivElement> | React.MouseEventHandler<HTMLDivElement> | any
  ) => {
    if ((e as React.KeyboardEvent<HTMLDivElement>).key) {
      const _e = e as React.KeyboardEvent<HTMLDivElement>
      if (_e.key === 'Enter') {
        setChecked(!checked)
        props.onChange && props.onChange({ target: { checked: !checked } } as any)
      }
    } else {
      setChecked(!checked)
      props.onChange && props.onChange({ target: { checked: !checked } } as any)
    }
  }

  useEffect(() => {
    setChecked(props.checked ?? false)
  }, [props.checked])

  return (
    <div
      onClick={handleCheck}
      onKeyDown={handleCheck}
      tabIndex={0}
      className={clsx(
        checked ? 'bg-blue-600 border-blue-600' : 'bg-slate-100 border-slate-300',
        `w-5 h-5 border-2 rounded focus:ring-2 focus:ring-offset-1 focus:ring-blue-600 flex items-center justify-center cursor-pointer outline-none`
      )}
    >
      {checked && <CheckFat color='white' size={13} weight='fill' />}
    </div>
  )
})

export default Checkbox
