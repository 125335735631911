import React, { useState } from 'react'

import * as Slider from '@radix-ui/react-slider'
import clsx from 'clsx'
import { AnimatePresence, motion } from 'framer-motion'
import { title } from 'radash'
import { useFormContext } from 'react-hook-form'

import { Switch } from 'src/components/Switch'

interface SensorTresholdBlockProps {
  title: string
  min: number
  max: number
  defaultValue: number[]
  format: (value: number) => string
  icon: string
  viewOnly?: boolean
}

export const SensorThresholdBlock: React.FC<SensorTresholdBlockProps> = props => {
  const form = useFormContext()

  const name = title(props.title)
  const values = form.watch(`${props.title}Value`)
  const isEnabled = form.watch(`${props.title}Check`)

  // Hide if disabled and viewOnly
  if (!isEnabled && props.viewOnly) return null

  return (
    <div className='bg-white border border-slate-300 rounded-lg drop-shadow p-4 flex flex-col gap-2'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          {!props.viewOnly && (
            <Switch
              checked={isEnabled}
              onClick={() => form.setValue(`${props.title}Check`, !isEnabled)}
            />
          )}

          <div className='font-medium'>
            <i className={`fas ${props.icon} text-blue-600 mr-2`} />
            {name}
          </div>
        </div>
        <div>
          {values.length > 1 && (
            <span>
              Alert when <i className={`fas ${props.icon} text-blue-600`} /> is outside of{' '}
              {props.format(values[0])} - {props.format(values[1])}
            </span>
          )}
          {values.length == 1 && (
            <span>
              Alert when <i className={`fas ${props.icon} text-blue-600`} /> is below{' '}
              {props.format(values)}
            </span>
          )}
        </div>
      </div>

      <div className='h-[24px]'></div>

      <Slider.Root
        className='relative flex items-center select-none touch-none w-full h-5'
        defaultValue={props.defaultValue}
        onValueChange={v => form.setValue(`${props.title}Value`, v)}
        minStepsBetweenThumbs={1}
        disabled={props.viewOnly}
        min={props.min}
        max={props.max}
        step={1}
      >
        <Slider.Track
          className={clsx(
            props.viewOnly ? 'bg-slate-50' : 'bg-slate-300',
            'relative grow rounded-full h-[4px]'
          )}
        >
          {isEnabled && (
            <Slider.Range className='absolute bg-blue-500 rounded-full h-full' />
          )}
        </Slider.Track>

        {isEnabled &&
          values.length > 1 &&
          ['min', 'max'].map((thumb, i) => (
            <Thumb
              viewOnly={props.viewOnly}
              thumb={thumb}
              value={props.format?.(values?.[i])}
              key={thumb}
            />
          ))}

        {isEnabled && values.length == 1 && (
          <Thumb
            viewOnly={props.viewOnly}
            thumb={'min'}
            value={props.format?.(values?.[0])}
          />
        )}
      </Slider.Root>
      <div className='flex items-center justify-between text-medium text-slate-600'>
        <div>{props.format(props.min)}</div>
        <div>{props.format(props.max)}</div>
      </div>
    </div>
  )
}

const Thumb = ({ thumb, value, viewOnly }) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  return (
    <Slider.Thumb
      key={thumb}
      id={thumb}
      onMouseEnter={() => setIsActive(true)}
      onMouseLeave={() => setIsActive(false)}
      style={{ borderWidth: 4 }}
      className={clsx(
        viewOnly
          ? 'bg-blue-500 w-2 h-3 rounded-sm'
          : 'bg-white w-5 h-5 rounded-full focus:outline-none border-blue-600 hover:ring-4 hover:ring-blue-600 hover:ring-opacity-50 transition-all cursor-pointer',
        'block relative'
      )}
      aria-label={thumb}
    >
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ opacity: 0, y: 5 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 5 }}
            className='font-medium absolute bg-slate-700 text-white text-sm px-1.5 py-0.5 rounded drop-shadow'
            style={{ top: -36, right: -14 }}
          >
            {value}
          </motion.div>
        )}
      </AnimatePresence>
    </Slider.Thumb>
  )
}
