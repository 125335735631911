import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Plus } from '@phosphor-icons/react'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { CopyIcon, Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'

import { Button } from 'src/components/Button'
import { DatePicker } from 'src/components/DatePicker'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from 'src/components/Dialog'
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import Searchbar from 'src/components/Searchbar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/Select'
import Tag from 'src/components/Tag'

const KitchenSinkPage = () => {
  return (
    <div className='p-4 flex flex-col gap-6'>
      <Buttons />
      <Forms />
      <Dropdowns />
      <div>
        <h1>Searchbar</h1>
        <Searchbar onSearch={s => console.log(s)} />
      </div>
      <div>
        <h1>Tags</h1>
        <div className='flex items-center gap-2'>
          <Tag text={'Test Tag'} id={'one'} />
          <Tag
            text={'Something very long, hopefully long enough it that it overflows'}
            id={'two'}
          />
        </div>
      </div>
      <Dialogs />
    </div>
  )
}

export default KitchenSinkPage

const Buttons = () => {
  return (
    <div>
      <TooltipProvider>
        <h1>Buttons</h1>
        <div className='flex gap-2'>
          <Button>
            <Plus className='mr-2' color='white' size={14} />
            Default
          </Button>
          <Button variant={'outline'}>Outline</Button>
          <Button variant={'destructive'} disabled>
            <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            Destructive
          </Button>
          <Button variant={'secondary'}>Secondary</Button>
          <Button variant={'outline'} size={'icon'}>
            <Plus />
          </Button>
        </div>
      </TooltipProvider>
    </div>
  )
}

const languages = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Spanish', value: 'es' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Russian', value: 'ru' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Korean', value: 'ko' },
  { label: 'Chinese', value: 'zh' },
]

const formSchema = z.object({
  username: z.string().min(2, {
    message: 'Username must be at least 2 characters.',
  }),
  language: z.string().min(1, {
    message: 'Please select a language.',
  }),
  theme: z.string().min(1, {
    message: 'Please select a theme.',
  }),
  range: z.object({
    from: z.date({
      required_error: 'Please select a date.',
    }),
    to: z.date({
      required_error: 'Please select a date.',
    }),
  }),
  date: z.date({
    required_error: 'Please select a date.',
  }),
})

const Forms = () => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      username: '',
      language: '',
      theme: '',
      range: { from: undefined, to: undefined },
      date: new Date(),
    },
  })

  function onSubmit(values: z.infer<typeof formSchema>) {
    console.log(values)
  }

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit(onSubmit)}
        className='flex flex-col gap-6 p-4 border border-slate-300'
      >
        <div>
          <h1>Form + Input</h1>
          <FormField
            control={form.control}
            name='username'
            render={({ field }) => (
              <FormItem>
                <FormLabel>Username</FormLabel>
                <FormControl>
                  <div className='flex items-center gap-2'>
                    <Input placeholder='shadcn' {...field} />
                    <Button type='submit'>Submit</Button>
                  </div>
                </FormControl>

                <FormDescription>This is your public display name.</FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <h1>Form + Combobox</h1>
          <FormField
            control={form.control}
            name='language'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Language</FormLabel>
                <FormControl>
                  {/* <Combobox
                    options={languages}
                    onSelect={field.onChange}
                    
                  /> */}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <h1>Form + Select</h1>
          <FormField
            control={form.control}
            name='theme'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Theme</FormLabel>
                <FormControl>
                  <Select onValueChange={field.onChange} defaultValue={field.value}>
                    <SelectTrigger className='w-[180px]'>
                      <SelectValue placeholder='Theme' />
                    </SelectTrigger>
                    <SelectContent>
                      <SelectItem value='light'>Light</SelectItem>
                      <SelectItem value='dark'>Dark</SelectItem>
                      <SelectItem value='system'>System</SelectItem>
                    </SelectContent>
                  </Select>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <h1>Form + Range DatePicker</h1>
          <FormField
            control={form.control}
            name='range'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Date</FormLabel>
                <FormControl>
                  <DatePicker onChange={field.onChange} />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>

        <div>
          <h1>Form + Single DatePicker</h1>
          <FormField
            control={form.control}
            name='date'
            render={({ field }) => (
              <FormItem className='flex flex-col'>
                <FormLabel>Date</FormLabel>
                <FormControl>
                  <DatePicker onChange={field.onChange} mode='single' />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
      </form>
    </Form>
  )
}

const Dropdowns = () => {
  const [position, setPosition] = React.useState('bottom')

  return (
    <div className='flex flex-col gap-6'>
      <div>
        <h1>Basic Dropdown</h1>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant={'outline'}>Click Me</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem>Profile</DropdownMenuItem>
            <DropdownMenuItem>Billing</DropdownMenuItem>
            <DropdownMenuItem>Team</DropdownMenuItem>
            <DropdownMenuItem>Subscription</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div>
        <h1>Checkbox Dropdown</h1>

        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline'>Open</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-56'>
            <DropdownMenuLabel>Appearance</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuCheckboxItem checked={true}>Status Bar</DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem
              checked={false}
              onCheckedChange={console.log}
              disabled
            >
              Activity Bar
            </DropdownMenuCheckboxItem>
            <DropdownMenuCheckboxItem checked={false} onCheckedChange={console.log}>
              Panel
            </DropdownMenuCheckboxItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <div>
        <h1>Radio Dropdown</h1>
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline'>Open</Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent className='w-56'>
            <DropdownMenuLabel>Panel Position</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuRadioGroup value={position} onValueChange={setPosition}>
              <DropdownMenuRadioItem value='top'>Top</DropdownMenuRadioItem>
              <DropdownMenuRadioItem value='bottom'>Bottom</DropdownMenuRadioItem>
              <DropdownMenuRadioItem value='right'>Right</DropdownMenuRadioItem>
            </DropdownMenuRadioGroup>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}

const Dialogs = () => {
  return (
    <div>
      <h1>Dialogs</h1>
      <div>
        <Dialog>
          <DialogTrigger asChild>
            <Button variant='outline'>Share</Button>
          </DialogTrigger>
          <DialogContent className='sm:max-w-md'>
            <DialogHeader>
              <DialogTitle>Share link</DialogTitle>
              <DialogDescription>
                Anyone who has this link will be able to view this.
              </DialogDescription>
            </DialogHeader>
            <div className='flex items-center space-x-2'>
              <div className='grid flex-1 gap-2'>
                <Input
                  id='link'
                  defaultValue='https://ui.shadcn.com/docs/installation'
                  readOnly
                />
              </div>
              <Button type='submit' size='sm' className='px-3'>
                <span className='sr-only'>Copy</span>
                <CopyIcon className='h-4 w-4' />
              </Button>
            </div>
            <DialogFooter className='sm:justify-start'>
              <DialogClose asChild>
                <Button type='button' variant='secondary'>
                  Close
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
