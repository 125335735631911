import { z } from 'zod'

const addressProperties = {
  addressLine1: z
    .string()
    .trim()
    .min(1, { message: 'Address Line 1 is required' })
    .max(50, { message: 'Address Line 1 must be less than 50 characters' }),
  addressLine2: z
    .string()
    .max(50, { message: 'Address Line 2 must be less than 50 characters' }),
  addressLine3: z
    .string()
    .max(50, { message: 'Address Line 3 must be less than 50 characters' }),
  city: z
    .string()
    .trim()
    .min(1, { message: 'City is required' })
    .max(50, { message: 'City must be less than 50 characters' }),
  province: z
    .string()
    .trim()
    .max(50, { message: 'Province must be less than 50 characters' }),
  postalCode: z
    .string()
    .trim()
    .max(25, { message: 'Postal Code must be less than 10 characters' }),
  country: z
    .object({
      label: z.string(),
      value: z.string(),
    })
    .nullable()
    .refine(value => value !== null, {
      message: 'Country is required',
    }),
  position: z.any(),
}

const createAddressSchema = z.object({
  ...addressProperties,
  name: z
    .string()
    .trim()
    .min(1, { message: 'Name is required' })
    .max(50, { message: 'Name must be less than 50 characters' }),
})

const updateAddressSchema = z.object({
  ...addressProperties,
})

export const addressSchema = {
  create: createAddressSchema,
  update: updateAddressSchema,
}
