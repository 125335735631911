import { useMemo } from 'react'

import { useMutation, useQuery } from '@tanstack/react-query'

import { makeOptions } from 'src/helpers/utils'
import { getApi } from 'src/services'
import {
  AddressCountriesListData,
  AddressDTO,
  AddressDTOPagedResponse,
  CreateAddressDTO,
  PatchAddressDTO,
  PatchOp,
} from 'src/types'

export const useAddresses = (query = {}) => {
  return useQuery<AddressDTOPagedResponse>({
    queryKey: ['/addresses', query],
    queryFn: async () => {
      const res = await getApi()?.address.get('/address', { params: query })
      return res?.data
    },
  })
}

export const useAddress = (id: string | null | undefined) => {
  return useQuery<AddressDTO>({
    queryKey: ['/address', id],
    queryFn: async () => {
      if (!id) return null
      const res = await getApi()?.address.get(`/address/${id}`)
      return res?.data
    },
  })
}

export const useCreateAddressMutator = () => {
  return useMutation({
    mutationFn: async (form: CreateAddressDTO) => {
      const res = await getApi()?.address.post(`/address`, form)
      return res?.data
    },
  })
}

export const useUpdateAddressMutator = (addressId: string) => {
  return useMutation({
    mutationFn: async (operations: PatchOp<PatchAddressDTO>[]) => {
      const res = await getApi()?.address.patch(`/address/${addressId}`, operations)
      return res?.data
    },
  })
}

export const useDeleteAddressMutator = (ids: string[]) => {
  return useMutation({
    mutationFn: async () => {
      const res = await getApi()?.address.delete(`/address`, { data: ids })
      return (
        res?.data && { success: true, message: 'Addresses are deleted successfully.' }
      )
    },
  })
}

export const useCountries = (query = {}) => {
  return useQuery<AddressCountriesListData>({
    queryKey: ['/countries', query],
    queryFn: async () => {
      const res = await getApi()?.address.get('/address/countries', { params: query })
      return res?.data
    },
  })
}

export const useCountryOptions = () => {
  const countries = useCountries()

  const countryOptions =
    useMemo(
      () => countries.data && makeOptions(countries.data, 'name', 'code2'),
      [countries.data]
    ) ?? []

  if (!countries.isLoading && countryOptions?.length == 0) {
    console.warn('No countries found')
  }

  return countryOptions ?? []
}

// TODO - add a no paging option to paged queries
export const useAddressOptions = () => {
  const addresses = useAddresses({ pageSize: 1000 })

  const addressOptions =
    useMemo(
      () =>
        addresses.data &&
        makeOptions(addresses.data?.items as Record<string, any>[], 'name', 'id'),
      [addresses.data]
    ) ?? []

  if (!addresses.isLoading && addressOptions?.length == 0) {
    console.warn('No addresses found')
  }

  return addressOptions ?? []
}
