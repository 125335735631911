import { useMutation } from '@tanstack/react-query'

import { getApi } from 'src/services'

import { UseShipmentStore } from '../views/CreateShipmentForm/CreateShipmentFormPage'

import { FAKE_PURCHASE_ORDER, ShipmentStatus } from './constants'

const USE_FAKE_PURCHASE_ORDER = false

export const useScanFileMutator = () => {
  return useMutation({
    mutationFn: async (file: File | null) => {
      if (file === null) throw new Error('File is null')
      const formData = new FormData()
      formData.append('file', file)

      if (USE_FAKE_PURCHASE_ORDER) {
        await new Promise(resolve => setTimeout(resolve, 1000))
        return FAKE_PURCHASE_ORDER
      } else {
        const res = await getApi()?.shipments.post('/nanonets/workflow', formData)
        return res?.data
      }
    },
  })
}

export const useOrchestrateCreateShipmentMutator = () => {
  return useMutation({
    mutationFn: async (form: UseShipmentStore) => {
      const formData = new FormData()

      const files = [...form.shipDocuments, form.poFile]

      files.forEach(file => {
        formData.append('files', file)
      })

      const documentIds = await getApi()?.shipments.post('/azureblob', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      const createDto = {
        shipment: {
          name: form.shipName,
          shippingDate: form.shipDate.toDateString(),
          shippingTerm: Number(form.shipTerms.value),
          isFinanced: form.shipIsFinanced,
          isInsured: form.shipIsInsured,
          purchaseOrderId: null,
        },

        legs: [
          {
            fromAddress: form.shipFromString,
            toAddress: form.shipToString,
            transportMode: Number(form.shipMode),
          },
        ],

        containers: [
          {
            sensorProfileId: form.shipSensorProfile.value,
            deviceIds: form.shipDevices.flatMap(device =>
              device?.id ? [device.id] : []
            ),
          },
        ],

        documents: documentIds?.data.map(file => ({
          fileId: file.id,
          fileName: file.fileName,
          isPurchaseOrder: file.fileName === form.poFile?.name,
        })),

        recipients: [...form.shipUserRecipients, ...form.shipEmailRecipients].map(
          recipient => {
            if (typeof recipient === 'string') {
              return {
                name: recipient,
                emailAddress: recipient,
              }
            } else {
              return {
                name: recipient.fullName,
                emailAddress: recipient.email,
              }
            }
          }
        ),

        purchaseOrder: {
          buyerName: form.po.buyer_name,
          buyerEmail: form.po.buyer_email,
          buyerPhone: form.po.buyer_phone,
          buyerAddress: form.po.buyer_address,
          buyerVatNumber: form.po.buyer_vat_number,
          poNumber: form.po.po_number,
          vendorName: form.po.vendor_name,
          vendorEmail: form.po.vendor_email,
          vendorId: form.po.vendor_id,
          vendorAddress: form.po.vendor_address,
          vendorVatNumber: form.po.vendor_vat_number,
          shipToAddress: form.po.ship_to_address,
          shipToName: form.po.ship_to_name,
          currency: form.po.currency,
          netAmount: form.po.net_amount,
          subtotal: form.po.subtotal,
          totalTax: form.po.total_tax,
          totalAmount: form.po.total_amount,
          netDiscount: form.po.net_discount,
          totalTaxPercentage: form.po.total_tax_percentage,
          lineItems: JSON.stringify(form.po.line_items),
        },
      }

      // const createDto = ShipmentAdapter.toCreateDto(form, documents?.data ?? [])
      const res = await getApi()?.shipments.post('/shipment/orchestrate', createDto)
      return res?.data
    },
  })
}

export const useOrchestrateUpdateShipmentMutator = (id: string | null | undefined) => {
  return useMutation({
    mutationFn: async (form: any) => {
      const formData = new FormData()

      const files = form.shipDocuments.filter(f => (f as File).size)

      files.forEach(file => {
        formData.append('files', file)
      })

      const documentIds = await getApi()?.shipments.post('/azureblob', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      const updateDto = {
        shipmentId: id,
        containerId: form.containerId,
        legId: form.legId,
        deviceIds: form.shipDevices.flatMap(device => (device?.id ? [device.id] : [])),
        recipients: [
          ...form.shipUserRecipients.map(u => ({
            name: u.name,
            emailAddress: u.emailAddress,
          })),
          ...form.shipEmailRecipients.map(u => ({
            name: u,
            emailAddress: u,
          })),
        ],
        shippingTerm: Number(form.shipTerms.value),
        shippingDate: form.shipDate.toDateString(),
        shippingMode: Number(form.shipMode),
        sensorProfileId: form.shipSensorProfile.value,
        newDocuments: documentIds?.data.map(file => ({
          fileId: file.id,
          fileName: file.fileName,
          isPurchaseOrder: false,
        })),
        documents: [form.poFile, ...form.shipDocuments.filter(f => !f.size)],
      }

      console.log(updateDto)

      const res = await getApi()?.shipments.put(`/shipment/orchestrate/${id}`, updateDto)
      return res?.data
    },
  })
}

export const useUpdateShipmentMutator = (shipmentId: string | null | undefined) => {
  return useMutation({
    mutationFn: async (data: { status: ShipmentStatus }) => {
      console.log({
        ...data,
        id: shipmentId,
      })

      const res = await getApi()?.shipments.put(`/shipment/${shipmentId}`, {
        ...data,
        id: shipmentId,
      })
      return res?.data
    },
  })
}

export const useUpdateLegMutator = (legId: string | null | undefined) => {
  return useMutation({
    mutationFn: async (data: { bolRef: string }) => {
      const res = await getApi()?.shipments.put(`/leg/${legId}`, data)
      return res?.data
    },
  })
}
