import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import { Skeleton } from 'src/components/Skeleton'
import Subheader from 'src/components/Subheader'
import { useCurrentUser, useUpdateTempPreferencesMutator } from 'src/features/Users'

// TODO - Company billing address needs to be fetched and displayed

// TODO - Page load needs to be occur AFTER data is fetched

const ProfilePageDesktop: React.FC = () => {
  const queryClient = useQueryClient()
  const currentUser = useCurrentUser()

  const updateTempMetric = useUpdateTempPreferencesMutator(currentUser.data?.id)

  const handleUpdateTempMetric = (value: string) => {
    updateTempMetric.mutate(value, {
      onSuccess: () => {
        queryClient.invalidateQueries(['/user'])
        toast.success('Preference saved')
      },
    })
  }

  return (
    <div className='flex-1' data-testid='profile-page-desktop'>
      <Subheader title={'Profile'} />
      <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
        <div className='container py-4 flex flex-col gap-4'>
          <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
            <h1 className='font-semibold text-lg'>User Information</h1>
            <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
              <div>
                <label className='text-[14px] font-medium'>Name</label>
                {currentUser?.isLoading ? (
                  <Skeleton className='h-[24px] w-[250px]' />
                ) : (
                  <p>{currentUser?.data?.fullName}</p>
                )}
              </div>
              <div>
                <label className='text-[14px] font-medium'>Email</label>
                {currentUser?.isLoading ? (
                  <Skeleton className='h-[24px] w-[250px]' />
                ) : (
                  <p>{currentUser?.data?.email}</p>
                )}
              </div>
            </div>
          </div>

          <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
            <h1 className='font-semibold tex3t-lg'>Units & Measurements</h1>
            <div>
              <h2 className='text-[14px] font-medium mb-2'>Temperature</h2>
              <div className='flex gap-2'>
                {currentUser?.isLoading ? (
                  <Skeleton className='h-[36px] w-[250px]' />
                ) : (
                  <>
                    <Button
                      variant={
                        currentUser.data?.tempMetric == 'C' ? 'default' : 'outline'
                      }
                      onClick={() => handleUpdateTempMetric('C')}
                    >
                      Degrees Celsius °C
                    </Button>
                    <Button
                      variant={
                        currentUser.data?.tempMetric == 'F' ? 'default' : 'outline'
                      }
                      onClick={() => handleUpdateTempMetric('F')}
                    >
                      Degrees Fahrenheit °F
                    </Button>
                  </>
                )}
              </div>
            </div>
          </div>
          {/* 
          <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
            <h1 className='font-semibold tex3t-lg'>Billing</h1>

            <div>{printAddress({})}</div>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ProfilePageDesktop
