import React, { ReactNode } from 'react'

import { CaretDown } from '@phosphor-icons/react'
import clsx from 'clsx'
import { renderToString } from 'react-dom/server'

type MarkerColors = 'gray' | 'blue' | 'red' | 'label'

export interface HTMLMapMarker extends google.maps.OverlayView {
  position: google.maps.LatLng
  point: google.maps.Point | null
  div: HTMLDivElement | null
  count?: number
  text?: string
  icon?: ReactNode
  color?: MarkerColors
  size?: number
  onClick: (e: Event, marker: HTMLMapMarker) => void
  onDblClick: (e: Event, marker: HTMLMapMarker) => void
  createDiv: () => void
  draw: () => void
  remove: () => void
  getPosition: () => google.maps.LatLng
  setPosition: (position: google.maps.LatLng) => void
  getDraggable: () => boolean
  getVisible: any
  setActive: () => void
  removeActive: () => void
  setColor: (color: MarkerColors) => void
}

export const createHTMLMapMarkerFactoy = (map, OverlayView = google.maps.OverlayView) => {
  return class HTMLMapMarker extends OverlayView {
    position: google.maps.LatLng
    point: google.maps.Point | null = null
    div: HTMLDivElement | null = null

    onClick: (e: Event, marker: HTMLMapMarker) => void
    onDblClick: (e: Event, marker: HTMLMapMarker) => void

    size?: number
    count?: number
    text?: string
    color?: MarkerColors = 'gray'
    icon?: ReactNode
    line?: any

    constructor(args) {
      super()
      this.position = args?.position

      this.count = args?.count
      this.color = args?.color
      this.text = args?.text
      this.size = args?.size
      this.icon = args?.icon
      this.onClick = args?.onMarkerClick
      this.onDblClick = args?.onDblClick
      this.line = args?.line
      this.setMap(map)
    }

    createDiv() {
      if (this.position.equals(new google.maps.LatLng(0, 0))) return
      this.div = this.div ?? document.createElement('div')
      this.div.style.position = 'absolute'
      this.div.innerHTML = createMarkerHTML({ ...this })

      const panes = this.getPanes()

      if (panes) {
        panes.overlayMouseTarget.appendChild(this.div)
        google.maps.OverlayView.preventMapHitsFrom(this.div)

        if (this.onClick) {
          this.div.addEventListener('click', e => {
            this.onClick(e, this)
            google.maps.event.trigger(this, 'click', e)
          })
        }

        if (this.onDblClick) {
          this.div.addEventListener('dblclick', e => {
            this.onDblClick(e, this)
          })
        }
      }
    }

    draw() {
      if (!this.div) this.createDiv()
      if (this.getProjection() == undefined) return
      this.point = this.getProjection().fromLatLngToDivPixel(this.position)

      if (this.point && this.div && this.getProjection()) {
        this.div.style.left = this.point.x + 'px'
        this.div.style.top = this.point.y + 'px'
        this.div.style.position = 'absolute'
      }
      if (this.position) {
        const path = [this.position, this.position]
        this.line = new google.maps.Polyline({
          path,
          strokeColor: 'blue',
          strokeOpacity: 0.8,
          strokeWeight: 2,
          icons: [
            {
              icon: {
                path: google.maps.SymbolPath.CIRCLE,
                scale: 8,
                strokeColor: 'white',
                fillOpacity: 1,
                fillColor: 'blue',
              },
              offset: '0',
              repeat: '10px',
            },
          ],
        })
        this.line.setMap(map)
      }
    }
    remove() {
      if (this.div) {
        this.div.parentNode?.removeChild(this.div)
        this.div = null
      }
      if (this.line) {
        this.line.setMap(null)
        this.line = null
      }
    }

    getPosition() {
      return this.position
    }

    setPosition(position: google.maps.LatLng) {
      this.position = position
      this.draw()
    }

    getDraggable() {
      return false
    }

    getVisible() {
      return this.getVisible
    }

    setColor(color: MarkerColors) {
      this.color = color

      const div = this.div?.children?.[0]
      switch (color) {
        case 'gray':
          div?.classList.remove(...mergeClasses(blueMarkerScheme, redMarkerScheme))
          div?.classList.add(...grayMarkerScheme.split(' '))
          break
        case 'blue':
          div?.classList.remove(...mergeClasses(grayMarkerScheme, redMarkerScheme))
          div?.classList.add(...blueMarkerScheme.split(' '))
          break
        case 'red':
          div?.classList.remove(...mergeClasses(grayMarkerScheme, blueMarkerScheme))
          div?.classList.add(...redMarkerScheme.split(' '))
          break
        default:
          break
      }
    }

    setActive() {
      const div = this.div?.children?.[0]
      const classes = [...withRing.split(' ')] as string[]
      div?.classList.add(...classes)
    }

    removeActive() {
      const div = this.div?.children?.[0]
      const classes = [...withRing.split(' ')] as string[]
      div?.classList.remove(...classes)
    }
  }
}

const withRing = 'ring-4'

const grayMarkerScheme = 'bg-zinc-600 child:bg-zinc-800'
const blueMarkerScheme = 'bg-blue-600 child:bg-blue-800 ring-blue-500/50'
const redMarkerScheme = 'bg-red-600 child:bg-red-800 ring-amber-500/50'
const labelMarkerScheme =
  'bg-zinc-900 text-white w-[120px] p-2 rounded-md font-medium drop-shadow flex items-center justify-center '

const defaultClasses =
  'rounded-full border-4 cursor-pointer shadow-lg transform transition duration-200 flex items-center justify-center hover:opacity-80 animate-fadeIn text-white font-bold border-slate-100 ring-offset-2 select-none fade-in'

const mergeClasses = (...classes: string[]) =>
  classes.flatMap(c => c.split(' ')) as unknown as string

const createMarkerHTML = ({
  size = 36,
  color = 'gray',
  count,
  text,
  icon,
}: {
  size?: number
  color?: MarkerColors
  count?: number | undefined
  text?: string
  icon?: ReactNode
}) => {
  if (color == 'label')
    return renderToString(
      <>
        <div
          style={{ fontSize: 14, marginTop: -50, marginLeft: -60 }}
          className={labelMarkerScheme}
        >
          {text} {icon}
        </div>
        <div
          className='flex items-center justify-center'
          style={{ fontSize: 14, marginTop: -8, marginLeft: -60 }}
        >
          <CaretDown size={20} color='#0F172A' weight='fill' />
        </div>
      </>
    )

  const html = (
    <div
      className={clsx(
        color === 'gray' && grayMarkerScheme,
        color === 'blue' && blueMarkerScheme,
        color === 'red' && redMarkerScheme,
        defaultClasses
      )}
      style={{
        height: size,
        width: size,
        fontSize: 14,
        marginTop: -(size / 2),
        marginLeft: -(size / 2),
      }}
    >
      {count ? (
        <>{count}</>
      ) : (
        <div
          className={clsx('rounded-full')}
          style={{ height: size / 3, width: size / 3 }}
        />
      )}
    </div>
  )

  return renderToString(html)
}
