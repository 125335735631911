import React from 'react'

import { Loader } from '@googlemaps/js-api-loader'
import Cookies from 'js-cookie'
import { Navigate, Outlet, createBrowserRouter, redirect } from 'react-router-dom'

import { AddressesPage, CreateAddressPage, SingleAddressPage } from './features/Addresses'
import ErrorPage from './features/App/views/ErrorPage'
import KitchenSinkPage from './features/App/views/KitchenSinkPage'
import Layout from './features/App/views/Layout'
import NotFoundPage from './features/App/views/NotFoundPage'
import {
  EmailConfirmedPage,
  LoginPage,
  NewPasswordPage,
  ResetPasswordPage,
  SendConfirmationPage,
} from './features/Auth'
import SingleFlarePageDesktop from './features/Flares/views/SingleFlarePage/SingleFlarePage.desktop'
import { ShipmentsListPage } from './features/POShipment'
import { ProfilePage } from './features/Profile'
import {
  CreateSensorProfilePage,
  SensorProfilesPage,
  SingleSensorProfilePage,
} from './features/SensorProfiles'
import { SettingsPage } from './features/Settings'
import { ShipmentFormPage, ViewShipmentPage } from './features/Shipments'
import { UsersPage } from './features/Users'
import AddUserPage from './features/Users/views/AddUserPage'
import { useServices } from './services'

async function protectedLoader() {
  if (!Cookies.get('flare-session')) {
    return redirect('/login')
  }

  return null
}

async function loginLoader() {
  if (Cookies.get('flare-session')) {
    return redirect('/')
  }

  return null
}

async function rootLoader() {
  await new Loader({
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  }).importLibrary('places')

  await useServices.getState().initServices()

  return null
}

const router = createBrowserRouter([
  {
    id: 'root',
    loader: rootLoader,
    Component: Outlet,
    errorElement: <ErrorPage />,
    children: [
      {
        loader: protectedLoader,
        Component: Layout,
        errorElement: <ErrorPage />,
        children: [
          {
            path: '/',
            index: true,
            element: <Navigate to='/shipments' replace />,
          },
          {
            path: 'profile',
            Component: ProfilePage,
          },
          {
            path: 'settings',
            Component: SettingsPage,
          },
          { path: 'flare/:id', Component: SingleFlarePageDesktop },
          {
            path: 'sensor-profiles',
            Component: SensorProfilesPage,
          },
          {
            path: 'sensor-profiles/:id',
            Component: SingleSensorProfilePage,
          },
          {
            path: 'sensor-profiles/new',
            Component: CreateSensorProfilePage,
          },
          {
            path: 'addresses',
            Component: AddressesPage,
          },
          {
            path: 'addresses/new',
            Component: CreateAddressPage,
          },
          {
            path: 'addresses/:id',
            Component: SingleAddressPage,
          },
          {
            path: 'users',
            Component: UsersPage,
          },
          {
            path: 'users/new',
            Component: AddUserPage,
          },
          {
            path: 'shipments',
            Component: ShipmentsListPage,
          },
          {
            path: 'shipments/new',
            Component: ShipmentFormPage,
          },
          {
            path: 'shipments/:id/edit',
            Component: ShipmentFormPage,
          },
          {
            path: 'shipments/:id',
            Component: ViewShipmentPage,
          },
          {
            path: 'kitchen-sink',
            Component: KitchenSinkPage,
          },
          {
            path: '*',
            Component: NotFoundPage,
          },
        ],
      },
      {
        loader: loginLoader,
        path: 'login',
        Component: LoginPage,
      },
      {
        path: 'reset-password',
        Component: ResetPasswordPage,
      },
      {
        path: 'new-password',
        Component: NewPasswordPage,
      },
      {
        path: 'confirm-email',
        Component: EmailConfirmedPage,
      },
      {
        path: 'send-confirmation',
        Component: SendConfirmationPage,
      },
      {
        loader: loginLoader,
        path: '*',
      },
    ],
  },
])

export default router
