import { CreateSensorProfileDTO, SensorProfileDTO } from 'src/types/flare-alert-dev'

import { CreateSensorProfileSchema } from './types'

export const convertSensorProfileFormToDTO = (
  form: CreateSensorProfileSchema
): CreateSensorProfileDTO => {
  return {
    name: form.name,
    temperatureCheck: form.temperatureCheck,
    temperatureMinC: form.temperatureValue[0],
    temperatureMaxC: form.temperatureValue[1],
    humidityCheck: form.humidityCheck,
    humidityMinRh: form.humidityValue[0],
    humidityMaxRh: form.humidityValue[1],
  }
}

export const convertSensorProfileDTOToForm = (dto: SensorProfileDTO) => {
  return {
    name: dto.name,
    temperatureCheck: dto.temperatureCheck,
    temperatureValue: [dto.temperatureMinC, dto.temperatureMaxC],
    humidityCheck: dto.humidityCheck,
    humidityValue: [dto.humidityMinRh, dto.humidityMaxRh],
  }
}
