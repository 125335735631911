import { useMemo } from 'react'

import { makeOptions } from './utils'

export const useMemoisedOptions = (
  options: any[] | null | undefined = [],
  labelKey = 'name',
  valueKey = 'id'
) => {
  return useMemo(() => {
    return makeOptions(options ?? [], labelKey, valueKey) ?? []
  }, [options])
}
