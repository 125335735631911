import { ReactHTML } from 'react'

import { create } from 'zustand'

import { defaultMapOptions } from 'src/helpers/gmap'
import { createHTMLMapMarkerFactoy } from 'src/helpers/markerFactory'

interface usePOShipmentStore {
  file: File | undefined
  steps: string[]
  currentStep: number
  nextStep: () => void
  prevStep: () => void
  setStep: (step: number) => void
}

export const usePOShipmentStore = create<any>()((set, get) => ({
  devices: [],
  cargo: [],
  recipients: [],
  files: [],
  reset: () => {
    set({ devices: [], cargo: [], recipients: [], files: [] })
  },
}))

export const { nextStep, prevStep, setStep } = usePOShipmentStore.getState()

export const usePOShipmentMap = create<any>()((set, get) => ({
  map: undefined,
  markerFactory: undefined,
  markers: [],
  lines: [],
  initialiseMap: (mapRef: React.RefObject<HTMLDivElement>) => {
    if (!mapRef?.current || !window.google) return
    const map = new google.maps.Map(mapRef.current, defaultMapOptions)
    const markerFactory = createHTMLMapMarkerFactoy(map)
    set({ map, markerFactory })
  },
  addMarker: (position: google.maps.LatLng, text: string, icon: ReactHTML) => {
    const markerFactory = get().markerFactory
    const marker = new markerFactory({ position, color: 'label', text, icon: icon })
    set({ markers: [...get().markers, marker] })
  },
  addLine: () => {
    const markers = get().markers
    if (markers.length < 2) return
    const icon = { path: 'M 0,-1 0,1', strokeOpacity: 1, scale: 3 }
    const line = new google.maps.Polyline({
      path: markers.map(marker => marker.getPosition()),
      geodesic: true,
      strokeColor: '#2563EB',
      strokeOpacity: 0,
      icons: [{ icon, offset: '0', repeat: '15px' }],
    })
    line.setMap(get().map)
    set({ lines: [...get().lines, line] })
  },
  centerMap: () => {
    const markers = get().markers
    if (markers.length === 0) return
    const bounds = new google.maps.LatLngBounds()
    markers.forEach(marker => {
      bounds.extend(marker.getPosition())
    })
    get().map?.fitBounds(bounds)
  },
}))
