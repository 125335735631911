import React, { memo } from 'react'

import { Plus, Trash } from '@phosphor-icons/react'
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Link, useNavigate } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Searchbar from 'src/components/Searchbar'
import Subheader from 'src/components/Subheader'
import Table, { PaginationControls, usePagination } from 'src/components/Table'
import { Modal, openModal } from 'src/features/App/api/stores'
import { SensorProfileDTO } from 'src/types/flare-alert-dev'

import { useSensorProfiles } from '../../api/queries'

import { sensorProfileTableColumns } from './components'

const SensorProfilesPageDesktop: React.FC = memo(() => {
  const navigate = useNavigate()
  const { pagination, setPagination } = usePagination()
  const [search, setSearch] = React.useState('')

  const profiles = useSensorProfiles({
    search,
    page: pagination.pageIndex + 1,
    pageSize: 15,
  })

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable<SensorProfileDTO>({
    data: (profiles?.data?.items as SensorProfileDTO[]) || [],
    pageCount: profiles?.data?.paging?.pageCount,
    columns: sensorProfileTableColumns(navigate),
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    state: { sorting, rowSelection, pagination },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
  })
  const selectedIds =
    profiles?.data?.items &&
    Object.keys(rowSelection)
      .map(index => parseInt(index))
      .map(index => profiles?.data?.items && profiles?.data?.items[index]?.id)

  const selectedCount = Object.keys(rowSelection).length
  const deleteButtonText = `${selectedCount} ${selectedCount > 1 ? 'Selected' : 'Select'}`

  return (
    <div className='flex-1'>
      <Subheader
        title={'Sensor Profiles'}
        actions={
          <div className='flex items-center gap-2'>
            <Link to='/sensor-profiles/new'>
              <Button>
                <Plus className='mr-2' color='#fff' />
                {/* <i className='fas fa-plus mr-2' /> */}
                New Sensor Profile
              </Button>
            </Link>
          </div>
        }
      />

      <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
        <div className='container py-4 flex flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <Searchbar onSearch={setSearch} placeholder={'Search...'} value={search} />
              {selectedCount > 0 && (
                <div className='flex items-center gap-2'>
                  <p className={'font-medium text-base text-red'}>{deleteButtonText}</p>
                  <Button
                    variant={'outline'}
                    onClick={() =>
                      openModal(Modal.DELETE_SENSOR_PROFILE, { ids: selectedIds }, () => {
                        setRowSelection({})
                        console.log('Callback function here')
                      })
                    }
                  >
                    {/* <i className={'fas fa-trash mr-2 text-red-600'} /> */}
                    <Trash className='mr-2' color='#dc2626' />
                    {`Delete ${selectedIds?.length == 1 ? 'Profile' : 'Profiles'}`}
                  </Button>
                </div>
              )}
            </div>
            <PaginationControls table={table} />
          </div>
          <Table table={table} isLoading={profiles.isFetching} minHeight={15 * 60} />
          <div className='flex items-center justify-end'>
            <PaginationControls table={table} />
          </div>
        </div>
      </div>
    </div>
  )
})

export default SensorProfilesPageDesktop
