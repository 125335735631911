import { useMutation, useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'
import { PatchOp } from 'src/types'

import { adaptPoShipmentFormToDto } from './helpers'
import { POShipmentDTO, POShipmentForm } from './types'

export const useCreatePOShipmentMutator = () => {
  return useMutation({
    mutationFn: async (data: POShipmentForm) => {
      const request = adaptPoShipmentFormToDto(data)

      const res = await getApi()?.shipments.post('/shipment', request, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return res?.data
    },
  })
}

export const useUpdatePoShipmentMutator = (id: string) => {
  return useMutation({
    mutationFn: async (data: POShipmentForm) => {
      const request = adaptPoShipmentFormToDto(data)

      const res = await getApi()?.shipments.put(`/shipment/${id}`, request, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })

      return res?.data
    },
  })
}

export const useShipments = (query = {}) => {
  return useQuery<any>({
    queryKey: ['/shipments', query],
    queryFn: async () => {
      const res = await getApi()?.shipments.get('/shipment', { params: query })

      return res?.data
    },
  })
}

export const useShipment = (id: string) => {
  return useQuery<POShipmentDTO>({
    queryKey: ['/shipments', id],
    queryFn: async () => {
      const res = await getApi()?.shipments.get(`/shipment/${id}`)
      return res?.data
    },
    suspense: true,
  })
}

export const useUpdateBOLMutator = (id: string | undefined | null) => {
  return useMutation({
    mutationFn: async (bolRef: string) => {
      if (!id) throw new Error('id is required')
      const res = await getApi()?.shipments.put(`/leg/${id}`, `\"${bolRef}\"`, {
        headers: { 'Content-Type': 'application/json' },
      })
      return res?.data
    },
  })
}

export const usePatchShipmentMutator = (id: string | undefined | null) => {
  return useMutation({
    mutationFn: async (ops: PatchOp<any>[]) => {
      if (!id) throw new Error('id is required')
      const res = await getApi()?.shipments.patch(`/shipment/${id}`, ops)
    },
  })
}

export const useDeleteDocumentMutator = (id: string) => {
  return useMutation({
    mutationFn: async () => {
      const res = await getApi()?.shipments.delete(`/shipment/document/${id}`)
      return res?.data && { success: true, message: 'Document is deleted successfully.' }
    },
  })
}
