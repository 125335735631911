import React from 'react'

import { IconContext } from '@phosphor-icons/react'
import { TooltipProvider } from '@radix-ui/react-tooltip'
import { RouterProvider } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'
import { Toaster } from 'sonner'

import router from './router'

export default function App() {
  return (
    <>
      <IconContext.Provider
        value={{
          color: '#94a3b8', // slate-400
          size: 18,
          weight: 'bold',
        }}
      >
        <TooltipProvider>
          <RouterProvider
            router={router}
            fallbackElement={
              <div className='flex items-center justify-center h-screen w-screen'>
                <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
              </div>
            }
          />
          <Toaster position='bottom-center' richColors closeButton />
        </TooltipProvider>
      </IconContext.Provider>
    </>
  )
}
