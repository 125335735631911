import React, { useCallback, useEffect, useState } from 'react'

import { MagnifyingGlass } from '@phosphor-icons/react'
import clsx from 'clsx'
import { debounce } from 'radash'

import { Input } from './Input'

interface SearchbarProps extends React.InputHTMLAttributes<HTMLInputElement> {
  debounceDelay?: number
  onSearch: (search: string) => void
}

const Searchbar = React.forwardRef<HTMLInputElement, SearchbarProps>((props, ref) => {
  const { onSearch, value, debounceDelay = 400, ...rest } = props

  const [input, setInput] = useState<string>(toString(value))

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(debounce({ delay: debounceDelay }, onSearch), [
    onSearch,
    debounceDelay,
  ])

  useEffect(() => {
    if (input !== toString(value)) {
      debouncedSearch(input)
    }
  }, [debouncedSearch, input, value])

  return (
    <div className='relative'>
      <Input
        ref={ref}
        {...rest}
        value={input}
        onChange={e => setInput(e.target.value)}
        className={clsx(rest.className, 'pl-8 pr-8')}
      />
      <div className='flex items-center justify-center absolute left-0 top-0 aspect-square h-full'>
        <MagnifyingGlass weight='bold' />
      </div>

      {input && (
        <i
          onClick={() => setInput('')}
          className='absolute h-full flex items-center justify-center right-0 top-0 fas fa-times text-slate-400 aspect-square cursor-pointer hover:text-black'
        />
      )}
    </div>
  )
})

export default Searchbar

const isFalsy = (value: any) => {
  return value === null || value === undefined || value === ''
}

const toString = (value: any) => {
  return isFalsy(value) ? '' : value.toString()
}
