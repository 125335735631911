import React, { Suspense, useEffect, useRef, useState } from 'react'

import {
  CalendarDots,
  CaretRight,
  CheckCircle,
  Circle,
  Clock,
  CreditCard,
  Drop,
  Envelope,
  Files,
  Flag,
  List,
  Pencil,
  Pulse,
  ShieldCheck,
  Thermometer,
  UploadSimple,
  Users,
  WarningDiamond,
} from '@phosphor-icons/react'
import { createColumnHelper, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { User } from 'lucide-react'
import { Link, useParams } from 'react-router-dom'

import Block from 'src/components/Block'
import { Button } from 'src/components/Button'
import { DocumentFileDisplay } from 'src/components/FileUploader'
import { Label } from 'src/components/Label'
import Subheader from 'src/components/Subheader'
import Table, { PaginationControls } from 'src/components/Table'
import { useCurrentOrg } from 'src/features/Settings/api/queries'
import { useLatestTelemetry } from 'src/features/Telemetry/queries'
import { useCurrentUser } from 'src/features/Users'
import { convertLocationToLatLng, defaultMapOptions } from 'src/helpers/gmap'
import { getModeIcon, getModeName, getTimeAgo, parseDate } from 'src/helpers/utils'
import { DeviceDTO, RecipientDTO, ShipmentDTO } from 'src/types'

import { ShipmentStatus, shippingTermOptions, useShipment } from '../../api'
import { useShipmentMap } from '../../api/stores'
import { MarkShipmentCompleteModal } from '../MarkShipmentCompleteModal'
import { UpdateBOLModal } from '../UpdateBolModal'
import {
  LoadingIndicator,
  ShipmentJourneyDetails,
  ShipmentPill,
  StatusPill,
} from '../shared'

const ViewShipmentPage = () => {
  const { id } = useParams() as { id: string }
  const shipmentQuery = useShipment(id)
  const shipment = shipmentQuery.data

  useEffect(() => {
    return () => {
      useShipmentMap.getState().clearMap()
    }
  }, [])

  return (
    <div className='flex-1'>
      <Subheader
        title={shipment?.name}
        returnTo='/shipments'
        actions={
          <div className='flex gap-2 items-center'>
            <Button variant={'outline'}>
              <WarningDiamond className='mr-2' color='#DC2626' weight='fill' />
              Make a Claim
            </Button>
            {shipment?.status == ShipmentStatus.Active && (
              <MarkShipmentCompleteModal shipment={shipment} />
            )}

            <Link to={`/shipments/${id}/edit`}>
              <Button variant={'outline'}>
                <Pencil className='mr-2' />
                Edit Shipment
              </Button>
            </Link>
          </div>
        }
      />

      <Suspense fallback={<LoadingIndicator />}>
        <div className='overflow-auto bg-slate-50 h-[calc(100vh-140.8px)]'>
          <div className='max-w-[1336px] mx-auto p-4 flex flex-col gap-4'>
            <div className='flex flex-col gap-4'>
              <ShipmenteDetailsBlock shipment={shipment} />
              <div className='grid gap-4' style={{ gridTemplateColumns: 'auto 320px' }}>
                <ShipmentMapBlock shipment={shipment} />
                <ShipmentProgressBlock shipment={shipment} />
              </div>

              <ShipmentDevicesBlock shipment={shipment} />
              <ShipmentCargoBox shipment={shipment} />
              <ShipmentCollaboratorBox shipment={shipment} />
              <ShipmentDocumentsBox shipment={shipment} />
            </div>
          </div>
        </div>
      </Suspense>
    </div>
  )
}

export default ViewShipmentPage

export const ShipmenteDetailsBlock = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  const leg = shipment?.legs?.[0]
  const container = shipment?.containers?.[0]
  const ETA = parseDate(shipment?.shippingDate)

  return (
    <Block className='flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-4'>
          <StatusPill status={shipment?.status} />

          {shipment?.isFinanced && (
            <ShipmentPill>
              <CreditCard color='#16a34a' />
              Financed
            </ShipmentPill>
          )}

          {shipment?.isInsured && (
            <ShipmentPill>
              <ShieldCheck color='#16a34a' />
              Insured
            </ShipmentPill>
          )}

          {ETA && (
            <div className='flex items-center gap-2'>
              <CalendarDots />
              <span>Ship Date:</span>
              <span>{ETA}</span>
            </div>
          )}

          {container && container?.devices && (
            <div className='items-center gap-2 flex'>
              <Pulse />
              <div> {`${container?.devices?.length} Device`}</div>
            </div>
          )}
        </div>

        {leg && <UpdateBOLModal shipment={shipment} />}
      </div>
    </Block>
  )
}

export const ShipmentMapBlock = ({ shipment }: { shipment: ShipmentDTO | undefined }) => {
  const leg = shipment?.legs?.[0]

  return (
    <div className='border border-slate-300 rounded-lg overflow-hidden drop-shadow'>
      <div className='p-4 flex items-center justify-between bg-white border-b border-slate-300'>
        <ShipmentJourneyDetails shipment={shipment} />
        <div className='flex items-center gap-2'>
          <i className={`ph-light ${getModeIcon(leg?.transportMode)} text-blue-600`} />
          {getModeName(leg?.transportMode)} Shipment
        </div>
      </div>
      <ShipmentMap shipment={shipment} />
    </div>
  )
}

export const ShipmentMap = ({ shipment }: { shipment: ShipmentDTO | undefined }) => {
  const leg = shipment?.legs?.[0]
  const [map, setMap] = useState<google.maps.Map | null>()
  const mapRef = useRef(null)
  const { initialiseMap, addMarker, addLine, centerMap } = useShipmentMap.getState()

  useEffect(() => {
    if (mapRef.current) {
      setMap(new google.maps.Map(mapRef.current, defaultMapOptions))
    }
  }, [mapRef])
  const originIcon = (
    <UploadSimple size={16} color='#94A3B8' className='ml-2' weight='bold' />
  )
  const destinationIcon = (
    <Flag size={16} color='#CBD5E1' className='ml-2' weight='bold' />
  )
  useEffect(() => {
    const addMapFeatures = async () => {
      try {
        if (map && leg) {
          initialiseMap(mapRef)
          if (leg?.origin?.location && leg?.destination?.location) {
            addMarker(convertLocationToLatLng(leg?.origin.location), 'Origin', originIcon)
            addMarker(
              convertLocationToLatLng(leg?.destination.location),
              'Destination',
              destinationIcon
            )
            addLine()
            centerMap()
          }
        }
      } catch (error) {
        console.error('Error occurred while adding map features:', error)
      }
    }

    addMapFeatures()
  }, [map, leg])

  return (
    <div className='bg-white overflow-hidden drop-shadow border border-slate-300 h-[350px]'>
      <div ref={mapRef} className='w-full h-full'></div>
    </div>
  )
}

const ProgressStep = ({ completed, text }) => {
  return (
    <div className='flex items-center gap-2'>
      {completed ? (
        <CheckCircle size={20} color='#16A34A' weight='fill' />
      ) : (
        <Circle size={20} color='#94A3B8' />
      )}
      <div>{text}</div>
    </div>
  )
}

const getProgressSteps = (shipment: ShipmentDTO | undefined) => [
  {
    text: 'Purchase order uploaded',
    condition: true,
  },
  {
    text: 'Purchase Order Data Verified',
    condition: true,
  },
  {
    text: 'Finance Approved',
    condition: shipment?.isFinanced,
  },
  {
    text: 'Insurance Approved',
    condition: shipment?.isInsured,
  },
  {
    text: 'Device Assigned',
    condition: (shipment?.containers?.[0]?.devices?.length ?? 0) > 0,
  },
  {
    text: 'BOL Uploaded',
    condition: shipment?.legs?.[0]?.bolRef?.trim() !== '',
  },
  {
    text: 'Shipment Completion',
    condition: shipment?.status === ShipmentStatus.Completed,
  },
]

export const ShipmentProgressBlock = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  const currentOrg = useCurrentOrg()

  const isFinanceApproved = currentOrg.data?.isFinanceApproved
  const isInsuranceApproved = currentOrg.data?.isInsuranceApproved

  return (
    <Block className='flex flex-col'>
      <div className='flex items-center gap-4 font-semibold mb-4'>
        <List />
        <span>Shipment Progress</span>
      </div>
      <div className='flex flex-col justify-between grow'>
        {getProgressSteps(shipment).map((step, index) =>
          step.text === 'Finance Approved' && !isFinanceApproved ? null : step.text ===
              'Insurance Approved' && !isInsuranceApproved ? null : (
            <React.Fragment key={index}>
              <ProgressStep completed={step.condition} text={step.text} />
              {index < getProgressSteps(shipment).length - 1 && (
                <div className={'w-[2px] ml-2 my-1 bg-slate-200 flex-grow'}></div>
              )}
            </React.Fragment>
          )
        )}
      </div>
    </Block>
  )
}

export const ShipmentDevicesBlock = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  const container = shipment?.containers?.[0]
  const sensorProfile = container?.sensorProfile
  const table = useReactTable<DeviceDTO>({
    data: (container?.devices as DeviceDTO[]) ?? [],
    columns: listFlaresInContainerTable(shipment) as any,
    getCoreRowModel: getCoreRowModel(),
  })
  const tempMetric = useCurrentUser()?.data?.tempMetric

  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Pulse}>Devices</Block.Title>
      <h3 className='font-semibold'>Device Sensor Profile</h3>
      <div className='flex items-center justify-start gap-4 font-normal'>
        <div>{sensorProfile?.name}</div>
        <div className='flex items-center gap-2'>
          <div className='flex items-center text-sm bg-blue-50 rounded px-2 py-1 w-fit'>
            <Thermometer className='mr-2' color='#2563eb' size={12} />
            {`${sensorProfile?.temperatureMinC}°${tempMetric} - ${sensorProfile?.temperatureMaxC}°${tempMetric}`}
          </div>
          <div className='flex items-center text-sm bg-blue-50 rounded px-2 py-1 w-fit'>
            <Drop className='mr-2' color='#2563eb' size={12} />
            {`${sensorProfile?.humidityMinRh}% - ${sensorProfile?.humidityMaxRh}%`}
          </div>
        </div>
      </div>
      <Table table={table} minHeight={15 * 60} />
    </Block>
  )
}

const columnHelper = createColumnHelper<any>()

export const listFlaresInContainerTable = (shipment: ShipmentDTO | undefined) => [
  columnHelper.accessor('name', {
    header: 'Device Name',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('', {
    header: 'Latest Report',
    cell: info => {
      const device = info.row.original
      const sensor = useLatestTelemetry(device.id as string, 2)
      const tempMetric = useCurrentUser()?.data?.tempMetric

      return (
        <div className='flex items-center gap-2'>
          <div className='flex items-center text-sm bg-blue-50 rounded px-2 py-1 w-fit'>
            <Drop className='mr-2' color='#2563eb' size={12} />
            {sensor.data?.humidity ? `${sensor.data.humidity}%` : 'NA'}
          </div>

          <div className='flex items-center text-sm bg-blue-50 rounded px-2 py-1 w-fit'>
            <Thermometer className='mr-2' color='#2563eb' size={12} />
            {sensor.data?.temperature ? `${sensor.data.temperature}°${tempMetric}` : 'NA'}
          </div>

          {sensor.data?.timeStamp && (
            <div className='flex items-center ml-auto text-xs text-slate-500'>
              <Clock className='mr-2' size={12} />
              {getTimeAgo(sensor.data.timeStamp)}
            </div>
          )}
        </div>
      )
    },
  }),
  columnHelper.accessor('', {
    id: 'actions',
    size: 50,
    cell: info => {
      const device = info.row.original
      return (
        <div className='flex items-center justify-end'>
          <Link to={`/flare/${device.id}`} state={shipment}>
            <Button variant={'outline'} className='ml-2'>
              View Device
              <CaretRight size={16} color='#64748B' className='ml-2' />
            </Button>
          </Link>
        </div>
      )
    },
  }),
]

const columns = [
  columnHelper.accessor('description', {
    header: 'Description',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('product_code', {
    header: 'Product Code',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('quantity', {
    header: 'Quantity',
    cell: info => <div className='flex items-center justify-end'>{info.getValue()}</div>,
  }),
]

const EMPTY_ARRAY = []

export const ShipmentCargoBox = ({ shipment }: { shipment: ShipmentDTO | undefined }) => {
  const shippingTerm = shipment?.shippingTerm?.toString()
  const lineItemsJson = JSON.parse(shipment?.purchaseOrder?.line_items ?? '[]')

  const table = useReactTable({
    data: lineItemsJson ?? EMPTY_ARRAY,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <Block>
      {shippingTerm && (
        <div className='flex flex-col gap-1 mb-4'>
          <Label>Shipping Terms</Label>
          <div className='font-semibold'>{shippingTermOptions[shippingTerm]?.label}</div>
        </div>
      )}
      <div className='flex flex-col gap-2 mt-4 pt-4 border-t border-slate-300'>
        <div className='flex items-center justify-between'>
          <Label>Cargo Items</Label>
          <PaginationControls table={table} />
        </div>
        <Table table={table} />
      </div>
    </Block>
  )
}

export const ShipmentCollaboratorBox = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  const recipients = shipment?.recipients as RecipientDTO[]

  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Users}>Collaborators</Block.Title>
      <Label>Send notifications to:</Label>
      <div className='flex items-center gap-1 flex-wrap'>
        {recipients?.length == 0 ? (
          <div className='bg-slate-100 rounded px-4 py-2 w-full text-slate-600'>
            No recipients added
          </div>
        ) : (
          recipients?.map(user => (
            <div
              key={user.id}
              className='flex items-center justify-center border rounded border-slate-300 px-2 py-1 gap-2 text-sm font-normal'
            >
              {user.name === user.emailAddress ? (
                <Envelope color='#64748B' />
              ) : (
                <User color='#2563EB' size={18} />
              )}

              <span>{user?.name || user.emailAddress}</span>
            </div>
          ))
        )}
      </div>
    </Block>
  )
}

export const ShipmentDocumentsBox = ({
  shipment,
}: {
  shipment: ShipmentDTO | undefined
}) => {
  const files = shipment?.documents
  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Files}>Documents</Block.Title>
      {files && files?.length > 0 ? (
        <div className='grid grid-cols-3 gap-2 mb-4  border-slate-300 pb-4'>
          {files.map((file, i) => {
            return <DocumentFileDisplay document={file} key={i} />
          })}
        </div>
      ) : (
        <div className='flex items-center justify-center h-32 text-slate-400'>
          There are no Documents attached to this Shipment.
        </div>
      )}
    </Block>
  )
}
