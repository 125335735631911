import * as React from 'react'

import { MagnifyingGlass } from '@phosphor-icons/react'
import clsx from 'clsx'
import { ChevronsUpDown, Loader2, XIcon } from 'lucide-react'
import { Virtuoso } from 'react-virtuoso'

import { Button } from 'src/components/Button'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/Popover'

import { Input } from './Input'

const compare = (a, b) => {
  return a?.toLowerCase().includes(b?.toLowerCase())
}

export type Option = {
  value: string | null
  label: string
}

type ComboboxProps = {
  options: Option[]
  value: Option | null
  className?: string
  onSelect: (value: Option | null) => void
  onInputChange?: (value: string) => void
  render?: (option: Option) => React.ReactNode
  placeholder?: string
  isLoading?: boolean
  isError?: boolean
  isClearable?: boolean
}

export function Combobox(props: ComboboxProps) {
  const [search, setSearch] = React.useState('')
  const [open, setOpen] = React.useState(false)

  const triggerRef = React.useRef<HTMLButtonElement>(null)

  const selected = props.options.find(opt => opt.value === props.value?.value)

  const handleSelect = (option: Option | null) => {
    props.onSelect(option)
    setOpen(false)
  }

  const filteredOptions = props.options.filter(opt => compare(opt.label, search))

  return (
    <Popover open={open} onOpenChange={setOpen} modal={true}>
      <PopoverTrigger asChild>
        <Button
          ref={triggerRef}
          variant='outline'
          role='combobox'
          disabled={props.isLoading}
          aria-expanded={open}
          className={clsx(
            props.className,
            'justify-between font-normal',
            props.isLoading && 'bg-slate-50'
          )}
        >
          {selected && props.render && props.render(selected)}

          {selected && !props.render && selected.label}

          {!selected && (
            <span className='text-slate-500'>
              {props.placeholder ?? 'Select an option...'}
            </span>
          )}

          <div className='flex items-center gap-2'>
            {props.isLoading && (
              <Loader2 className='h-4 w-4 animate-spin text-slate-600' />
            )}

            {selected && props?.isClearable && (
              <XIcon
                className='h-4 w-4 shrink-0 ml-2 text-slate-400 hover:text-black'
                onClick={e => {
                  e.stopPropagation()
                  handleSelect(null)
                }}
              />
            )}

            <ChevronsUpDown className='ml-2 h-4 w-4 shrink-0 opacity-50' />
          </div>
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className='p-0'
        style={{ width: triggerRef?.current?.getBoundingClientRect().width }}
      >
        <div className='p-1 border-b border-slate-300 relative'>
          <div className='flex items-center justify-center absolute left-0 top-0 aspect-square h-full'>
            <MagnifyingGlass weight='bold' />
          </div>
          <Input
            onChange={e => setSearch(e.target.value)}
            placeholder='Search...'
            className='w-full border-none pl-9 focus-visible:ring-transparent focus-visible:outline-transparent'
          />
        </div>
        {filteredOptions.length === 0 ? (
          <div className='p-4 text-slate-500 text-[14px] text-center'>
            No results found
          </div>
        ) : (
          <Virtuoso
            className='w-full overflow-hidden'
            totalCount={filteredOptions.length}
            style={{ height: Math.min(filteredOptions.length * 40, 400) }}
            itemContent={index => {
              const option = filteredOptions[index]

              return (
                <div
                  onClick={() => handleSelect(option)}
                  className='p-2 text-[14px] hover:bg-slate-100 h-[40px]'
                >
                  {props.render ? props.render(option) : option.label}
                </div>
              )
            }}
          />
        )}
      </PopoverContent>
    </Popover>
  )
}
