import * as React from 'react'

import { Slot } from '@radix-ui/react-slot'
import { type VariantProps, cva } from 'class-variance-authority'

import { cn } from 'src/utils'

const buttonVariants = cva(
  'inline-flex items-center align-middle justify-center whitespace-nowrap rounded text-[14px] font-medium ring-offset-white transition-colors focus:ring-offset-1 focus:ring-2 focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none',
  {
    variants: {
      variant: {
        default: 'bg-blue-600 text-slate-50 hover:bg-blue-600/90 focus:ring-blue-600',
        destructive:
          'bg-red-500 text-slate-50 hover:bg-red-500/90 focus:ring--red-500 focus:ring-red-600 ',
        outline:
          'border border-slate-300 bg-white hover:bg-slate-100 hover:text-slate-900 focus:ring-slate-600',
        secondary: 'bg-slate-500 text-slate-50 hover:bg-zinc-700/80 focus:ring-zinc-700',
        ghost:
          'hover:bg-slate-100 hover:text-slate-900 dark:hover:bg-slate-800 dark:hover:text-slate-50',
        link: 'text-slate-900 underline-offset-4 hover:underline dark:text-slate-50',
        magic: 'bg-purple-600 text-white hover:bg-purple-500 focus:ring-purple-600',
      },
      size: {
        default: 'h-[36px] px-3',
        sm: 'h-9 rounded-md px-3',
        lg: 'h-11 rounded-md px-8',
        icon: 'h-[36px] w-[36px] p-0 font-[12px]',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : 'button'
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    )
  }
)

// @ts-ignore
Button.displayName = 'Button'

export { Button, buttonVariants }
