import React from 'react'

import { useRouteError } from 'react-router-dom'

const ErrorPage = () => {
  const [timestamp] = React.useState(new Date().toLocaleString())
  const error = useRouteError() as { statusText?: string; message?: string }

  return (
    <div className='flex h-full w-full itesm-center justify-center'>
      <div className='p-4'>
        <div className='p-4 border-2 border-slate-300 rounded-lg gap-4 text-slate-600 flex items-center gap-2'>
          <i
            className='fal fa-face-sad-sweat text-slate-400'
            style={{ fontSize: 60 }}
          ></i>
          <div className='flex flex-col'>
            <p>Something unexpected has happened. Please contact Suply.</p>
            <p>{timestamp}</p>
            <p className='font-bold'>Error: {error?.statusText || error?.message}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
