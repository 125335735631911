import React, { Ref, forwardRef } from 'react'

import {
  CaretDoubleLeft,
  CaretDoubleRight,
  CaretLeft,
  CaretRight,
} from '@phosphor-icons/react'
import { Table as ITable, PaginationState, flexRender } from '@tanstack/react-table'
import clsx from 'clsx'
import { SortAsc, SortDesc } from 'lucide-react'
import { PuffLoader } from 'react-spinners'

import { Button } from './Button'
import Checkbox from './Checkbox'

interface TableProps<T> {
  table: ITable<any>
  isLoading?: boolean
  minHeight?: number
  emptyMessage?: string
  scrollable?: boolean
  maxHeight?: number
}

const Table = forwardRef(
  <T,>(
    props: TableProps<T>,
    ref: Ref<HTMLTableElement> // Ref type for the table element
  ) => {
    const rows = props.table.getRowModel().rows
    const isEmpty = rows.length === 0

    return (
      <div className='border border-slate-300 rounded bg-white w-full'>
        <table className='w-full'>
          <TableHeader table={props.table} />

          {props.isLoading ? (
            <tbody>
              <tr>
                <td colSpan={props.table.getHeaderGroups()[0].headers.length}>
                  <div className='flex items-center justify-center h-32'>
                    <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
                  </div>
                </td>
              </tr>
            </tbody>
          ) : !props.isLoading && isEmpty ? (
            <tbody>
              <tr>
                <td colSpan={props.table.getHeaderGroups()[0].headers.length}>
                  <div className='flex items-center justify-center h-32 text-slate-400'>
                    {props.emptyMessage ?? 'Nothing to show here'}
                  </div>
                </td>
              </tr>
            </tbody>
          ) : (
            <TableBody table={props.table} />
          )}
        </table>
      </div>
    )
  }
)

export default Table

interface TableHeaderProps<T> {
  table: ITable<T>
  hidden?: boolean
}

function TableHeader<T>(props: TableHeaderProps<T>) {
  return (
    <thead>
      {props.table.getHeaderGroups().map(headerGroup => (
        <tr
          key={headerGroup.id}
          className={clsx('shadow', props.hidden && 'shadow-none')}
        >
          {headerGroup.headers.map((header, i) => {
            const isLastHeader = i === headerGroup.headers.length - 1
            return (
              <th
                style={{
                  width:
                    i === 0 && header.column.columnDef.id == 'select'
                      ? 40
                      : `${header.getSize()}px`,
                }}
                className={clsx(
                  'border-b border-slate-300 rounded-t bg-slate-50 text-[14px]',
                  props.hidden && 'opacity-0 h-0 border-none'
                )}
                key={header.id}
                colSpan={header.colSpan}
              >
                {header.isPlaceholder || props.hidden ? null : (
                  <div
                    {...{
                      className: clsx(
                        'p-2 cursor-pointer text-left font-semibold flex items-center',
                        header.column.getCanSort() && '',
                        isLastHeader && 'justify-end'
                      ),
                      onClick: header.column.getToggleSortingHandler(),
                    }}
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}

                    {header.column.getCanSort() ? (
                      <div className='text-sm'>
                        {{
                          asc: <SortAsc size={14} />,
                          desc: <SortDesc size={14} />,
                        }[header.column.getIsSorted() as string] ?? <></>}
                      </div>
                    ) : null}
                  </div>
                )}
              </th>
            )
          })}
        </tr>
      ))}
    </thead>
  )
}

interface TableBodyProps<T> {
  table: ITable<T>
}

function TableBody<T>(props: TableBodyProps<T>) {
  const rows = props.table.getRowModel().rows

  return (
    <tbody data-testid='table-body'>
      {rows.map((row, i) => {
        return (
          <tr
            key={row.id}
            // onClick={() => row.getCanSelect() && row.toggleSelected()}
            className={clsx(
              `border-slate-300`,
              i !== rows.length - 1 && 'border-b',
              row.getIsSelected() && 'bg-blue-50'
            )}
          >
            {row.getVisibleCells().map(cell => {
              return (
                <td key={cell.id} className='p-2'>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

interface PaginationControlsProps {
  table: ITable<any>
}

export function PaginationControls({ table }: PaginationControlsProps) {
  return (
    <div className='flex items-center gap-2'>
      <Button
        date-testid='first-page-btn'
        size={'icon'}
        disabled={!table.getCanPreviousPage()}
        variant='outline'
        onClick={() => table.setPageIndex(0)}
      >
        <CaretDoubleLeft />
      </Button>
      <Button
        data-testid='prev-page-btn'
        size={'icon'}
        disabled={!table.getCanPreviousPage()}
        variant='outline'
        onClick={() => table.previousPage()}
      >
        <CaretLeft />
      </Button>
      <div
        className={
          'h-[36px] bg-white border border-slate-300 rounded flex items-center justify-center px-2 text-[14px]'
        }
      >
        {`${table.getState().pagination.pageIndex + 1} of ${table.getPageCount()}`}
      </div>
      <Button
        data-testid='next-page-btn'
        size={'icon'}
        disabled={!table.getCanNextPage()}
        variant='outline'
        onClick={() => table.nextPage()}
      >
        <CaretRight />
      </Button>
      <Button
        data-testid='last-page-btn'
        size={'icon'}
        disabled={!table.getCanNextPage()}
        variant='outline'
        onClick={() => table.setPageIndex(table.getPageCount() - 1)}
      >
        <CaretDoubleRight />
      </Button>
    </div>
  )
}

export const usePagination = (defaultPageSize = 10) => {
  const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize: defaultPageSize,
  })

  const pagination = React.useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize])

  return { pagination, setPagination }
}

export const selectionColumn = columnHelper =>
  columnHelper.accessor('select', {
    id: 'select',
    size: 10,
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        data-testid='select-all-checkbox'
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) =>
      row.getCanSelect() ? (
        <Checkbox
          data-testid={`select-${row.original.id}`}
          checked={row.getIsSelected()}
          disabled={!row.getCanSelect()}
          onChange={row.getToggleSelectedHandler()}
        />
      ) : null,
  })
