import { useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'
import { ShipmentDTO } from 'src/types'

export const useShipment = (id: string) => {
  return useQuery<ShipmentDTO>({
    queryKey: ['/shipments', id],
    queryFn: async () => {
      const res = await getApi()?.shipments.get(`/shipment/${id}`)
      return res?.data
    },
    enabled: !!id,
    suspense: true,
  })
}
