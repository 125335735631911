import React from 'react'

import { DotsThreeVertical, Trash } from '@phosphor-icons/react'
import { createColumnHelper } from '@tanstack/react-table'
import { NavigateFunction } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Checkbox from 'src/components/Checkbox'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { Modal, openModal } from 'src/features/App/api/stores'
import { AddressDTO } from 'src/types'

const columnHelper = createColumnHelper<any>()

export const addressesTableColumns = (navigate: NavigateFunction) => [
  columnHelper.accessor('select', {
    id: 'select',
    size: 40,
    enableSorting: false,
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllRowsSelected()}
        onChange={table.getToggleAllRowsSelectedHandler()}
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        disabled={!row.getCanSelect()}
        onChange={row.getToggleSelectedHandler()}
      />
    ),
  }),
  columnHelper.accessor('name', {
    header: 'Name',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('city', {
    header: 'City',
    cell: info => <div>{info.getValue() || 'NA'}</div>,
  }),
  columnHelper.accessor('country.name', {
    header: 'Country',
    cell: info => <div>{info.getValue()}</div>,
  }),
  columnHelper.accessor('', {
    id: 'actions',
    size: 50,
    cell: info => {
      const address = info.row.original

      return (
        <div className='flex items-center justify-end gap-2'>
          <Button
            variant={'outline'}
            onClick={() => navigate(`/addresses/${address?.id.toLowerCase()}`)}
          >
            View
          </Button>

          <AddressActionsDropdown address={address} />
        </div>
      )
    },
  }),
]

export const AddressActionsDropdown = ({ address }: { address: AddressDTO }) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant={'outline'} size={'icon'}>
          <DotsThreeVertical color='000' size={20} />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem
          className='text-red-600 font-medium text-[14px] rounded'
          onClick={() => openModal(Modal.DELETE_ADDRESS, { ids: [address.id] })}
        >
          <Trash className='mr-2' color='#dc2626' />
          Delete Address
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
