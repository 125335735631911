import { MutableRefObject } from 'react'

import { create } from 'zustand'

import { defaultMapOptions } from 'src/helpers/gmap'
import { HTMLMapMarker, createHTMLMapMarkerFactoy } from 'src/helpers/markerFactory'

import {
  getAddressFromGooglePlace,
  getAddressFromLatLng,
  getLatLngFromAddressString,
} from './helpers'
import { AddressDetails } from './types'

interface AddressStoreState {
  map: google.maps.Map | null
  mapRef: MutableRefObject<HTMLDivElement | null> | null
  auto: google.maps.places.Autocomplete | null
  autoRef: MutableRefObject<HTMLInputElement | null> | null
  address: AddressDetails | null
  isdirty: boolean
  marker: HTMLMapMarker | null
  initMap: (mapRef: React.RefObject<HTMLDivElement>) => void
  initAuto: (autoRef: React.RefObject<HTMLInputElement>) => void
  onMapClick: (mouseEvent: google.maps.MapMouseEvent) => void
  updateMap: (position: AddressDetails) => void
  onAutocomplete: () => void
  reset: () => void
}
export const useAddressStore = create<AddressStoreState>((set, get) => ({
  map: null,
  mapRef: null,
  auto: null,
  autoRef: null,
  address: null,
  marker: null,
  isdirty: false,

  initMap: mapRef => {
    if (!mapRef?.current || !window.google) return

    const map = new google.maps.Map(mapRef.current, defaultMapOptions)

    map.addListener('click', get().onMapClick)

    set({ map, mapRef })
  },
  initAuto: autoRef => {
    if (!autoRef?.current || !window.google) return

    const auto = new google.maps.places.Autocomplete(autoRef?.current, {
      fields: ['address_components', 'geometry'],
      types: ['geocode'],
    })
    auto.addListener('place_changed', get().onAutocomplete)
    set({ auto, autoRef })
  },

  onMapClick: async e => {
    const position = e?.latLng

    const address = (await getAddressFromLatLng(position)) as AddressDetails
    if (address == null) {
      console.error('onMapClick address is null')
    } else {
      get().updateMap(address)
    }
  },

  onAutocomplete: () => {
    const autoComplete = get().auto?.getPlace()
    if (autoComplete == null) {
      console.error('onAutocomplete called with null value ')
    } else {
      const address = getAddressFromGooglePlace(autoComplete) as AddressDetails
      get().updateMap(address)
      set({ isdirty: true })
    }
  },

  updateMap: async (address: AddressDetails) => {
    const { map, marker } = get()

    if (!map) {
      console.error('No map object set')
      return
    }
    const position = await getLatLngFromAddressString(address)
    if (!position) {
      console.error('No map position object set')
      return
    }

    if (!marker) {
      console.log(`Marker init with ${position}`)
      const HTMLMapMarker = createHTMLMapMarkerFactoy(map)
      set({ marker: new HTMLMapMarker({ position, color: 'blue' }) })
    } else {
      console.error(`Marker updated with ${position}`)
      marker.setPosition(position)
    }

    map.panTo(position)
    map.setZoom(13)
    set({ address })
  },

  reset: () => {
    set({ address: null, marker: null, isdirty: false })
  },
}))
