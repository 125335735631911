import React, { useCallback, useEffect } from 'react'

import { Check, FloppyDisk, Prohibit } from '@phosphor-icons/react'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import PhoneInput from 'react-phone-input-2'
import { PuffLoader } from 'react-spinners'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import { Skeleton } from 'src/components/Skeleton'
import Subheader from 'src/components/Subheader'

import { useCurrentOrg, useUpdateOrgMutator } from '../../api/queries'

const SettingsPageDesktop: React.FC = () => {
  const form = useForm({
    defaultValues: {
      contactName: '',
      contactEmail: '',
      contactTelephone: '',
    },
  })

  const currentOrg = useCurrentOrg()

  const resetForm = useCallback(() => {
    form.reset({
      contactName: String(currentOrg.data?.contactName),
      contactEmail: String(currentOrg.data?.contactEmail),
      contactTelephone: String(currentOrg.data?.contactTelephone),
    })
  }, [currentOrg.data, form])

  useEffect(() => {
    if (currentOrg.data) resetForm()

    return () => {
      currentOrg.refetch()
    }
  }, [currentOrg.data, resetForm])

  const updateOrg = useUpdateOrgMutator(currentOrg.data?.id)

  const handleSubmit = form.handleSubmit(async data => {
    await updateOrg.mutateAsync(data, {
      onError: e => {
        if (e instanceof AxiosError) {
          toast.error(e?.response?.data?.mesage)
        }
      },
      onSuccess: async () => {
        form.reset(data)
        toast.success('Settings updated')
      },
    })
  })

  return (
    <Form {...form}>
      <div className='flex-1' data-testid='profile-page-desktop'>
        <Subheader
          title={'Settings'}
          actions={
            <div className='flex items-center gap-2'>
              <Button
                variant={'outline'}
                disabled={!form.formState.isDirty}
                onClick={resetForm}
              >
                {/* <i className='far fa-cancel mr-2' /> */}
                <Prohibit className='mr-2' />
                Cancel
              </Button>
              <Button
                variant={'outline'}
                disabled={!form.formState.isDirty}
                onClick={handleSubmit}
              >
                {updateOrg.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
                {/* <i className='far fa-floppy-disk mr-2' /> */}
                <FloppyDisk className='mr-2' />
                Save
              </Button>
            </div>
          }
        />
        <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
          {currentOrg.isLoading ? (
            <div className='flex items-center justify-center h-60'>
              <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
            </div>
          ) : (
            <div className='container py-4 flex flex-col gap-4'>
              <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
                <h1 className='font-semibold text-lg'>Company Information</h1>
                <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                  <div>
                    <label className='text-[14px] font-medium'>Company Name</label>
                    {currentOrg?.isLoading ? (
                      <Skeleton className='h-[24px] w-[250px]' />
                    ) : (
                      <p>{currentOrg?.data?.name}</p>
                    )}
                  </div>
                </div>
              </div>

              <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
                <h1 className='font-semibold tex3t-lg'>Contact Details</h1>
                <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4'>
                  <FormField
                    control={form.control}
                    name='contactName'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contact Name</FormLabel>
                        <FormControl>
                          <Input {...field} className='w-full' data-testid='name-input' />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='contactEmail'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contact Email</FormLabel>
                        <FormControl>
                          <Input
                            {...field}
                            className='w-full'
                            data-testid='email-input'
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                  <FormField
                    control={form.control}
                    name='contactTelephone'
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Contact Telephone</FormLabel>
                        <FormControl>
                          <PhoneInput
                            data-testid='telephone-input'
                            inputClass='flex h-[36px] rounded border border-slate-300 text-base bg-white px-3 py-2 ring-offset-white file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-slate-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-1 disabled:cursor-not-allowed disabled:opacity-50 dark:border-slate-800 dark:bg-slate-950 dark:ring-offset-slate-950 dark:placeholder:text-slate-400 dark:focus-visible:ring-slate-300 w-full'
                            {...field}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
              </div>
              <div className='flex flex-col bg-white border border-slate-300 rounded p-4 flex gap-4'>
                <h1 className='font-semibold text-lg'>Services</h1>
                {currentOrg.data?.isFinanceApproved && (
                  <div className='flex items-center space-x-1'>
                    {/* <i className='far fa-check text-green-600 mr-2' /> */}
                    <Check className='mr-2' color='#16a34a' />
                    <span>Finance</span>
                  </div>
                )}
                {currentOrg.data?.isInsuranceApproved && (
                  <div className='flex items-center space-x-1'>
                    {/* <i className='far fa-check text-green-600 mr-2' /> */}
                    <Check className='mr-2' color='#16a34a' />
                    <span>Insurance</span>
                  </div>
                )}
              </div>
              {/* <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
              <h1 className='font-semibold tex3t-lg'>Billing</h1>

              <div>{printAddress({})}</div>
            </div> */}
            </div>
          )}
        </div>
      </div>
    </Form>
  )
}

export default SettingsPageDesktop
