import { create } from 'zustand'

export enum Modal {
  DISABLE_USER,
  ENABLE_USER,
  DELETE_REPORT,
  DELETE_ADDRESS,
  DELETE_LEG,
  DELETE_CONTAINER,
  DELETE_SENSOR_PROFILE,
  SELECT_SHIPMENT_RECIPIENTS,
  ADD_FLARES_TO_CONTAINER,
  MANAGE_CONTAINER_DEVICES,
  ADD_RECIPIENTS,
  UPDATE_BOL_NUMBER,
  MARK_TO_COMPLETE,
}

interface UseModalsStore {
  modal: Modal | null
  state: Record<string, unknown>
  callback: () => void
  openModal: (modal: Modal, state?: any, callback?: () => void) => void
  closeModal: () => void
}

export const useModals = create<UseModalsStore>()(set => ({
  modal: null,
  state: {},
  callback: () => {},
  openModal: (modal: Modal, state?: Record<string, unknown>, callback?: () => void) => {
    set({ modal, state, callback }) // Set the callback in the state
  },
  closeModal: () => {
    set({ modal: null, state: {}, callback: () => {} })
  },
}))

export const openModal = useModals.getState().openModal
export const closeModal = useModals.getState().closeModal
