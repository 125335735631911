import React from 'react'

import { Plus } from '@phosphor-icons/react'
import {
  SortingState,
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { Link } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Searchbar from 'src/components/Searchbar'
import Subheader from 'src/components/Subheader'
import Table, { PaginationControls, usePagination } from 'src/components/Table'
import { AspNetUserDTO } from 'src/types/flare-user-dev'

import { useUsers } from '../../api/queries'

import { usersTableColumns } from './components'

const UsersPageDesktop: React.FC = () => {
  const { pagination, setPagination } = usePagination()

  const [search, setSearch] = React.useState('')

  const users = useUsers({
    search,
    status,
    pageIndex: pagination.pageIndex + 1,
    pageSize: 15,
  })

  const [sorting, setSorting] = React.useState<SortingState>([])
  const [rowSelection, setRowSelection] = React.useState({})

  const table = useReactTable<AspNetUserDTO>({
    data: (users?.data?.items as AspNetUserDTO[]) || [],
    pageCount: users?.data?.paging?.pageCount ?? 0,
    columns: usersTableColumns,
    enableColumnResizing: true,
    columnResizeMode: 'onChange',
    state: { sorting, rowSelection, pagination },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
    manualPagination: true,
  })

  return (
    <div className='flex-1' data-testid='list-users-page-desktop'>
      <Subheader
        title={'Users'}
        actions={
          <div className='flex items-center gap-2'>
            <Link to='/users/new'>
              <Button variant='outline' date-testid='add-user-btn'>
                {/* <i className='fas fa-plus mr-2' /> */}
                <Plus className='mr-2' />
                Add User
              </Button>
            </Link>
          </div>
        }
      />

      <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
        <div className='container py-4 flex flex-col gap-4'>
          <div className='flex items-center justify-between'>
            <div className='flex items-center gap-2'>
              <Searchbar
                onSearch={setSearch}
                placeholder={'Search...'}
                value={search}
                data-testid='searchbar-input'
              />
            </div>
            <PaginationControls table={table} />
          </div>
          {/* @ts-ignore */}
          <Table table={table} isLoading={users.isFetching} minHeight={15 * 60} />
          <div className='flex items-center justify-end'>
            <PaginationControls table={table} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default UsersPageDesktop
