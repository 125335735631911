import React from 'react'

import { cn } from 'src/utils'

const Skeleton = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'animate-pulse rounded-md bg-slate-200 dark:bg-slate-800',
          className
        )}
        {...props}
      />
    )
  }
)

export { Skeleton }
