import React, { Suspense, useEffect, useState } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import {
  Bell,
  Check,
  CircleNotch,
  CloudArrowUp,
  CreditCard,
  Envelope,
  File,
  FileArrowUp,
  FileDoc,
  FilePdf,
  Flag,
  FloppyDisk,
  MapPin,
  Package,
  Plus,
  Prohibit,
  Pulse,
  Question,
  ShieldCheck,
  Trash,
  UploadSimple,
  User,
  X,
} from '@phosphor-icons/react'
import { useQueryClient } from '@tanstack/react-query'
import clsx from 'clsx'
import { title } from 'radash'
import { ErrorBoundary } from 'react-error-boundary'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import { Document, Page } from 'react-pdf'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { MoonLoader } from 'react-spinners'
import { toast } from 'sonner'
import { z } from 'zod'
import { create } from 'zustand'

import Block from 'src/components/Block'
import { Button } from 'src/components/Button'
import { Combobox } from 'src/components/Combobox'
import { DatePicker } from 'src/components/DatePicker'
import { ErrorDisplay, Input } from 'src/components/Input'
import { Label } from 'src/components/Label'
import RangePill from 'src/components/RangePill'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/Select'
import Subheader from 'src/components/Subheader'
import { Tooltip, TooltipContent, TooltipTrigger } from 'src/components/Tooltip'
import { useDevices } from 'src/features/Flares/api/queries'
import { useSensorProfiles } from 'src/features/SensorProfiles/api/queries'
import { useCurrentOrg } from 'src/features/Settings/api/queries'
import { useUsers } from 'src/features/Users'
import { convertLocationToLatLng, defaultMapOptions } from 'src/helpers/gmap'
import { useMemoisedOptions } from 'src/helpers/hooks'
import { getApi } from 'src/services'
import { AddressDTO, DeviceDTO, DocumentDTO, LocationDTO } from 'src/types'

import {
  shippingTermOptions,
  useOrchestrateCreateShipmentMutator,
  useOrchestrateUpdateShipmentMutator,
  useScanFileMutator,
  useShipment,
} from '../../api'
import { useShipmentMap } from '../../api/stores'
import { LoadingIndicator } from '../shared'

export interface UseShipmentStore {
  step: number
  nextStep: () => void
  prevStep: () => void
  resetSteps: () => void
  setStep: (step: number) => void

  legId: string
  containerId: string

  poFile: File | null
  po: any
  poLineItems: any
  scanSuccessful: boolean

  shipName: string

  shipFromAddress: AddressDTO | null
  shipFromLocation: LocationDTO | null
  shipFromString: string

  shipToAddress: AddressDTO | null
  shipToLocation: LocationDTO | null
  shipToString: string

  shipMode: string
  shipDate: Date
  shipTerms: any
  shipSensorProfile: any

  shipIsInsured: boolean
  shipIsFinanced: boolean

  shipDevices: (DeviceDTO | null)[]
  addDeviceSlot: () => void
  setDevice: (index: number, device: DeviceDTO) => void
  removeDevice: (index: number) => void

  shipUserRecipients: any[]
  addUserRecipient: (user: any) => void
  removeUserRecipient: (index: number) => void

  shipEmailRecipients: any[]
  addEmailRecipient: (email: string) => void
  removeEmailRecipient: (index: number) => void

  shipDocuments: any[]
  addDocuments: (document: any) => void
  removeDocument: (index: number) => void

  resetStore: () => void
}

const useShipmentStore = create<UseShipmentStore>((set, get) => ({
  step: 0,
  nextStep: () => set(state => ({ step: state.step + 1 })),
  prevStep: () => set(state => ({ step: state.step - 1 })),
  resetSteps: () => set({ step: 0 }),
  setStep: step => set({ step }),

  legId: '',
  containerId: '',

  poFile: null,
  po: null,
  poLineItems: null,
  scanSuccessful: false,

  shipName: '',

  shipFromAddress: null,
  shipFromLocation: null,
  shipFromString: '',

  shipToAddress: null,
  shipToLocation: null,
  shipToString: '',

  shipMode: '0',
  shipDate: new Date(),
  shipTerms: shippingTermOptions[0],
  shipSensorProfile: '',

  shipIsInsured: false,
  shipIsFinanced: false,

  shipDevices: [],
  addDeviceSlot: () => set(state => ({ shipDevices: [...state.shipDevices, null] })),
  setDevice: (index, device) =>
    set(state => {
      const devices = [...state.shipDevices]
      devices[index] = device
      return { shipDevices: devices }
    }),
  removeDevice: index =>
    set(state => ({
      shipDevices: state.shipDevices.filter((_, i) => i !== index),
    })),

  shipUserRecipients: [],
  addUserRecipient: user =>
    set(state => ({ shipUserRecipients: [...state.shipUserRecipients, user] })),
  removeUserRecipient: index =>
    set(state => ({
      shipUserRecipients: state.shipUserRecipients.filter((_, i) => i !== index),
    })),

  shipEmailRecipients: [],
  addEmailRecipient: email =>
    set(state => ({ shipEmailRecipients: [...state.shipEmailRecipients, email] })),
  removeEmailRecipient: index =>
    set(state => ({
      shipEmailRecipients: state.shipEmailRecipients.filter((_, i) => i !== index),
    })),

  shipDocuments: [],
  addDocuments: documents => {
    const maxSize = 100 * 1024 * 1024 // 100MB in bytes
    const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png']

    const totalSize = documents.reduce((acc, doc) => acc + doc.size, 0)
    if (totalSize > maxSize) {
      toast.error('Documents exceed 100MB limit')
      return
    }

    const invalidTypes = documents.filter(doc => !allowedTypes.includes(doc.type))
    if (invalidTypes.length > 0) {
      const invalidNames = invalidTypes.map(doc => doc.name).join(', ')
      toast.error(
        `Invalid file types: ${invalidNames}. Only PDF, JPG, and PNG files are allowed.`
      )
      return
    }

    if (get().shipDocuments.length + documents.length > 9) {
      toast.error('Cannot add more than 9 documents')
      return
    }

    set(state => ({ shipDocuments: [...state.shipDocuments, ...documents] }))
  },
  removeDocument: index =>
    set(state => ({
      shipDocuments: state.shipDocuments.filter((_, i) => i !== index),
    })),

  resetStore: () =>
    set({
      step: 0,
      legId: '',
      containerId: '',
      poFile: null,
      po: null,
      poLineItems: null,
      scanSuccessful: false,
      shipName: '',
      shipFromAddress: null,
      shipFromLocation: null,
      shipFromString: '',
      shipToAddress: null,
      shipToLocation: null,
      shipToString: '',
      shipMode: '0',
      shipDate: new Date(),
      shipTerms: shippingTermOptions[0],
      shipSensorProfile: '',
      shipIsInsured: false,
      shipIsFinanced: false,
      shipDevices: [],
      shipUserRecipients: [],
      shipEmailRecipients: [],
      shipDocuments: [],
    }),
}))

const {
  nextStep,
  setStep,
  addDeviceSlot,
  setDevice,
  removeDevice,
  addEmailRecipient,
  removeEmailRecipient,
  addUserRecipient,
  removeUserRecipient,
  addDocuments,
  removeDocument,
  resetStore,
} = useShipmentStore.getState()

export const ShipmentFormPage = () => {
  const { id } = useParams() as { id: string }

  useEffect(() => {
    return () => {
      useShipmentMap.getState().clearMap()
      resetStore()
    }
  }, [])

  return (
    <div className='flex-1'>
      {!id && <CreateShipmentForm />}
      {id && <EditShipmentForm />}
    </div>
  )
}

export default ShipmentFormPage

// EDIT

const EditShipmentForm = () => {
  return (
    <>
      <Suspense fallback={<LoadingIndicator />}>
        <ShipmentSetupStep />
      </Suspense>
    </>
  )
}

// CREATE

const CreateShipmentForm = () => {
  const step = useShipmentStore(state => state.step)

  return (
    <>
      {step == 0 && <PromptUploadStep />}
      {step == 1 && <ProcessUploadStep />}
      {step == 2 && <VerifyDetailsStep />}
      {step == 3 && <ShipmentSetupStep />}
    </>
  )
}

const PromptUploadStep = () => {
  const inputRef = React.useRef<HTMLInputElement>(null)

  const handleFileChange = event => {
    const file = event.target.files?.[0]

    if (!file) {
      toast.error('No file selected')
      return
    }

    if (file.size > 100 * 1024 * 1024) {
      toast.error('File size exceeds 100MB')
      return
    }

    if (!file.type.startsWith('image/') && file.type !== 'application/pdf') {
      toast.error('File type must be an image or PDF')
      return
    }

    useShipmentStore.setState({ poFile: file })
    nextStep()
  }

  const handleDrop = event => {
    event.preventDefault()
    const file = event.dataTransfer.files?.[0]

    // Use the same file validation logic as in handleFileChange
    handleFileChange({ target: { files: [file] } })
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  return (
    <>
      <Subheader title={'Creating New Shipment'} actions={<CancelBtn />} />
      <PageLayout>
        <h1 className='font-medium flex items-center justify-center gap-2'>
          <CloudArrowUp color='#2563eb' />
          Get started by uploading your Purchase Order, we’ll scan the file and fill in
          the details for you.
        </h1>
        <Block>
          <h1 className='font-medium mb-2'>Upload Purchase Order</h1>
          <div
            className='p-6 gap-2 bg-slate-100 border-2 border-dashed border-slate-300 rounded flex items-center flex-col justify-center'
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >
            <FileArrowUp size={32} />
            <p className='text-slate-600'>Drag and drop your file here</p>
            <p className='text-slate-400'>or</p>
            <Button onClick={() => inputRef.current?.click()}>Browse Files</Button>
            <small className='text-slate-400'>Maximum size 100mb</small>
            <input
              ref={inputRef}
              type='file'
              onChange={handleFileChange}
              hidden
              accept='image/*,.pdf'
            />
          </div>
        </Block>
      </PageLayout>
    </>
  )
}

const ProcessUploadStep = () => {
  const scanFile = useScanFileMutator()

  useEffect(() => {
    scanFile.mutate(useShipmentStore.getState().poFile, {
      onSuccess: result => {
        useShipmentStore.setState({
          scanSuccessful: true,
          po: result,
          poLineItems: result?.line_items,
          shipName: result?.po_number,
          shipFromString: result?.vendor_address,
          shipToString: result?.ship_to_address,
        })
      },
      onError: (error: any) => {
        toast.error(`Unable to scan File. ${error.message}`)
      },
      onSettled: nextStep,
    })
  }, [])

  return (
    <>
      <Subheader title={'Creating New Shipment'} actions={<CancelBtn />} />
      <PageLayout>
        <Block className='flex flex-col gap-4 items-center justify-center h-60'>
          <MoonLoader size={24} color='#2563eb' />
          <h1 className='font-semibold'>Processing your Purchase Order...</h1>
        </Block>
      </PageLayout>
    </>
  )
}

const detailsSchema = zodResolver(
  z.object({
    shipName: z.string().min(1, 'Name is required'),
    shipFromString: z.string().min(1, 'From is required'),
    shipToString: z.string().min(1, 'To is required'),
  })
)

const getLocationFromStr = async (address: string) => {
  const res = await getApi()?.location?.get(`/location/from-string`, {
    params: { address },
  })
  return res?.data
}

const verifyLocations = async ({ shipFromString, shipToString }) => {
  let success = true

  try {
    const shipFromLocation = await getLocationFromStr(shipFromString)
    useShipmentStore.setState({ shipFromLocation })
  } catch (e) {
    success = false
    toast.error("Unable to find a location for 'Shipment From' address")
  }

  try {
    const shipToLocation = await getLocationFromStr(shipToString)
    useShipmentStore.setState({ shipToLocation })
  } catch (e) {
    success = false
    toast.error("Unable to find a location for 'Shipment To' address")
  }

  return success
}

const VerifyDetailsStep = () => {
  const scanSuccessful = useShipmentStore(state => state.scanSuccessful)
  const poLineItems = useShipmentStore(state => state.poLineItems)

  const shipName = useShipmentStore(state => state.shipName)
  const shipFromString = useShipmentStore(state => state.shipFromString)
  const shipToString = useShipmentStore(state => state.shipToString)
  const poFile = useShipmentStore(state => state.poFile)

  const defaultValues = { shipName, shipFromString, shipToString }

  const form = useForm<any>({ defaultValues, resolver: detailsSchema })

  const handleSubmit = form.handleSubmit(async data => {
    const success = await verifyLocations({ ...data })
    if (success) {
      useShipmentStore.setState({ ...data })
      nextStep()
    }
  })

  return (
    <>
      <Subheader
        title={'Creating New Shipment'}
        actions={
          <div className='flex gap-2 items-center'>
            <CancelBtn />
            <Button onClick={handleSubmit} variant={'outline'}>
              {form.formState.isSubmitting ? (
                <CircleNotch className='mr-2 animate-spin' />
              ) : (
                <Check className='mr-2' />
              )}
              Confirm Details
            </Button>
          </div>
        }
      />
      <PageLayout>
        {scanSuccessful && (
          <h1 className='font-medium flex items-center justify-center gap-2'>
            <CloudArrowUp color='#2563eb' />
            We’ve scanned your Purchase Order, please confirm that the details below are
            correct.
          </h1>
        )}

        <Block className='grid gap-4' style={{ gridTemplateColumns: '250px auto' }}>
          <div className='w-full rounded overflow-hidden bg-slate-50 min-h-[250px] h-fit border border-slate-300 p-1'>
            {poFile && poFile.type === 'application/pdf' ? (
              <Document file={poFile}>
                <Page
                  pageNumber={1}
                  width={250}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                />
              </Document>
            ) : (
              <img src={URL.createObjectURL(poFile as File)} alt='Uploaded File' />
            )}
          </div>

          <div className='space-y-4'>
            <div className='space-y-1'>
              <Label>Shipment Name</Label>
              <Input className='w-full' {...form.register('shipName')} />
              <ErrorDisplay context={form} name='shipName' />
            </div>

            <div className='space-y-1'>
              <Label>Shipment From</Label>
              <Input className='w-full' {...form.register('shipFromString')} />
              <ErrorDisplay context={form} name='shipFromString' />
            </div>

            <div className='space-y-1'>
              <Label>Shipment To</Label>
              <Input className='w-full' {...form.register('shipToString')} />
              <ErrorDisplay context={form} name='shipToString' />
            </div>

            {Array.isArray(poLineItems) && (
              <div className='space-y-1'>
                <Label>Contents</Label>
                <ContentsTable
                  headers={['description', 'product_code', 'quantity']}
                  rows={poLineItems}
                />
              </div>
            )}
          </div>
        </Block>
      </PageLayout>
    </>
  )
}

const setupSchema = zodResolver(
  z.object({
    shipMode: z.string().min(1, 'Shipment Mode is required'),
    shipDate: z.date(),
    shipTerms: z.object({
      label: z.string().min(1),
      value: z.string().min(1),
    }),
    shipSensorProfile: z.object(
      {
        label: z.string().min(1),
        value: z.string().min(1, { message: 'Sensor Profile is required' }),
      },
      { invalid_type_error: 'Sensor Profile is required' }
    ),
  })
)

const defaultValues = {
  shipMode: '0',
  shipDate: new Date(),
  shipSensorProfile: '',
  shipTerms: shippingTermOptions[0],
}

const ShipmentSetupStep = () => {
  const { id } = useParams() as { id: string }
  const shipmentQuery = useShipment(id)

  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const createShipment = useOrchestrateCreateShipmentMutator()
  const updateShipment = useOrchestrateUpdateShipmentMutator(id)

  const form = useForm({
    defaultValues,
    resolver: setupSchema,
  })

  const handleSubmitCreate = form.handleSubmit(
    async data => {
      useShipmentStore.setState({ ...data })

      await createShipment.mutateAsync(useShipmentStore.getState(), {
        onSuccess: async () => {
          await queryClient.invalidateQueries([`'/shipments'`])
          navigate('/shipments')
          setStep(0)
          toast.success('Shipment Created.')
        },
      })
    },
    () => {
      toast.error(`There are errors with the form.`)
    }
  )

  const handleSubmitEdit = form.handleSubmit(
    async data => {
      useShipmentStore.setState({ ...data })

      await updateShipment.mutateAsync(useShipmentStore.getState(), {
        onSuccess: async () => {
          await queryClient.invalidateQueries([`'/shipments'`])
          navigate('/shipments')
          setStep(0)
          toast.success('Shipment Updated.')
        },
      })
    },
    () => {
      toast.error(`There are errors with the form.`)
    }
  )

  useEffect(() => {
    if (shipmentQuery.data) {
      const leg = shipmentQuery.data.legs?.[0]
      const container = shipmentQuery.data.containers?.[0]

      const shipName = shipmentQuery.data.name as string
      const shipFromString = leg?.origin?.name as string
      const shipToString = leg?.destination?.name as string
      const shipFromAddress = leg?.origin
      const shipToAddress = leg?.destination
      const shipFromLocation = leg?.origin?.location
      const shipToLocation = leg?.destination?.location
      const shipMode = leg?.transportMode?.toString()
      const shipDate = new Date(shipmentQuery.data?.shippingDate as string)
      const shipTerms = shippingTermOptions.find(
        t => t.value == shipmentQuery.data?.shippingTerm?.toString()
      ) as any
      const shipSensorProfile = {
        label: container?.sensorProfile?.name,
        value: container?.sensorProfileId,
      } as any
      const shipIsFinanced = shipmentQuery.data.isFinanced as boolean
      const shipIsInsured = shipmentQuery.data.isInsured as boolean
      const shipDevices = container?.devices ?? []
      const po = shipmentQuery.data.purchaseOrder
      const poLineItems = JSON.parse(shipmentQuery.data.purchaseOrder?.line_items ?? '[]')

      const shipEmailRecipients = shipmentQuery.data?.recipients
        ?.filter(r => {
          return r.emailAddress === r.name
        })
        .map(r => r.emailAddress)

      const shipUserRecipients = shipmentQuery.data?.recipients
        ?.filter(r => {
          return r.emailAddress !== r.name
        })
        .map(r => r)

      const shipDocuments = (shipmentQuery.data?.documents ?? [])?.filter(
        d => !d.isPurchaseOrder
      )
      const poFile = shipmentQuery.data?.documents?.find(d => d.isPurchaseOrder) as any

      console.log(shipToAddress)

      useShipmentStore.setState({
        containerId: container?.id,
        legId: leg?.id as string,
        shipName,
        shipFromString,
        shipToString,
        shipFromAddress,
        shipToAddress,
        shipFromLocation,
        shipToLocation,
        shipMode,
        shipDate,
        shipTerms,
        shipSensorProfile,
        shipIsFinanced,
        shipIsInsured,
        shipDevices,
        po,
        poLineItems,
        shipEmailRecipients,
        shipUserRecipients,
        shipDocuments,
        poFile,
      })

      form.reset({
        shipMode,
        shipDate,
        shipTerms,
        shipSensorProfile,
      })
    }
  }, [shipmentQuery.data])

  return (
    <FormProvider {...form}>
      {!id && (
        <Subheader
          title={'Creating New Shipment'}
          actions={
            <div className='flex gap-2 items-center'>
              <CancelBtn />
              <Button onClick={handleSubmitCreate} variant={'default'}>
                {createShipment.isLoading ? (
                  <CircleNotch color='#fff' className='mr-2 animate-spin' />
                ) : (
                  <FloppyDisk className='mr-2' color='white' />
                )}
                Create Shipment
              </Button>
            </div>
          }
        />
      )}

      {id && (
        <Subheader
          title={`Editing Shipment: '${shipmentQuery.data?.name}'`}
          actions={
            <div className='flex gap-2 items-center'>
              <CancelBtn />
              <Button onClick={handleSubmitEdit} variant={'default'}>
                {createShipment.isLoading ? (
                  <CircleNotch color='#fff' className='mr-2 animate-spin' />
                ) : (
                  <FloppyDisk className='mr-2' color='white' />
                )}
                Edit Shipment
              </Button>
            </div>
          }
        />
      )}

      <PageLayout>
        <ShipmentDetailsBlock />
        <ShipmentTrackingBlock />
        <ShipmentCargoBlock />
        <ShipmentFinanceBlock />
        <ShipmentCollaboratorsBlock />
        <ShipmentDocumentsBlock />
      </PageLayout>
    </FormProvider>
  )
}

const MODES = ['Sea', 'Air', 'Rail', 'Road']

const ShipmentDetailsBlock = () => {
  const form = useFormContext()

  const shipName = useShipmentStore(state => state.shipName)
  const shipFromString = useShipmentStore(state => state.shipFromString)
  const shipToString = useShipmentStore(state => state.shipToString)

  return (
    <Block className='space-y-4'>
      <Block.Title icon={MapPin}>Shipment Details</Block.Title>
      <ShipmentMap />

      <div className='grid grid-cols-3 gap-4 '>
        <div className='space-y-1'>
          <Label>
            Shipment Name <Check color='#16a34a' size={16} />
          </Label>
          <Input disabled value={shipName} className='w-full' isValid />
        </div>

        <div className='space-y-1'>
          <Label>
            Shipment From <Check color='#16a34a' size={16} />
          </Label>
          <Input disabled value={shipFromString} className='w-full' isValid />
        </div>

        <div className='space-y-1'>
          <Label>
            Shipment To <Check color='#16a34a' size={16} />
          </Label>
          <Input disabled value={shipToString} className='w-full' isValid />
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <div className='flex flex-col gap-1'>
          <Label>Shipment Mode</Label>
          <Controller
            control={form.control}
            name='shipMode'
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger>
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  {MODES.map((m, i) => (
                    <SelectItem key={i} value={String(i)}>
                      {m}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        </div>

        <div className='flex flex-col gap-1'>
          <Label>
            Ship Date
            <Tooltip>
              <TooltipTrigger>
                <Question className='ml-1' />
              </TooltipTrigger>
              <TooltipContent>
                <p>The date the shipment is scheduled to begin transit.</p>
              </TooltipContent>
            </Tooltip>
          </Label>
          <Controller
            control={form.control}
            name='shipDate'
            render={({ field }) => (
              <DatePicker mode='single' {...field} date={field.value as any} />
            )}
          />
          <ErrorDisplay context={form} name='shipDate' />
        </div>
      </div>
    </Block>
  )
}

const ShipmentTrackingBlock = () => {
  return (
    <Block className='flex flex-col gap-4'>
      <Block.Title icon={Pulse}>Devices</Block.Title>
      <SensorProfileSelector />
      <DeviceSelectWrapper />
    </Block>
  )
}

const ShipmentCargoBlock = () => {
  const form = useFormContext()
  const poLineItems = useShipmentStore(state => state.poLineItems)

  return (
    <Block className='space-y-4'>
      <Block.Title icon={Package}>Cargo</Block.Title>
      <div className='space-y-1 md:max-w-[50%]'>
        <Label>Shipping Terms</Label>
        <Controller
          control={form.control}
          name='shipTerms'
          render={({ field }) => (
            <Combobox
              className='w-full'
              options={shippingTermOptions}
              value={field.value}
              onSelect={field.onChange}
            />
          )}
        />
      </div>

      <div className='space-y-1 border-t border-slate-300 pt-4'>
        <Label>Cargo Items</Label>
        <ContentsTable
          headers={['description', 'product_code', 'quantity']}
          rows={poLineItems}
        />
      </div>
    </Block>
  )
}

export const ShipmentFinanceBlock = () => {
  const { id } = useParams() as { id: string }
  const shipmentQuery = useShipment(id)
  const currentOrg = useCurrentOrg()
  const isFinanceApproved = shipmentQuery?.data?.isFinanced
  const isInsuranceApproved = shipmentQuery?.data?.isInsured
  const isFinanceAllowed = currentOrg.data?.isFinanceApproved
  const isInsuranceAllowed = currentOrg.data?.isInsuranceApproved
  const cols = isFinanceAllowed && isInsuranceAllowed ? 2 : 1

  useEffect(() => {
    if (!shipmentQuery.isLoading) {
      useShipmentStore.setState({
        shipIsFinanced: isFinanceApproved ?? false,
        shipIsInsured: isInsuranceApproved ?? false,
      })
    }
  }, [shipmentQuery.isLoading])

  return (
    <div
      className={clsx(
        cols == 1 ? 'md:grid-cols-1' : 'md:grid-cols-2',
        'grid grid-cols-1 gap-6'
      )}
    >
      {isFinanceAllowed && (
        <Block className='grid gap-4' style={{ gridTemplateColumns: 'auto 100px' }}>
          <div className='flex flex-col gap-1'>
            <Block.Title icon={CreditCard}>Finance</Block.Title>
            {isFinanceApproved ? (
              <div className='flex text-center font-normal text-slate-900'>
                Finance approved for this Shipment
                <Check color='#16A34A' className='ml-2' />
              </div>
            ) : (
              <p className='font-semibold text-amber-400'>Awaiting Approval</p>
            )}
          </div>
        </Block>
      )}
      {isInsuranceAllowed && (
        <Block className='grid gap-4' style={{ gridTemplateColumns: 'auto 100px' }}>
          <div className='flex flex-col gap-1'>
            <Block.Title icon={ShieldCheck}>Insurance</Block.Title>
            {isInsuranceApproved ? (
              <div className='flex text-center font-normal text-slate-900'>
                Insurance approved for this Shipment
                <Check color='#16A34A' className='ml-2' />
              </div>
            ) : (
              <p className='font-semibold text-amber-400'>Awaiting Approval</p>
            )}
          </div>
        </Block>
      )}
    </div>
  )
}

const ShipmentCollaboratorsBlock = () => {
  const emailRecipients = useShipmentStore(state => state.shipEmailRecipients)

  const userRecipients = useShipmentStore(state => state.shipUserRecipients)
  console.log(userRecipients)
  return (
    <Block className='space-y-2'>
      <Block.Title icon={Bell}>Collaborators</Block.Title>
      <Label>Notification Recipients</Label>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
        <UserRecipientSelector />
        <EmailRecipientSelector />
      </div>
      <div className='flex items-center gap-1 flex-wrap'>
        {userRecipients.map((user, index) => (
          <div
            className='rounded flex items-center gap-1 border border-slate-300 px-1.5 py-0.5'
            key={index}
          >
            <User color='#2563eb' size={16} />
            {user?.name || user.fullName}
            <X
              onClick={() => removeUserRecipient(index)}
              size={14}
              className='cursor-pointer'
            />
          </div>
        ))}

        {emailRecipients.map((email, index) => (
          <div
            className='rounded flex items-center gap-1 border border-slate-300 px-1.5 py-0.5'
            key={index}
          >
            <Envelope size={16} />
            {email}
            <X
              onClick={() => removeEmailRecipient(index)}
              size={14}
              className='cursor-pointer'
            />
          </div>
        ))}
      </div>
    </Block>
  )
}

const ShipmentDocumentsBlock = () => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const poFile = useShipmentStore(state => state.poFile)
  const shipDocuments = useShipmentStore(state => state.shipDocuments)

  const handleFileChange = event => {
    const files = event.target.files
    addDocuments(Array.from(files))
  }

  const handleDrop = event => {
    event.preventDefault()
    const files = event.target.files
    addDocuments([...shipDocuments, ...Array.from(files)])
  }

  const handleDragOver = event => {
    event.preventDefault()
  }

  return (
    <Block className='space-y-4'>
      <Block.Title icon={FileArrowUp}>Documents</Block.Title>
      <div className='grid grid-cols-3 gap-4'>
        <FileDisplay file={poFile} />
        {shipDocuments.map((file, index) => (
          <FileDisplay key={index} file={file} onRemove={() => removeDocument(index)} />
        ))}
      </div>

      <div
        className='p-6 gap-2 bg-slate-100 border-2 border-dashed border-slate-300 rounded flex items-center flex-col justify-center'
        onDrop={handleDrop}
        onDragOver={handleDragOver}
      >
        <FileArrowUp size={32} />
        <p className='text-slate-600'>Drag and drop your file here</p>
        <p className='text-slate-400'>or</p>
        <Button onClick={() => inputRef.current?.click()}>Browse Files</Button>
        <small className='text-slate-400'>Maximum size 100mb</small>
        <input
          type='file'
          multiple
          hidden
          ref={inputRef}
          onChange={handleFileChange}
          accept='image/*,.pdf'
        />
      </div>
    </Block>
  )
}

// SUBCOMPONENTS

const ContentsTable = ({ headers, rows }) => {
  if (!Array.isArray(rows)) return null
  if (!Array.isArray(headers) || headers.length === 0) return null

  return (
    <div className='overflow-hidden border border-slate-300 rounded text-[14px]'>
      <div
        className='grid'
        style={{ gridTemplateColumns: `repeat(${headers.length}, 1fr)` }}
      >
        {headers?.map((header, index) => (
          <div
            key={index}
            className='bg-slate-100 px-4 py-2 text-left font-medium border-b border-slate-300'
          >
            {title(header ?? '').replace('_', ' ')}
          </div>
        ))}

        {rows?.map((row, rowIndex) =>
          headers.map((header, cellIndex) => (
            <div
              key={`${rowIndex}-${cellIndex}`}
              className={clsx(
                rowIndex === rows.length - 1 &&
                  rows.length !== 1 &&
                  'border-b border-slate-300',
                'px-4 py-2'
              )}
            >
              {row[header] === null || row[header] === undefined
                ? 'N/A'
                : String(row[header])}
            </div>
          ))
        )}
      </div>
    </div>
  )
}

const ShipmentMap = () => {
  const mapRef = React.useRef<HTMLDivElement>(null)
  const [map, setMap] = useState<google.maps.Map | null>()
  const shipFromLocation = useShipmentStore(state => state.shipFromLocation)
  const shipToLocation = useShipmentStore(state => state.shipToLocation)

  const { initialiseMap, addMarker, addLine, centerMap } = useShipmentMap.getState()

  useEffect(() => {
    if (mapRef.current) {
      setMap(new google.maps.Map(mapRef.current, defaultMapOptions))
    }
  }, [mapRef])

  useEffect(() => {
    if (map) {
      initialiseMap(mapRef)
      if (shipFromLocation && shipToLocation) {
        addMarker(
          convertLocationToLatLng(shipFromLocation),
          'Origin',
          <UploadSimple size={16} color='#94A3B8' className='ml-2' weight='bold' />
        )
        addMarker(
          convertLocationToLatLng(shipToLocation),
          'Destination',
          <Flag size={16} color='#CBD5E1' className='ml-2' weight='bold' />
        )
        addLine()
        centerMap()
      }
    }
  }, [map])

  return (
    <div className='bg-white rounded-lg overflow-hidden drop-shadow border border-slate-300 h-[320px]'>
      <div ref={mapRef} className='w-full h-full'></div>
    </div>
  )
}

const SensorProfileSelector = () => {
  const form = useFormContext<any>()
  const profiles = useSensorProfiles({ pageSize: 1000 })
  const profileOptions = useMemoisedOptions(profiles.data?.items)

  return (
    <div className='flex flex-col gap-1 md:max-w-[50%]'>
      <Label>Device Sensor Profile</Label>
      <Controller
        name='shipSensorProfile'
        control={form.control}
        render={({ field }) => (
          <Combobox
            className='w-full'
            options={profileOptions}
            value={field.value}
            render={e => {
              const profile = profiles.data?.items?.find(p => p.id == e.value)
              return (
                <div className='flex items-center justify-between w-full'>
                  <span>{profile?.name}</span>
                  <div className='flex items-center gap-2'>
                    <RangePill
                      isInactive={!profile?.temperatureCheck}
                      sensor={'temperature'}
                      value={[profile?.temperatureMinC, profile?.temperatureMaxC]}
                    />
                    <RangePill
                      isInactive={!profile?.humidityCheck}
                      sensor={'humidity'}
                      value={[profile?.humidityMinRh, profile?.humidityMaxRh]}
                    />
                  </div>
                </div>
              )
            }}
            onSelect={e => field.onChange(e)}
          />
        )}
      />
      <ErrorDisplay context={form} name='shipSensorProfile' />
    </div>
  )
}

const DeviceSelectWrapper = () => {
  const allDevices = useDevices()
  const containerId = useShipmentStore(state => state.containerId)
  const shipDevices = useShipmentStore(state => state.shipDevices)

  const availableDevices = allDevices.data?.filter(
    device =>
      (!shipDevices.find(d => d?.id == device.id) && device.containerId == null) ||
      (device.containerId == containerId && !shipDevices.find(d => d?.id == device.id))
  )

  return (
    <div className='space-y-2'>
      <Label>Assigned Devices</Label>
      {shipDevices.length == 0 ? (
        <div className='bg-slate-100 rounded px-4 py-2 w-full text-slate-600'>
          No Assigned Devices yet
        </div>
      ) : (
        shipDevices.map((device, index) => {
          return (
            <div className='gap-2 flex items-center' key={index}>
              <Select
                value={device?.id}
                onValueChange={e => {
                  const device = allDevices.data?.find(d => d.id == e)
                  device && setDevice(index, device)
                }}
              >
                <SelectTrigger>
                  <SelectValue placeholder={'Select a Device...'}></SelectValue>
                </SelectTrigger>

                <SelectContent>
                  {allDevices.data?.map(device => (
                    <SelectItem
                      key={device.id}
                      value={device.id as string}
                      style={{
                        display: !availableDevices?.find(d => d.id == device?.id)
                          ? 'none'
                          : undefined,
                      }}
                    >
                      {device.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Button
                size={'icon'}
                variant={'outline'}
                onClick={() => removeDevice(index)}
              >
                <X />
              </Button>
            </div>
          )
        })
      )}

      <div className='flex items-center gap-2'>
        <Button
          variant={'secondary'}
          className='w-fit'
          onClick={addDeviceSlot}
          disabled={availableDevices?.length == 0}
        >
          <Plus className='mr-2' />

          {Number(availableDevices?.length) > 0
            ? 'Add Device'
            : 'No more available Devices'}
        </Button>
      </div>
    </div>
  )
}

const UserRecipientSelector = () => {
  const shipUserRecipients = useShipmentStore(state => state.shipUserRecipients)
  const users = useUsers({ pageSize: 1000 })
  const usersOptions = useMemoisedOptions(users.data?.items, 'fullName', 'id')

  const filteredOptions = usersOptions.filter(
    u => !shipUserRecipients.find(user => user.id == u.value)
  )

  const addUser = userOpt => {
    const user = users.data?.items?.find(u => u.id == userOpt.value)
    if (user) {
      const newUser = {
        id: user.id,
        name: user.fullName,
        emailAddress: user.email,
      }
      newUser && addUserRecipient(newUser)
    }
  }

  return (
    <div className='space-y-1'>
      <Label>Users</Label>
      <Combobox
        isLoading={users.isLoading}
        placeholder='Select a user...'
        className='w-full'
        options={filteredOptions}
        onSelect={addUser}
        value={null}
      />
    </div>
  )
}

const EmailRecipientSelector = () => {
  const shipEmailRecipients = useShipmentStore(state => state.shipEmailRecipients)
  const [input, setInput] = useState('')
  const [emailError, setEmailError] = useState<string>('')

  const addEmail = () => {
    try {
      z.string().email().parse(input)

      if (shipEmailRecipients.includes(input)) {
        setInput('')
        setEmailError('Email already added')
        return
      }

      addEmailRecipient(input)
      setInput('')
      setEmailError('')
    } catch (error) {
      setInput('')
      setEmailError('Invalid email')
    }
  }

  return (
    <div className='space-y-1'>
      <Label>Emails</Label>
      <div className='flex items-start gap-2'>
        <div className='w-full'>
          <Input
            onKeyDown={e => e.key === 'Enter' && addEmail()}
            value={input}
            isError={!!emailError}
            onChange={e => setInput(e.currentTarget.value)}
            className='w-full'
            placeholder='Enter an email...'
          />
          {emailError && (
            <small className='text-red-600 mt-1 flex items-center gap-2'>
              {emailError}
            </small>
          )}
        </div>
        <Button variant={'secondary'} onClick={addEmail}>
          Add
        </Button>
      </div>
    </div>
  )
}

const renderFileIcon = (file: File | DocumentDTO | null) => {
  const type = (file as File)?.type ?? (file as DocumentDTO)?.fileName

  if (!type) return null
  if (type.includes('pdf')) return <FilePdf size={32} />
  if (type.includes('doc') || type.includes('docx')) return <FileDoc size={32} />
  return <File size={32} />
}

const FileDisplay = ({
  file,
  onRemove,
}: {
  file: File | DocumentDTO | null
  onRemove?: any
}) => {
  return (
    <div className='border border-slate-300 rounded h-20 p-1 flex items-center'>
      <div
        className={clsx(
          'h-full rounded aspect-square bg-slate-200 flex items-center justify-center'
        )}
      >
        {renderFileIcon(file)}
      </div>
      <div className='p-4 flex flex-col truncate grow'>
        <div className='space-x-1'>
          {(file as DocumentDTO)?.isPurchaseOrder && (
            <span className='w-fit bg-green-600 text-[10px] font-semibold py-1 px-2 rounded text-white'>
              Purchase Order
            </span>
          )}
          {(file as DocumentDTO)?.fileName && (
            <span className='w-fit bg-blue-600 text-[10px] font-semibold p-1 px-2 rounded text-white'>
              Uploaded
            </span>
          )}
          {(file as File)?.size && (
            <span className='w-fit bg-amber-600 text-[10px] font-semibold p-1 px-2 rounded text-white'>
              To Upload
            </span>
          )}
        </div>
        <p className='truncate'>
          {(file as File)?.name || (file as DocumentDTO)?.fileName}
        </p>
        {(file as File)?.size && (
          <small className='text-slate-400'>
            Size: {((file as File).size / 1024 / 1024).toPrecision(2)} MB
          </small>
        )}
      </div>

      {onRemove && (
        <div className='flex items-center justify-center aspect-square h-full'>
          <Button size='icon' variant={'outline'} onClick={onRemove}>
            <Trash />
          </Button>
        </div>
      )}
    </div>
  )
}

// LAYOUT

const PageLayout = ({ children }) => {
  return (
    <div className='overflow-auto bg-slate-50 h-[calc(100vh-140.8px)]'>
      <div className='max-w-[1336px] mx-auto p-4 flex flex-col gap-4'>
        <ErrorBoundary fallbackRender={ErrorFallback}>{children}</ErrorBoundary>
      </div>
    </div>
  )
}

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  return (
    <div role='alert'>
      <p>Something went wrong:</p>
      <pre style={{ color: 'red' }}>{error.message}</pre>
      <Button onClick={resetErrorBoundary}>Go Back</Button>
    </div>
  )
}

const CancelBtn = () => (
  <Link to='/shipments'>
    <Button variant='outline'>
      <Prohibit />
      <span className='hidden sm:inline ml-2'>Cancel</span>
    </Button>
  </Link>
)
