import React, { useState } from 'react'

import { Plus } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import { Button } from 'src/components/Button'
import Searchbar from 'src/components/Searchbar'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'src/components/Select'
import { Skeleton } from 'src/components/Skeleton'
import Subheader from 'src/components/Subheader'

import { POShipmentDTO } from '../../api'
import { ShipmentStatus } from '../../api/constants'
import { useShipments } from '../../api/queries'

import { ShipmentRow } from './components'

const ShipmentListPageDesktop: React.FC = () => {
  const [search, setSearch] = useState('')
  const [shipmentStatus, setShipmentStatus] = useState(ShipmentStatus.All)

  const shipments = useShipments({
    search,
    shipmentStatus: shipmentStatus == ShipmentStatus.All ? '' : shipmentStatus,
    page: 1,
    pageSize: 50,
  })

  const isEmpty = !shipments.isLoading && shipments.data?.paging?.totalItemCount === 0

  console.log(shipments.data)

  return (
    <div className='flex-1'>
      <Subheader
        title='Shipments'
        actions={
          <Link to='/shipments/new'>
            <Button>
              <Plus className='mr-2' color='#fff' size={18} />
              {/* <i className='fas fa-plus mr-2' /> */}
              Create Shipment
            </Button>
          </Link>
        }
      />

      <div className='overflow-auto bg-slate-50 h-[calc(100vh-140.8px)]'>
        <div className='max-w-[1336px] mx-auto p-4 flex flex-col gap-4'>
          <div className='flex items-center gap-2'>
            <Searchbar onSearch={setSearch} placeholder='Search...' value={search} />
            <Select
              onValueChange={value =>
                setShipmentStatus(String(value) as unknown as ShipmentStatus)
              }
              defaultValue={shipmentStatus.toString()}
            >
              <SelectTrigger className='w-[120px] font-medium'>
                <SelectValue placeholder='Status' />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value={String(ShipmentStatus.All)}>All</SelectItem>
                <SelectItem value={String(ShipmentStatus.Pending)}>Pending</SelectItem>
                <SelectItem value={String(ShipmentStatus.Active)}>Active</SelectItem>
                <SelectItem value={String(ShipmentStatus.Completed)}>
                  Completed
                </SelectItem>
              </SelectContent>
            </Select>
          </div>

          {shipments.isLoading && <Skeleton className='h-[120px]' />}

          {!shipments.isLoading && isEmpty && (
            <div className='w-full p-6 text-slate-500 font-medium flex items-center justify-center'>
              You have no Shipments
            </div>
          )}

          {!shipments.isLoading &&
            !isEmpty &&
            shipments?.data?.items?.map((shipment: POShipmentDTO) => (
              <ShipmentRow key={shipment.id} shipment={shipment} />
            ))}
        </div>
      </div>
    </div>
  )
}

export default ShipmentListPageDesktop
