import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

const options = {
  order: ['navigator'],
  lookupQuerystring: 'lng',
  lookupLocalStorage: 'i18nextLng',
}

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    detection: options,
    lng: 'en',
    fallbackLng: 'en',
    supportedLngs: ['en', 'es'],
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  })

export default i18n
