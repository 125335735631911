import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { ArrowLeft, Envelope } from '@phosphor-icons/react'
import { motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Link, useLocation } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'

import FlareLogo from '../../../assets/suply_black.png'
import { useSendConfirmEmailMutator } from '../api/queries'
import { emailSchema } from '../api/resolvers'

enum Step {
  PROVIDE_EMAIL,
  SUCCESS,
}

const SendConfirmationPage = () => {
  const state = useLocation()
  const [step, setStep] = React.useState<Step>(Step.PROVIDE_EMAIL)

  const form = useForm({
    defaultValues: { email: state.state?.emailAddress ?? '' },
    resolver: zodResolver(emailSchema),
  })

  const sendConfirmEmail = useSendConfirmEmailMutator()

  const handleSubmit = form.handleSubmit(async data => {
    await sendConfirmEmail.mutateAsync(data, {
      onSuccess: () => {
        setStep(Step.SUCCESS)
      },
      onError: e => {
        toast.error('Something went wrong. Please try again.')
      },
    })
  })

  return (
    <Form {...form}>
      <div
        className='flex flex-col gap-6 bg-slate-100 w-screen h-screen items-center justify-center'
        data-testid='send-confirmation-page'
      >
        <img src={FlareLogo} alt='Suply Logo' width={200} />

        {step === Step.PROVIDE_EMAIL && (
          <motion.form
            data-testid='provide-email-step'
            onSubmit={handleSubmit}
            className='flex flex-col gap-4 p-6 border border-slate-300 rounded-lg bg-white w-full'
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            style={{ width: 364 }}
          >
            <div className='flex items-baseline gap-3 text-xl font-semibold'>
              <Envelope color='#2563eb' />
              {/* <i className='fas fa-envelope text-blue-600 aspect-square' /> */}
              <h1>Confirm Email Address</h1>
            </div>
            <p className='text-slate-500 text-[14px]'>
              We’ll send a link to reset your password to the following email address,
              please confirm this is correct.
            </p>
            <div style={{ height: 1 }} className='bg-slate-300'></div>

            <FormField
              control={form.control}
              name='email'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Email Address</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      autoComplete='email'
                      className='w-full'
                      data-testid='email'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              className='w-full'
              disabled={sendConfirmEmail.isLoading}
              data-testid='send-email-btn'
            >
              {sendConfirmEmail.isLoading && (
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
              )}
              Send Email
            </Button>
          </motion.form>
        )}

        {step === Step.SUCCESS && (
          <motion.div
            data-testid='email-sent-success-step'
            className='flex flex-col gap-4 p-6 border border-slate-300 rounded-lg bg-white w-full'
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            style={{ width: 364 }}
          >
            <div className='flex flex-col gap-3'>
              <div className='flex gap-3 items-center text-xl font-semibold'>
                <i
                  className='fas fa-envelope text-blue-600'
                  style={{ lineHeight: 'inherit' }}
                />
                <h1>Email Sent</h1>
              </div>
              <p className='text-[14px]'>
                Check your inbox and open the link we sent to continue.
              </p>
            </div>
          </motion.div>
        )}

        <Link
          to='/login'
          className='text-slate-600 hover:text-slate-900 transition'
          data-testid='return-login-link'
        >
          <ArrowLeft className='mr-2' />
          {/* <i className='fas fa-arrow-left mr-2' /> */}
          Back to Login
        </Link>
      </div>
    </Form>
  )
}

export default SendConfirmationPage
