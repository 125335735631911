import React, { useEffect } from 'react'

import { Envelope } from '@phosphor-icons/react'
import { motion } from 'framer-motion'
import { Link, useNavigate } from 'react-router-dom'
import { PuffLoader } from 'react-spinners'

import { Button } from 'src/components/Button'

import FlareLogo from '../../../assets/suply_black.png'
import { validateBase64 } from '../api/helpers'
import { useConfirmEmailMutator } from '../api/queries'

const EmailConfirmedPage = () => {
  const navigate = useNavigate()
  const params = new URLSearchParams(window.location.search)
  const confirmationToken = params.get('token') as string
  const emailAddress = params.get('email') as string

  const confirmEmail = useConfirmEmailMutator()
  const [confirmed, setConfirmed] = React.useState(false)

  useEffect(() => {
    const validToken = validateBase64(confirmationToken)

    if (!confirmationToken || !validToken) {
      navigate('/login')
    } else {
      confirmEmail.mutateAsync(
        { confirmationToken, emailAddress },
        {
          onSuccess: () => {
            setConfirmed(true)
          },
        }
      )
    }
  }, [])

  return (
    <motion.div
      data-testid='email-confirmed-page'
      className='flex flex-col gap-4 bg-slate-100 w-screen h-screen items-center justify-center'
      initial={{ opacity: 0, y: -10 }}
      animate={{ opacity: 1, y: 0 }}
    >
      <img src={FlareLogo} alt='Suply Logo' width={200} />
      {!confirmed ? (
        <div className='h-60 flex items-center justify-center'>
          <PuffLoader color={'#94a3b8'} speedMultiplier={0.5} />
        </div>
      ) : (
        <div
          className='flex flex-col gap-4 m-6 p-6 border border-slate-300 rounded-lg bg-white'
          style={{ width: 364 }}
        >
          <div className='flex items-baseline gap-3 text-xl font-semibold'>
            <Envelope color='#2563eb' />
            <h1>Email Confirmed</h1>
          </div>
          <p className='text-slate-500 text-[14px]'>
            Your email has been confirmed. You can now try login.
          </p>
          <div style={{ height: 1 }} className='bg-slate-300'></div>
          <Link
            to={`/new-password?${params}`}
            className='w-full'
            data-testid='return-new-password'
          >
            <Button className='w-full'>Set Password</Button>
          </Link>
        </div>
      )}
    </motion.div>
  )
}

export default EmailConfirmedPage
