import { useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'

export const useLatestTelemetry = (deviceId: string, source: number) => {
  return useQuery<any>({
    queryKey: ['/telemetry', deviceId],
    queryFn: async () => {
      const res = await getApi()?.telemetry?.get(`/telemetry/${deviceId}/latest`, {
        params: { source },
      })
      return res?.data
    },
  })
}

export const useSensorTelemetry = (deviceId: string) => {
  return useQuery<any>({
    queryKey: ['/sensor-data', deviceId],
    queryFn: async () => {
      const res = await getApi()?.telemetry?.get(`/telemetry/${deviceId}/sensors`)
      return res?.data
    },
  })
}

export const usePowerTelemetry = (deviceId: string) => {
  return useQuery<any>({
    queryKey: ['/power', deviceId],
    queryFn: async () => {
      const res = await getApi()?.telemetry?.get(`/telemetry/${deviceId}/power`)
      return res?.data
    },
  })
}
export const usePositionTelemetry = (deviceId: string) => {
  return useQuery<any>({
    queryKey: ['/power', deviceId],
    queryFn: async () => {
      const res = await getApi()?.telemetry?.get(`/telemetry/${deviceId}/position`)
      return res?.data
    },
  })
}
