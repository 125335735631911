import { AddressDTO, CreateAddressDTO, PatchAddressDTO, PatchOp } from 'src/types'

import { AddressDetails, AddressForm } from './types'

export const mapAddressDTOToForm = (addressDTO: AddressDTO): AddressForm => {
  return {
    id: addressDTO.id || '',
    name: addressDTO.name || '',
    addressLine1: addressDTO.addressLine1 || '',
    addressLine2: addressDTO.addressLine2 || '',
    addressLine3: addressDTO.addressLine3 || '',
    city: addressDTO.city || '',
    country: addressDTO.country
      ? { label: addressDTO.country.name || '', value: addressDTO.country.code2 || '' }
      : null,
    province: addressDTO.province || '',
    postalCode: addressDTO.postalCode || '',
    location: addressDTO.location,
    getNewPosition: false,
  }
}

export const mapAddressFormToDetails = (addressForm: AddressForm): AddressDetails => {
  return {
    name: addressForm.name || '',
    addressLine1: addressForm.addressLine1 || '',
    addressLine2: addressForm.addressLine2 || '',
    province: addressForm.province || '',
    city: addressForm.city || '',
    postalCode: addressForm.postalCode || '',
    country: addressForm.country || { label: '', value: '' }, // Assuming default value for country
    position: new google.maps.LatLng(
      addressForm.location?.latitude || 0.0,
      addressForm.location?.longitude || 0.0
    ),
  }
}

export const mapAddressDetailsToForm = (addressDetails: AddressDetails): AddressForm => {
  return {
    id: undefined,
    name: addressDetails.name,
    addressLine1: addressDetails.addressLine1,
    addressLine2: addressDetails.addressLine2,
    addressLine3: '',
    city: addressDetails.city,
    country: addressDetails.country,
    province: addressDetails.province,
    postalCode: addressDetails.postalCode,
    location: {
      latitude: addressDetails.position?.lat(),
      longitude: addressDetails.position?.lng(),
    },
    getNewPosition: false,
  }
}

export const mapAddressDTOToDetails = (addressDTO: AddressDTO): AddressDetails => {
  return {
    name: addressDTO?.name || '',
    addressLine1: addressDTO.addressLine1 || '',
    addressLine2: addressDTO.addressLine2 || '',
    province: addressDTO.province || '',
    city: addressDTO.city || '',
    postalCode: addressDTO.postalCode || '',
    country: {
      label: addressDTO.country?.name || '',
      value: addressDTO.country?.code2 || '',
    },
    position: addressDTO.location
      ? new google.maps.LatLng(
          addressDTO.location.latitude || 0,
          addressDTO.location.longitude || 0
        )
      : null,
  }
}
export const mapFormToPatchAddressDTO = (form: AddressForm): PatchAddressDTO => {
  return {
    name: form?.name || '',
    addressLine1: form?.addressLine1 || '',
    addressLine2: form?.addressLine2 || '',
    addressLine3: form?.addressLine3 || '',
    city: form?.city || '',
    province: form?.province || '',
    postalCode: form?.postalCode || '',
    countryCode: form?.country?.value || '',
  }
}
export const mapFormToCreateAddressDTO = (form: AddressForm): CreateAddressDTO => {
  return {
    name: form?.name || '',
    addressLine1: form?.addressLine1 || '',
    addressLine2: form?.addressLine2 || '',
    addressLine3: form?.addressLine3 || '',
    city: form?.city || '',
    province: form?.province || '',
    postalCode: form?.postalCode || '',
    countryCode: form?.country?.value || '',
  }
}
export const mapToPatchOperation = (
  data: Partial<PatchAddressDTO>,
  original?: AddressDTO
): PatchOp<PatchAddressDTO>[] => {
  const patchOperations: PatchOp<PatchAddressDTO>[] = []

  for (const [key, value] of Object.entries(data)) {
    if (original) {
      const originalPatchOpration = mapFormToPatchAddressDTO(
        mapAddressDTOToForm(original)
      )
      const originalValue = originalPatchOpration[key]
      if (value !== undefined) {
        if (value !== originalValue) {
          const path = `/${key}` as keyof PatchAddressDTO
          if (value === null) {
            const patchOperation: PatchOp<PatchAddressDTO> = {
              op: 'remove',
              path: path,
              value: null,
            }
            patchOperations.push(patchOperation)
          } else {
            const patchOperation: PatchOp<PatchAddressDTO> = {
              op: 'replace',
              path: path,
              value: value,
            }
            patchOperations.push(patchOperation)
          }
        }
      }
    } else {
      if (value !== undefined) {
        const path = `/${key}` as keyof PatchAddressDTO
        if (value === null) {
          const patchOperation: PatchOp<PatchAddressDTO> = {
            op: 'remove',
            path: path,
            value: null,
          }
          patchOperations.push(patchOperation)
        } else {
          const patchOperation: PatchOp<PatchAddressDTO> = {
            op: 'replace',
            path: path,
            value: value,
          }
          patchOperations.push(patchOperation)
        }
      }
    }
  }

  return patchOperations
}

export const getLatLngFromAddressString = async (
  address: any
): Promise<google.maps.LatLng | undefined> => {
  const geocoder = new google.maps.Geocoder()
  try {
    const place = await geocoder.geocode({ address: Object.values(address).join(', ') })
    return place.results?.[0]?.geometry?.location ?? null
  } catch (error) {
    console.error('Error geocoding address:', error)
    return undefined
  }
}

export const getAddressFromLatLng = async (
  position: google.maps.LatLng | null
): Promise<Partial<AddressDetails | null>> => {
  if (!position) return null
  const geocoder = new google.maps.Geocoder()
  const place = await geocoder.geocode({ location: position })
  const address = place.results?.[0]
    ? getAddressFromGooglePlace(place.results?.[0])
    : null
  return address
}

export const getAddressFromGooglePlace = (
  place: google.maps.places.PlaceResult
): Partial<AddressDetails> => {
  const newAdd = {
    streetNumber: '',
    route: '',
    postalCode: '',
    postalCodeSuffix: '',
    postalTown: '',
    country: {
      label: '',
      value: '',
    },
    province: '',
    adminLevel1: '',
  }

  const position = place.geometry?.location

  for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
    const type = component.types

    if (type.includes('street_number') || type.includes('premise')) {
      newAdd.streetNumber = component.long_name
    }

    if (type.includes('route') || type.includes('sublocality_level_1')) {
      newAdd.route = component.long_name
    }

    if (type.includes('postal_code')) {
      newAdd.postalCode = component.long_name
    }

    if (type.includes('postal_code_suffix')) {
      newAdd.postalCodeSuffix = component.long_name ?? ''
    }

    if (type.includes('postal_town') || type.includes('locality')) {
      newAdd.postalTown = component.long_name
    }

    if (type.includes('country')) {
      newAdd.country = {
        label: component.long_name,
        value: component.short_name,
      }
    }

    if (
      type.includes('administrative_area_level_2') ||
      type.includes('administrative_area_level_3')
    ) {
      newAdd.province = component.long_name
    }

    if (type.includes('administrative_area_level_1')) {
      newAdd.adminLevel1 = component.long_name
    }
  }

  return {
    addressLine1: `${newAdd.streetNumber} ${newAdd.route}`,
    addressLine2: `${newAdd.adminLevel1}`,
    province: newAdd.province,
    city: newAdd.postalTown,
    postalCode: `${newAdd.postalCode} ${newAdd.postalCodeSuffix}`,
    country: newAdd.country,
    position,
  }
}
