import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { DocumentDTO } from 'src/types'

import { useDeleteDocumentMutator } from '../../api'

// Fixed import typo

const ConfirmDeleteDocumentDialog = ({
  document,
  open,
  setShowModal,
}: {
  document: DocumentDTO
  open: boolean
  setShowModal: () => void
}) => {
  const queryClient = useQueryClient()
  const deleteDocument = useDeleteDocumentMutator(document?.id || '')

  const handleSubmit = async () => {
    await deleteDocument.mutate(void 0, {
      onSuccess: async () => {
        await queryClient.invalidateQueries(['/shipments', document?.shipmentId])
        setShowModal()
        toast.success('Document Deleted successfully')
      },
    })
  }

  return (
    <Dialog open={open} onOpenChange={setShowModal}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Delete Document</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2 p-6'>
          {`Are you sure you want to delete this ${document?.fileName}?`}
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild>
            <Button variant='secondary' onClick={() => setShowModal()}>
              Close
            </Button>
          </DialogClose>
          <Button
            variant='destructive'
            disabled={deleteDocument.isLoading}
            onClick={handleSubmit}
          >
            {deleteDocument.isLoading && (
              <Loader2 className='mr-2 h-4 w-4 animate-spin' />
            )}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDeleteDocumentDialog
