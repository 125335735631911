import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { Modal, closeModal, useModals } from 'src/features/App/api/stores'

import { useDeleteReportMutator } from '../../api'

const ConfirmDeleteReportDialog = () => {
  const queryClient = useQueryClient()
  const state = useModals(state => state.state)
  const isOpen = useModals(state => state.modal) == Modal.DELETE_REPORT
  const id = state?.id as string

  const deleteReport = useDeleteReportMutator(id)

  return (
    <Dialog open={isOpen}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Delete Report</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2'>
          Are you sure you want to delete this Report?
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild onClick={closeModal}>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>

          <Button
            type='button'
            variant='destructive'
            disabled={deleteReport.isLoading}
            onClick={() => {
              deleteReport.mutate(void 0, {
                onSuccess: async () => {
                  closeModal()
                  await queryClient.invalidateQueries(['/reports'])
                  toast.success('Report deleted')
                },
              })
            }}
          >
            {deleteReport.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmDeleteReportDialog
