import { format, formatDistanceToNow, isValid, parseISO } from 'date-fns'

import { AddressResource } from 'src/types'

import { Option } from '../components/Combobox'

/**
 * Get the flag emoji for a given country code
 * @param countryCode The two-letter country code for which the flag emoji is needed
 * @returns The flag emoji for the given country code
 */
export function getFlagEmoji(countryCode: string | undefined | null): string {
  const codePoints = (countryCode ?? 'OW')
    .toUpperCase()
    .split('')
    .map(char => 127397 + char.charCodeAt(0))
  return String.fromCodePoint(...codePoints)
}

export const makeOptions = (
  data: any[] | null | undefined = [],
  label: string,
  value: string
) => {
  return (data ?? []).map(item => ({
    label: item[label],
    value: item[value],
  })) as Option[]
}

export const celsiusToFahrenheit = (c: number) => Number(((c * 9) / 5 + 32).toFixed(1))

export const printAddress = (address: AddressResource | null | undefined) => {
  const lines = [
    'addressLine1',
    'addressLine2',
    'addressLine3',
    'city',
    'state',
    'postalCode',
  ]

  if (!address) return ''
  const nonEmptyLines = lines
    .map(line => address[line])
    .filter(line => typeof line === 'string' && line.trim() !== '')

  return nonEmptyLines.join(', \n')
}

export const getTimeAgo = (time: string | undefined | null | Date) => {
  console.log(time)

  return time
    ? formatDistanceToNow(new Date(time), { includeSeconds: true, addSuffix: true })
    : 'unknown'
}

// https://stackoverflow.com/questions/4338267/validate-phone-number-with-javascript
export const validatePhoneNumber = (phoneNumber: string) => {
  const regex = new RegExp(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]{8,14}$/)
  return regex.test(phoneNumber)
}

export const getModeIcon = (mode: string | number | undefined) => {
  switch (mode) {
    case '0':
    case 0:
      return 'ph-boat'
    case '1':
    case 1:
      return 'ph-airplaneTilt'
    case '2':
    case 2:
      return 'ph-train'
    case '3':
    case 3:
      return 'ph-truck'
    default:
      return 'ph-boat'
  }
}

// Sea,
// Air,
// Road,
// Rail

export const getModeName = (mode: string | number | undefined) => {
  switch (mode) {
    case '0':
    case 0:
      return 'Sea'
    case '1':
    case 1:
      return 'Air'
    case '2':
    case 2:
      return 'Rail'
    case '3':
    case 3:
      return 'Road'
    default:
      return 'Any'
  }
}

export const parseDate = (dateString: string | undefined | null) => {
  if (!dateString) {
    return null
  }

  const parsedDate = parseISO(dateString)

  if (isValid(parsedDate)) {
    return format(parsedDate, 'dd/MM/yyyy')
  } else {
    return null // Handle malformed dates
  }
}
