import React, { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { Check } from '@phosphor-icons/react'
import { motion } from 'framer-motion'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'

import FlareLogo from '../../../assets/suply_black.png'
import { useUpdatePasswordMutator } from '../api/queries'
import { updatePasswordSchema } from '../api/resolvers'

enum Step {
  PROVIDE_PASSWORD,
  SUCCESS,
}

const NewPasswordPage = () => {
  const navigate = useNavigate()
  const [step, setStep] = React.useState<Step>(Step.PROVIDE_PASSWORD)

  const params = new URLSearchParams(window.location.search)
  const resetToken = params.get('token') as string
  const emailAddress = params.get('email') as string

  const form = useForm({
    defaultValues: { password: '', confirmPassword: '' },
    resolver: zodResolver(updatePasswordSchema),
  })

  useEffect(() => {
    const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    const validToken = base64regex.test(resetToken)

    if (!resetToken || !validToken) {
      navigate('/login')
    }
  }, [])

  const updatePassword = useUpdatePasswordMutator()

  const handleSubmit = form.handleSubmit(async data => {
    await updatePassword.mutateAsync(
      { newPassword: data.confirmPassword, emailAddress },
      {
        onSuccess: () => {
          setStep(Step.SUCCESS)
          params.delete('token')
          params.delete('email')
        },
        onError: () => {
          toast.error('Something went wrong. Please try again.')
        },
      }
    )
  })

  return (
    <Form {...form}>
      <motion.div
        data-testid='new-password-page'
        className='flex flex-col gap-4 bg-slate-100 w-screen h-screen items-center justify-center'
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -10 }}
      >
        <img src={FlareLogo} alt='Suply Logo' width={200} />

        {step === Step.PROVIDE_PASSWORD && (
          <form
            data-testid='provide-details-step'
            onSubmit={handleSubmit}
            className='flex flex-col gap-4 m-6 p-6 border border-slate-300 rounded-lg bg-white'
            style={{ width: 364 }}
          >
            <div className='flex flex-col gap-3'>
              <div className='flex items-baseline gap-3 text-xl font-semibold'>
                <h1>New Password</h1>
              </div>

              <p className='text-slate-500 text-[14px]' data-testid='preloaded-email'>
                For: {params.get('email')}
              </p>

              <p className='text-slate-500 text-[14px]'>
                Your password MUST contain at least:
              </p>
              <ul className='list-disc text-sm pl-6'>
                <li>8 characters</li>
                <li>1 uppercase character</li>
                <li>1 digit</li>
                <li>1 special character</li>
              </ul>
            </div>
            <div style={{ height: 1 }} className='bg-slate-300'></div>

            <FormField
              control={form.control}
              name='password'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Password</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type={'password'}
                      className='w-full'
                      data-testid='password'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name='confirmPassword'
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Confirm Password</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type={'password'}
                      className='w-full'
                      data-testid='confirm-password'
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button
              type={'submit'}
              disabled={updatePassword.isLoading}
              data-testid='submit-password-reset-btn'
            >
              {updatePassword.isLoading && (
                <Loader2 className='mr-2 h-4 w-4 animate-spin' />
              )}
              Reset Password
            </Button>
          </form>
        )}

        {step === Step.SUCCESS && (
          <motion.div
            data-testid='reset-success-step'
            className='flex flex-col gap-4 m-6 p-6 border border-slate-300 rounded-lg bg-white'
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            style={{ width: 364 }}
          >
            <div className='flex items-baseline gap-3 text-xl font-semibold'>
              {/* <i className='fas fa-check text-blue-600 aspect-square' /> */}
              <Check color='#2563eb' />
              <h1>Password Reset</h1>
            </div>
            <p className='text-slate-500 text-[14px]'>
              Your password has been reset successfully.
            </p>
            <div style={{ height: 1 }} className='bg-slate-300'></div>
            <Link to='/login' className='w-full' data-testid='return-login-link'>
              <Button className='w-full'>Log In</Button>
            </Link>
          </motion.div>
        )}
      </motion.div>
    </Form>
  )
}

export default NewPasswordPage
