import axios, { AxiosInstance } from 'axios'
import Cookies from 'js-cookie'
import { create } from 'zustand'

interface Service {
  name: string
  port: number
}

export const services: Service[] = [
  { name: 'identity', port: 7204 },
  { name: 'telemetry', port: 7123 },
  { name: 'devices', port: 7042 },
  { name: 'shipments', port: 7269 },
  { name: 'address', port: 7095 },
  { name: 'user', port: 7254 },
  { name: 'alert', port: 7002 },
  { name: 'organisations', port: 7203 },
  { name: 'location', port: 7170 },
  { name: 'reports', port: 7001 },
]

type Api = {
  [K in Service['name'] as `${K}`]: AxiosInstance
}

// Unable to supress the 404 error in the console!! They'll only show in "local" however
const checkServiceHealth = async (url: string) => {
  try {
    await fetch(url + '/health')
    return true
  } catch {
    return false
  }
}

export async function createServiceClients(
  services: Service[],
  env: string
): Promise<Api> {
  console.debug('Creating service clients...')
  const clients: Partial<Api> = {}

  for (const { name, port } of services) {
    let baseUrl: string
    if (env === 'local') {
      baseUrl = `https://localhost:${port}`
    } else {
      baseUrl = `https://suply-${name}-${env}.azurewebsites.net`
    }

    const isHealthy = await checkServiceHealth(baseUrl)
    if (isHealthy) {
      clients[name as keyof Api] = axios.create({ baseURL: baseUrl + '/api' })
      if (env == 'local') {
        console.debug(`\x1b[35mConnected to local service: ${name} - ${baseUrl}\x1b[0m`)
      } else if (env == 'dev') {
        console.debug(`\x1b[36mConnected to remote service: ${name} - ${baseUrl}\x1b[0m`)
      }
    } else {
      if (env === 'local') {
        const devUrl = `https://suply-${name}-dev.azurewebsites.net`
        const isDevHealthy = await checkServiceHealth(devUrl)
        if (isDevHealthy) {
          clients[name as keyof Api] = axios.create({ baseURL: devUrl + '/api' })
          if (env == 'local' || env == 'dev') {
            console.debug(
              `\x1b[36mConnected to remote service: ${name} - ${devUrl}\x1b[0m`
            )
          }
        } else {
          console.error(`Unable to connect to service: ${name} - ${devUrl}`)
        }
      } else {
        console.error(`Unable to connect to service: ${name} - ${baseUrl}`)
      }
    }
  }

  Object.values(clients).forEach(client => {
    client?.interceptors.request.use(
      config => {
        const token = Cookies.get('flare-session')
        config.headers.Authorization = `Bearer ${token}`
        return config
      },
      error => {
        console.error('Request Interceptor - Error:', error)
        return Promise.reject(error)
      }
    )
  })

  return clients as Api
}

interface ServiceStore {
  api: Api | null
  isLoaded: boolean
  getApi: () => Api | null
  initServices: () => Promise<void>
}

export const useServices = create<ServiceStore>((set, get) => ({
  api: null,
  isLoaded: false,
  getApi: () => get().api,
  initServices: async () => {
    if (get().isLoaded) return
    const api = await createServiceClients(services, import.meta.env.VITE_ENVIRONMENT)
    set({ api, isLoaded: true })
  },
}))

export const getApi = useServices.getState().getApi
