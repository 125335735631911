import React from 'react'

import {
  CalendarDots,
  CreditCard,
  DotsThreeVertical,
  Pencil,
  Pulse,
  ShieldCheck,
} from '@phosphor-icons/react'
import { Link } from 'react-router-dom'

import { Button } from 'src/components/Button'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from 'src/components/DropdownMenu'
import { getModeIcon, parseDate } from 'src/helpers/utils'

import { POShipmentDTO } from '../../api/types'
import { ShipmentJourneyDetails, ShipmentPill, StatusPill } from '../components'

export const ShipmentRow = ({ shipment }: { shipment: POShipmentDTO | undefined }) => {
  const leg = shipment?.legs?.[0]
  const container = shipment?.containers?.[0]
  const ETA = parseDate(shipment?.shippingDate)
  return (
    <div className='p-4 bg-white rounded-lg drop-shadow border border-slate-300 flex flex-col gap-4'>
      <div className='flex items-center justify-between'>
        <div className='flex items-center gap-2'>
          <i className={`ph-bold ${getModeIcon(leg?.transportMode)} text-blue-600`} />
          <div className='text-lg font-semibold'>{shipment?.name}</div>
          <StatusPill status={shipment?.status} />
        </div>

        <div className='flex items-center gap-2'>
          <Link to={`/shipments/${shipment?.id}`}>
            <Button variant='outline'>View</Button>
          </Link>

          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant={'outline'} size={'icon'}>
                <DotsThreeVertical color='000' size={20} />
                {/* <i className={'fas fa-ellipsis-v'} /> */}
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <Link to={`/shipments/${shipment?.id}/edit`}>
                <DropdownMenuItem className='font-medium text-[14px] rounded flex items-center justify-between'>
                  <span>Edit Shipment</span>
                  <Pencil />
                  {/* <i className='fas fa-pencil text-slate-400' /> */}
                </DropdownMenuItem>
              </Link>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div className='flex items-center justify-between flex-wrap gap-4'>
        <div className='items-center gap-4 flex flex-wrap'>
          {shipment?.isFinanced && (
            <ShipmentPill>
              <CreditCard color='#16a34a' />
              {/* <i className='far fa-credit-card text-green-600' /> */}
              Financed
            </ShipmentPill>
          )}
          {shipment?.isInsured && (
            <ShipmentPill>
              <ShieldCheck color='#16a34a' />
              {/* <i className='far fa-shield-check text-green-600' /> */}
              Insured
            </ShipmentPill>
          )}

          {ETA && (
            <div className='flex items-center gap-2'>
              <CalendarDots />
              {/* <i className='far fa-calendar-alt text-slate-400' /> */}
              <span>Ship Date:</span>
              <span>{ETA}</span>
            </div>
          )}

          {container?.devices && (
            <div className='items-center gap-2 flex'>
              <Pulse />
              {/* <i className='far fa-wave-pulse text-slate-400' /> */}
              <div> {`${container.devices?.length} Devices`}</div>
            </div>
          )}
        </div>

        <ShipmentJourneyDetails shipment={shipment} />
      </div>
    </div>
  )
}
