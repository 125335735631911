import React from 'react'

import { useQueryClient } from '@tanstack/react-query'
import { Loader2 } from 'lucide-react'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from 'src/components/Dialog'
import { Modal, closeModal, useModals } from 'src/features/App/api/stores'
import { AspNetUserDTO } from 'src/types/flare-user-dev'

import { useUpdateUserMutator } from '../../api'
import { UserStatus } from '../../api/types.d'

const ConfirmEnableUserDialog = () => {
  const queryClient = useQueryClient()
  const isOpen = useModals(state => state.modal) == Modal.ENABLE_USER
  const user: AspNetUserDTO = useModals(state => state.state)?.user as AspNetUserDTO
  const id = user?.id || ''
  const updateUser = useUpdateUserMutator(id)

  return (
    <Dialog open={isOpen}>
      <DialogContent className='sm:max-w-md'>
        <DialogHeader>
          <DialogTitle>Enable User</DialogTitle>
        </DialogHeader>
        <div className='flex items-center space-x-2'>
          Are you sure you want to enable this user?
        </div>
        <DialogFooter className='sm:justify-between'>
          <DialogClose asChild onClick={closeModal}>
            <Button type='button' variant='secondary'>
              Close
            </Button>
          </DialogClose>

          <Button
            type='button'
            disabled={updateUser.isLoading}
            onClick={() => {
              updateUser.mutate(
                [{ op: 'replace', path: '/status', value: UserStatus.Active }],
                {
                  onSuccess: async () => {
                    await queryClient.invalidateQueries(['/users'])
                    toast.success('User enabled')
                    closeModal()
                  },
                }
              )
            }}
          >
            {updateUser.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
            Yes
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default ConfirmEnableUserDialog
