import { useMutation, useQuery } from '@tanstack/react-query'

import { getApi } from 'src/services'
import {
  CreateSensorProfileDTO,
  SensorProfileDTO,
  SensorProfileDTOPagedResponse,
} from 'src/types/flare-alert-dev'

export const useSensorProfile = (id: string | null | undefined) => {
  return useQuery<SensorProfileDTO>({
    queryKey: ['/sensor-profiles', id],
    queryFn: async () => {
      if (!id) return null
      const res = await getApi()?.alert?.get(`/sensorprofile/${id}`)
      return res?.data
    },
  })
}

export const useSensorProfiles = (query = {}) => {
  return useQuery<SensorProfileDTOPagedResponse>({
    queryKey: ['/sensor-profiles', query],
    queryFn: async () => {
      const res = await getApi()?.alert?.get('/sensorprofile', {
        params: query,
      })
      return res?.data
    },
  })
}

export const useCreateSensorProfileMutator = () => {
  return useMutation({
    mutationFn: async (form: CreateSensorProfileDTO) => {
      const res = await getApi()?.alert?.post(`/sensorprofile`, form)
      return res?.data as SensorProfileDTO | null
    },
  })
}
export const useUpdateSensorProfileMutator = (id: string) => {
  return useMutation({
    mutationFn: async (form: CreateSensorProfileDTO) => {
      const res = await getApi()?.alert?.put(`/sensorprofile/${id}`, form)
      return res?.data as SensorProfileDTO | null
    },
  })
}

export const useDeleteSensorProfileMutator = (ids: string[]) => {
  return useMutation({
    mutationFn: async () => {
      const res = await getApi()?.alert?.delete(`/sensorprofile`, {
        data: ids,
      })
      return (
        res?.data && { success: true, message: 'Sensor profiles deleted successfully.' }
      )
    },
  })
}

export const useRenameSensorProfileMutator = (id: string) => {
  return useMutation({
    mutationFn: async (newName: string) => {
      const res = await getApi()?.alert?.put(`/sensorprofile/${id}/rename`, newName)
      return res?.data as SensorProfileDTO | null
    },
  })
}
