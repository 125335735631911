import React, { useEffect, useRef } from 'react'

import { useFormContext } from 'react-hook-form'

import { Combobox } from 'src/components/Combobox'
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'

import {
  AddressDetails,
  CreateAddressPageForm,
  useAddressStore,
  useCountryOptions,
} from '../../api'
import { mapAddressDetailsToForm } from '../../api/helpers'

export const AddressDetailsBlock = ({
  resetAutoRefInChild,
}: {
  resetAutoRefInChild: (resetFunction: () => void) => void
}) => {
  const countries = useCountryOptions()
  const form = useFormContext<CreateAddressPageForm>()
  const storedAddress = useAddressStore(s => s.address)

  const autoRef = useRef<HTMLInputElement>(null)

  // const position = form.watch('location')
  const resetAutoRef = () => {
    if (autoRef.current) {
      autoRef.current.value = ''
    }
  }

  useEffect(() => {
    resetAutoRefInChild(resetAutoRef)
  }, [resetAutoRefInChild])

  useEffect(() => {
    if (storedAddress) {
      form.reset(mapAddressDetailsToForm(storedAddress))
      useAddressStore.getState().initAuto(autoRef)
    }
  }, [storedAddress, autoRef])
  return (
    <div className='border border-slate-300 rounded p-5 flex flex-col gap-4 bg-white'>
      <h1 className='font-xl font-semibold'>Address Details</h1>

      <FormItem>
        <p className='text-[14px]'>
          Search an address or click the map to perform a lookup
        </p>
        <Input
          placeholder='Search for an Address'
          className='w-full'
          ref={autoRef}
          data-testid='autocompete-input'
        />
      </FormItem>

      <div className='flex items-center justify-between gap-4'>
        <div className='h-[1px] bg-slate-300 flex-1'></div>
        <div className='text-[14px]'>Or enter an address manually</div>
        <div className='h-[1px] bg-slate-300 flex-1'></div>
      </div>

      <FormField
        control={form.control}
        name='addressLine1'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Address Line 1</FormLabel>
            <FormControl>
              <Input {...field} className='w-full' data-testid='address-line-1-input' />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name='addressLine2'
        render={({ field }) => (
          <FormItem>
            <FormLabel>Address Line 2</FormLabel>
            <FormControl>
              <Input {...field} className='w-full' data-testid='address-line-2-input' />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <div className='grid grid-cols-2 gap-4'>
        <FormField
          control={form.control}
          name='city'
          render={({ field }) => (
            <FormItem>
              <FormLabel>City</FormLabel>
              <FormControl>
                <Input {...field} className='w-full' data-testid='city-input' />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='province'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Province</FormLabel>
              <FormControl>
                <Input {...field} className='w-full' data-testid='province-input' />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>

      <div className='grid grid-cols-2 gap-4'>
        <FormField
          control={form.control}
          name='country'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Country</FormLabel>
              <FormControl>
                <Combobox
                  options={countries}
                  onSelect={field.onChange}
                  value={field.value}
                  className='w-full'
                  data-testid='country-combobox'
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />

        <FormField
          control={form.control}
          name='postalCode'
          render={({ field }) => (
            <FormItem>
              <FormLabel>Postal Code</FormLabel>
              <FormControl>
                <Input {...field} className='w-full' data-testid='postal-code-input' />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </div>
    </div>
  )
}

interface AddressMapProps {
  onAddressUpdate: (addressDetails: AddressDetails) => void
}
export const AddressMapBlock = ({ onAddressUpdate }: AddressMapProps) => {
  const mapRef = useRef(null)

  useEffect(() => {
    useAddressStore.getState().initMap(mapRef)
  }, [mapRef])

  const address = useAddressStore(state => state.address)

  useEffect(() => {
    if (address) {
      onAddressUpdate(address)
    }
  }, [address])

  return (
    <div
      className='border border-slate-300 rounded p-4 flex flex-col bg-white'
      ref={mapRef}
    ></div>
  )
}
