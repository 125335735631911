import React, { useEffect } from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FloppyDisk } from '@phosphor-icons/react'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'sonner'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import Subheader from 'src/components/Subheader'
import { useCurrentUser } from 'src/features/Users'
import { celsiusToFahrenheit } from 'src/helpers/utils'

import { defaultSensorProfile } from '../../api/constants'
import {
  convertSensorProfileDTOToForm,
  convertSensorProfileFormToDTO,
} from '../../api/helpers'
import {
  useRenameSensorProfileMutator,
  useSensorProfile,
  useUpdateSensorProfileMutator,
} from '../../api/queries'
import { createSensorProfileSchema } from '../../api/resolvers'
import { SensorThresholdBlock } from '../components'

const SingleSensorProfilePage: React.FC = () => {
  const { id } = useParams() as { id: string }
  const tempMetric = useCurrentUser()?.data?.tempMetric
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const profile = useSensorProfile(id)

  const form = useForm({
    defaultValues: defaultSensorProfile,
    resolver: zodResolver(createSensorProfileSchema),
  })

  const updateSensorProfile = useUpdateSensorProfileMutator(id)
  useEffect(() => {
    if (profile.data && !profile.isLoading) {
      form.reset(convertSensorProfileDTOToForm(profile.data))
    }
  }, [profile.isLoading])

  const renameProfile = useRenameSensorProfileMutator(id)

  const onSubmit = form.handleSubmit(
    async () => {
      const values = convertSensorProfileFormToDTO(form.getValues())
      return updateSensorProfile.mutateAsync(values, {
        onSuccess: async () => {
          await queryClient.invalidateQueries(['/sensor-profiles'])
          toast.success('Sensor Profile Updated')
          navigate('/sensor-profiles')
        },
        onError: e => {
          if (e instanceof AxiosError) {
            console.log(e)
          }
        },
      })
    },
    error => {
      console.log(error)
    }
  )
  return (
    <Form {...form}>
      <div className='flex-1'>
        <Subheader
          returnTo='/sensor-profiles'
          title={profile.data?.name || ''}
          actions={
            <div className='flex items-center gap-2'>
              <Button
                variant='outline'
                disabled={
                  !form.getValues().humidityCheck && !form.getValues().temperatureCheck
                }
                onClick={onSubmit}
              >
                {/* <i className='far fa-floppy-disk mr-2' /> */}
                <FloppyDisk className='mr-2' />
                {renameProfile.isLoading && (
                  <Loader2 className='mr-2 h-4 w-4 animate-spin' />
                )}
                Save
              </Button>
            </div>
          }
        />

        {profile.isLoading ? (
          <div className='flex items-center justify-center h-screen w-screen'></div>
        ) : (
          <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
            <div className='container py-4 flex flex-col gap-4'>
              <FormField
                control={form.control}
                name='name'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Profile Name</FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className='w-full'
                        data-testid='name-input'
                        placeholder='E.g. Acceptable Temp Range'
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <SensorThresholdBlock
                title={'temperature'}
                icon={'fa-thermometer-half'}
                min={-30}
                max={100}
                defaultValue={[
                  profile.data?.temperatureMinC || 5,
                  profile.data?.temperatureMaxC || 25,
                ]}
                format={value =>
                  tempMetric === 'F' ? `${celsiusToFahrenheit(value)} °F` : `${value}°C`
                }
              />
              <SensorThresholdBlock
                title={'humidity'}
                icon={'fa-droplet'}
                min={0}
                max={100}
                defaultValue={[
                  profile.data?.humidityMinRh === 0
                    ? 0
                    : profile.data?.humidityMinRh || 25,
                  profile.data?.humidityMaxRh || 75,
                ]}
                format={value => `${value}%`}
              />
            </div>
          </div>
        )}
      </div>
    </Form>
  )
}

export default SingleSensorProfilePage
