import React from 'react'

import { zodResolver } from '@hookform/resolvers/zod'
import { FloppyDisk, Prohibit } from '@phosphor-icons/react'
import { useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { Loader2 } from 'lucide-react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { toast } from 'sonner'
import { z } from 'zod'

import { Button } from 'src/components/Button'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'src/components/Form'
import { Input } from 'src/components/Input'
import Subheader from 'src/components/Subheader'
import { useCurrentOrg } from 'src/features/Settings/api/queries'

import { useAddUserMutator } from '../../api'
import { defaultAddUserForm } from '../../api/constants'
import { addUserSchema } from '../../api/resolvers'

const AddUserPageDesktop: React.FC = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const form = useForm<z.infer<typeof addUserSchema>>({
    defaultValues: defaultAddUserForm,
    resolver: zodResolver(addUserSchema),
  })

  const addUser = useAddUserMutator()
  const currentOrg = useCurrentOrg()
  const handleSubmit = form.handleSubmit(async data => {
    const newUser = { ...data, orgId: currentOrg?.data?.id || '' }
    await addUser.mutate(newUser, {
      onError: e => {
        if (e instanceof AxiosError) {
          if (e.response?.status === 409) {
            form.setError('emailAddress', {
              type: 'manual',
              message: 'Email address already in use',
            })
          }
        }
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['/users'])
        toast.success('User added.')
        navigate('/users')
      },
    })
  })

  return (
    <Form {...form}>
      <div className='flex-1'>
        <Subheader
          title={'Adding a New User'}
          actions={
            <div className='flex items-center gap-2'>
              <Button
                variant='outline'
                onClick={() => {
                  form.reset()
                  navigate('/users')
                }}
              >
                <Prohibit className='mr-2' />
                {/* <i className='fas fa-ban mr-2' /> */}
                Cancel
              </Button>

              <Button
                variant='outline'
                onClick={handleSubmit}
                disabled={addUser.isLoading}
              >
                {addUser.isLoading && <Loader2 className='mr-2 h-4 w-4 animate-spin' />}
                <FloppyDisk className='mr-2' />
                {/* <i className='far fa-floppy-disk mr-2' /> */}
                Add User
              </Button>
            </div>
          }
        />

        <div className='overflow-auto bg-slate-50 h-[calc(100vh-128px)]'>
          <div className='container py-4 flex flex-col gap-4'>
            <div className='bg-white border border-slate-300 rounded p-4 flex flex-col gap-4'>
              <h1 className='font-semibold text-lg'>User Details</h1>
              <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                <FormField
                  control={form.control}
                  name='personName'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Name</FormLabel>
                      <FormControl>
                        <Input {...field} className='w-full' data-testid='name-input' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name='emailAddress'
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>Email Address</FormLabel>
                      <FormControl>
                        <Input {...field} className='w-full' data-testid='email-input' />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default AddUserPageDesktop
